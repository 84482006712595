<!--
 * @Descripttion:
 * @version:
 * @Author: 王三华
 * @Date: 2023-05-15 14:38:28
-->
<template>
    <div>
        <div id="mounthData1"
        :style="myChartStyle">
        </div>
    </div>
</template>

<script>
import * as api from '@/api/activity.js'
import {getPerformance} from "@/api/index";
export default {
    props:{
        time: {
            type: Number,
            // 定义是否必须传
            required: false,
        },
    },
    data(){
      return{
        myChartStyle: {
        float: 'center',
        width: '700px',
        height: '450px',
        paddingTop: '0',
        // margin: "20px",
      }, 
      }
    },
    mounted() {
      // this.upDateCover()
      // this.drawChart()
    },
    methods: {
        upDateCover(val) {
            let arr2=[]
            let arr3=[]
            getPerformance({
                month: val || this.time,
                })
                .then((res) => {
                    arr2.push(res.data.leftGrandTotal)
                    arr2.push(res.data.firstLeftPurchaseTotal)
                    arr2.push(res.data.firstLeftRepurchaseTotal)
                    arr3.push(res.data.rightGrandTotal)
                    arr3.push(res.data.firstRightPurchaseTotal)
                    arr3.push(res.data.firstRightRepurchaseTotal)
                    // arr2.push(3)
                    // arr2.push(5)
                    // arr2.push(10)
                    // arr3.push(3)
                    // arr3.push(5)
                    // arr3.push(10)
                    this.drawChart(arr2, arr3)
                })
        },
        drawChart(arr2, arr3) {
            let arr1 =[this.$t('N_I_103'),this.$t('N_I_108'),this.$t('N_I_104')]
            // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
            let myChart = this.$echarts.init(document.getElementById('mounthData1'))
            // 指定图表的配置项和数据
            let option = {
                title: {
                    text: '',
                    subtext: this.$t('w_0339'),
                    left:40,
                },
                tooltip: {},
                legend: {
                    top: 'bottom',
                    data: [this.$t('S_C_18'), this.$t('S_C_19')],
                    itemHeight:20,
                    itemWidth:40,
                    bottom:0,
                },
                xAxis: {
                    data: arr1,
                    axisTick: {
                        show: false,
                    },
                },
                yAxis: {
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                        },
                    },
                },
                series: [
                    {
                        name: this.$t('S_C_18'),
                        type: 'bar',
                        data: arr2,
                        itemStyle: {
                            normal: {
                                barBorderRadius: [10, 10, 0, 0],
                                color: '#01C291',
                            },
                        },
                    },
                    {
                        name: this.$t('S_C_19'),
                        type: 'bar',
                        data: arr3,
                        itemStyle: {
                            normal: {
                                barBorderRadius: [10, 10, 0, 0],
                                color: '#FE4C4B',
                            },
                        },
                    },
                ],
            }
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option)
        },
    },
}
</script>

<style>
</style>