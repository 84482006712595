<!--
 * @Descripttion:
 * @version:
 * @Author: kBank
 * @Date: 2022-08-08 10:42:29
-->
<template>
  <el-dialog class="notice"
             width="45%"
             :title="$t('MN_F_4')"
             :visible.sync="noticeFlag"
             @close="closeTap">
    <!--    <div class="title">-->
    <!--      {{ content.title }}-->
    <!--    </div>-->
    <div class="img-auto"
         v-html="content.content"></div>
    <!--    <div class="surebtn1">-->
    <!--      <div class="btn hh" @click="closeTap">取消</div>-->
    <!--        <div class="btn " @click="closeTap">确认</div>-->
    <!--    </div>-->
  </el-dialog>
</template>

<script>
import { indexPopScreen } from '@/api/index.js'
export default {
  name: 'Title',
  props: {
    userInfo: {
      type: Object,
    },
    publishLocationIndex: {
      type: Number,
    },
  },
  data() {
    return {
      noticeFlag: false,
      content: '',
    }
  },
  created(){
    // EventBus.$on('callMethod', this.getUserTc);
  },
  // mounted() {
  //   this.getUserTc()
  // },
  methods: {
    closeTap() {
      this.noticeFlag = false
      // this.$parent.getTree()
    },
    getUserTc() {
      indexPopScreen({
        grade: this.userInfo.pkGradeId,
        awards: this.userInfo.pkAwardsId,
      }).then((res) => {
        if (res.code == '200') {
          if (res.data.length > 0) {
            let arr = res.data[0].publishLocation.split(',')
            arr.forEach((items) => {
              if (items == this.publishLocationIndex) {
                this.noticeFlag = true
                this.content = res.data[0]
              }
            })
          } else {
            // this.$parent.getTree()
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__headerbtn i {
  font-size: 24px;
  //visibility: hidden;
  color: #333;
}
::v-deep .el-dialog {
  margin-top: 113px !important;
  height: 815px;
  margin-bottom: 0;
}
::v-deep .el-dialog__body {
  padding-bottom: 20px;
}
.surebtn1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  //justify-content: space-around;
  .btn {
    width: 200px;
    height: 66px;
    background: #d5251d;
    border-radius: 6px 6px 6px 6px;
    text-align: center;
    font-size: 22px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 66px;
    cursor: pointer;
  }
  .hh {
    background: #cccccc;
    margin-right: 78px;
  }
}
::v-deep .el-dialog__header {
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
::v-deep .el-dialog__title {
  font-size: 32px;
  color: #333;
  font-weight: bold;
}
::v-deep .el-dialog__body {
  text-align: center;
  padding-top: 10px;
}
.title {
  color: #333;
  font-weight: bold;
  margin: 10px auto 10px 0;
  font-size: 16px;
}
.img-auto {
  text-align: center;
  height: 685px;
  overflow-y: auto;
  ::v-deep p {
    font-size: 14px;
  }
  ::v-deep img {
    max-width: 100%;
    min-width: 95%;
    height: auto;
  }
}
</style>