<template>
  <div class="contain">
    <div class="rightbox"
         style="width: 100%">

      <div class="top-lists">
        <div class="nav-view"
             @click="navTap(index)"
             :class="navIndex == index ? 'styles' : ''"
             v-for="(item, index) in navList"
             :key="index">
          {{ item.name }}
        </div>
      </div>
      <div class="maincontent"
           v-show="navIndex == 0">
        <div class="actBox"
             @click="goDetail(item)"
             v-for="(item, index) in dataList"
             :key="index">
          <img class="actimg"
               :src="item.actCover"
               alt="" />
          <div class="afloat"
               :class="`ac${item.actStatus}`">
            {{ item.actStatusVal }}
          </div>
          <div class="activetext">
            <div class="acttitle">[{{ $t('ENU_PAYMENT_TYPE_6') }}] {{ item.actName }}</div>
            <div class="acttime">
              {{ $t('N_I_129') }}：{{ item.actStartDate }}-{{ item.actEndDate }}
            </div>
            <!-- <div class="actremark">{{ item.actExplain }}</div> -->
            <div style="height: 40px">
              <div class="btnbox">
                <el-button class="actbtn"
                           @click.prevent.stop="getGift(item)"
                           v-if="item.existNewPeopleGift == 0">{{ $t('w_0143') }}</el-button>
                <el-button class="actbtn"
                           @click.prevent.stop="getGift(item)"
                           v-if="item.existDrawNum == 0">{{ $t('ENU_APPROVE_B_724') }}</el-button>
                <el-button class="actbtn"
                           @click.prevent.stop="getTree()"
                           v-if="item.actType == 19">{{ $t('w_0283') }}</el-button>
                <!-- <el-button class="actbtn"
                           v-if="item.actType == 22"></el-button> -->
                <div class="addCarImg"
                     v-if="item.actType == 22&& item.actStatus == 1&&item.point">
                  积分:{{ item.point }}
                </div>
                <!-- v-if="item.existNewPeopleGift == 0" -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <shajiList v-show="navIndex == 1"
                 ref="shajiList"></shajiList>
    </div>
    <newGift ref="newGift"></newGift>
    <giftDrwal ref="giftDrwal"></giftDrwal>
    <div class="goodsDiag_a">
      <!-- 植树 -->
      <el-dialog title=""
                 :visible.sync="isGoods"
                 width="25%"
                 center
                 :append-to-body="true"
                 :before-close="closeGoods">
        <div class="goodsDiag">
          <div class="dis_t">{{ $t('w_0283') }}</div>
          <div class="dis_a">
            <div class="disFlex">
              <div class="dis_l">{{ $t('MY_TH_2') }}:</div>
              <el-input-number size="mini"
                               @change="treePrice"
                               v-model="treeObj.quantity"
                               :min="treeMin"></el-input-number>
            </div>
            <div class="disFlex">
              <div class="dis_l">{{ $t('w_0287') }}:</div>
              <div class="dis_r">￥{{ treeObj.amount | numberToCurrency }}</div>
            </div>
          </div>
        </div>
        <span slot="footer"
              class="treefooter">
          <el-button type="primary"
                     class="delBtn"
                     @click="payGoods">{{ $t('w_0035') }}</el-button>
        </span>
      </el-dialog>
    </div>
    <PayTree :isPay="isPay"
             ref="payment"
             @closePay="closePay"></PayTree>
  </div>
</template>
        
        <script>
import sidebarUserInfo from '@/components/sidebarUserInfo.vue'
import topBanner from '@/components/topBanner.vue'
import navBar from '@/components/navBar.vue'
import newGift from '@/components/newGift.vue'
import giftDrwal from '@/components/giftDrwal/index.vue'
import shajiList from '@/components/marketDynamics/shajiList.vue'
import * as act from '@/api/bonus.js'
import * as api from '@/api/index.js'

import PayTree from '@/components/PayTree.vue'
export default {
  name: 'Registration',
  components: {
    navBar,
    sidebarUserInfo,
    topBanner,
    newGift,
    giftDrwal,
    shajiList,
    PayTree,
  },
  data() {
    return {
      marketList: [
        {
          text: this.$t('ENU_MENU_510'),
          id: 0,
          path: '/marketDynamics',
        },
        {
          text: this.$t('ENU_MENU_520'),
          id: 7,
          path: '/activeZone',
        },
        {
          text: this.$t('ENU_MENU_530'),
          id: 1,
          path: '/incomeDetail',
        },
        {
          text: this.$t('ENU_MENU_540'),
          id: 3,
          path: '/bonusSource',
        },
        // {
        //   text: "云代奖金",
        //   id: 2,
        //   path: "/generation",
        // },

        {
          text: this.$t('ENU_MENU_560'),
          id: 4,
          path: '/appraisal',
        },
        {
          text: this.$t('ENU_MENU_570'),
          id: 5,
          path: '/registration',
        },
        {
          text: this.$t('ENU_MENU_580'),
          id: 6,
          path: '/investment',
        },
      ],
      dataList: [],
      navIndex: 0,
      isGoods: false,
      navList: [
        {
          name: this.$t('w_0144'),
        },
        {
          name: this.$t('ENU_TRADE_T_725'),
        },
      ],
      treeObj: '',
      treeMin: 0,
      isPay: false,
    }
  },
  watch: {
    $route(n, o) {
      this.specialArea = n.query.id
    },
  },
  created() {},
  mounted() {
    this.specialArea = this.$route.query.id
    this.getSearch()
  },
  methods: {
    closeGoods() {
      this.isGoods = false
    },
    closePay(val) {
      if (val) {
        this.isPay = false
        this.isGoods = false
      } else {
        this.$confirm('确认取消支付？')
          .then((_) => {
            this.isPay = false
            this.isGoods = false
          })
          .catch((_) => {})
      }
    },
    payGoods() {
      this.$refs.payment.howMoney(this.treeObj)
      this.isPay = true
    },
    treePrice(e) {
      let amount = e * Number(this.treeObj.price)
      this.$set(this.treeObj, 'amount', amount)
    },
    getTree() {
      api.queryTreeActivity().then((res) => {
        if (res.data) {
          this.treeObj = res.data
          this.treeMin = res.data.quantity
          this.$set(
            this.treeObj,
            'amount',
            Number(res.data.quantity) * Number(res.data.price)
          )
          this.isGoods = true
        }
      })
    },
    navTap(index) {
      this.navIndex = index
      if (index == 1) {
        this.$refs.shajiList.getList()
      }
    },
    //详情跳转
    goDetail(item) {
      console.log('🌈', item)

      this.$router.push({
        path: '/activeDetail',
        query: { pkId: item.pkId },
      })
    },
    getGift(item) {
      console.log('🌈', item)
      // 新人礼包;
      if (item.actType == 16) {
        this.$refs.newGift.getGiftList(item.pkId)
      } else if (item.actType == 17) {
        this.$refs.giftDrwal.getGiftList(item.pkId)
      }
    },
    getSearch() {
      act.getActivityList().then((res) => {
        this.dataList = res.rows
        this.dataList.forEach((item) => {
          if (item.actType == 22 && item.actStatus == 1) {
            act.tourismPoint(item.pkId).then((res) => {
              if (res.data != undefined) {
                this.$set(item, 'point', res.data)
              }
            })
          }
        })
      })
    },
  },
}
</script>
        
<style lang="scss" scoped>
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
.contain {
  background: rgba(249, 249, 249, 1);
  height: 100vh;
  padding: 0 !important;
  display: flex;
  .maincontent {
    background: #ffffff;
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
    padding: 20px;
    margin-top: 20px;
    // display: flex;
    // flex-wrap: wrap;
    // align-items: center;
    min-height: 1393px;
    overflow-y: auto;
    .actBox {
      width: 425px;
      border-radius: 8px;
      margin: 20px;
      background: #ffffff;
      box-shadow: 0px 2px 20px 0px rgba(204, 204, 204, 0.5);
      position: relative;
      overflow: hidden;
      display: inline-block;
      .actimg {
        width: 100%;
        height: 253px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }
      .afloat {
        position: absolute;
        top: 20px;
        right: 0;
        width: 86px;
        height: 42px;

        border-radius: 8px 0px 0px 8px;
        color: #ffffff;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ac0 {
        background: linear-gradient(180deg, #7692ff 0%, #335af1 100%);
      }
      .ac1 {
        background: linear-gradient(180deg, #ffb011 0%, #ff8202 100%);
      }
      .ac2 {
        background: #cccccc;
      }
      .activetext {
        padding: 20px;
        background: #ffffff;
        .acttitle {
          font-size: 18px;
          color: #b42b2a;
          font-weight: 500;
        }
        .acttime {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin-top: 9px;
        }
        .actremark {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-top: 22px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .btnbox {
          text-align: center;
          margin-top: 29px;
          .actbtn {
            background: #d61820;
            border-radius: 29px 29px 29px 29px;
            color: #ffffff;
            padding-left: 40px;
            padding-right: 40px;
          }
        }
      }
    }
  }
}
.styles {
  border: none !important;
  color: #fff !important;
  background: #d61820;
}
.top-lists {
  display: flex;
  padding: 20px 0 0 0;
  .nav-view {
    cursor: pointer;
    padding: 0 26px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid #999999;
    font-size: 14px;
    text-align: center;
    line-height: 44px;
    margin-right: 20px;
    color: #999;
  }
}
.goodsDiag {
  text-align: center;
  .dis_t {
    font-size: 28px;
    font-weight: 500;
    color: #333;
    margin-top: -40px;
  }
  .dis_a {
    margin-top: 40px;
  }
  .disFlex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .dis_l {
    margin-right: 10px;
  }
  .dis_r {
    color: #e3000f;
    font-size: 28px;
    font-weight: 500;
  }
}
.treefooter {
  ::v-deep .el-button {
    width: 200px;
  }
  // 删除按钮
  .delBtn {
    background: #c8161d;
    color: #fff;
    border-color: #c8161d;
  }
  .delBtn:hover {
    background: #c8161d;
    color: #fff;
    border-color: #c8161d;
  }
}
.addCarImg {
  // width: 279px;
  // height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e2524a;
  font-weight: 500;
  padding: 5px 0;
  border: 2px solid #e2524a;
  border-radius: 5px;
}
</style>