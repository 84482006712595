<template>
  <div class="contains">
    <div class="topbox" v-if="userInfo.pkCountry != 1">
      <el-form label-width="80px">
        <el-row>
          <el-col :span="7">
            <el-form-item
              :label="$t('N_I_130')"
              prop="teamCode"
              style="margin-bottom: 15px"
            >
              <el-date-picker
                v-model="thetime"
                type="daterange"
                value-format="yyyy-MM-dd"
                :range-separator="$t('w_0139')"
                :start-placeholder="$t('CK_KS_4')"
                :end-placeholder="$t('CK_KS_5')"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <div class="searchbox">
              <el-button class="btn2" @click="research">{{
                $t("N_I_183")
              }}</el-button>
              <el-button class="btn1" @click="reset">{{
                $t("CK_KS_6")
              }}</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="tablebox">
      <div class="tabletop">
        <span class="tabletoptitle1">{{ $t("w_0157") }}：</span>
        <span class="tabletoptitle2">{{
          toSum | toThousandthAndKeepDecimal
        }}</span>
      </div>
      <el-table
        height="1351px"
        :data="tableData"
        :header-cell-style="{ background: '#EEEEEE' }"
        :row-class-name="tableRowClassName"
      >
        <!-- :row-class-name="tableRowClassName" -->
        <el-table-column
          align="center"
          prop="settleDate"
          width="200"
          :label="$t('N_I_130')"
        >
          <template slot-scope="scope">
            <div
              @click="dateHref(scope.row)"
              :class="marketList[0].isShow == true ? 'style1' : ''"
            >
              {{ scope.row.settleDate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="repurCoupon"
          :formatter="stateFormat"
          :label="$t('N_I_147') + `(${isLocalSymbol()})`"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="repurCouponShare"
          :formatter="stateFormat"
          :label="$t('w_0192') + `(${isLocalSymbol()})`"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="serviceSpend"
          :formatter="stateFormat"
          :label="$t('N_I_137') + `(${isLocalSymbol()})`"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="repurCouponSubtotal"
          :formatter="stateFormat"
          :label="$t('N_I_138') + `(${isLocalSymbol()})`"
        ></el-table-column>
        <!--          <el-table-column align="center" prop="" :label="$t('MY_ORD_14')">-->
        <!--            <template slot-scope="scope">-->
        <!--              <el-button style="color:red" @click="goSource(scope.row)" type="text" size="small">查看详情</el-button>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
      </el-table>
      <pagination
        v-if="userInfo.pkCountry != 1"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="research"
      />
    </div>
  </div>
</template>

<script>
import sidebarUserInfo from "@/components/sidebarUserInfo.vue";
import topBanner from "@/components/topBanner.vue";
import pagination from "@/components/pagination.vue";
import {
  getQueryBonus,
  queryBonusCoupon,
  queryBonusMarker,
} from "@/api/bonus.js";
import user from "@/store/modules/user";
import { mapGetters } from "vuex";
import { queryBonusTotal } from "../../api/bonus";
import { isLocalSymbol } from "../../util/numberToCurrency";
export default {
  name: "IncomeDetail",
  components: {
    sidebarUserInfo,
    topBanner,
    pagination,
  },
  data() {
    return {
      totals: [],
      marketList: [
        {
          text: this.$t("ENU_MENU_540"),
          id: 3,
          path: "/marketDynamics",
          name: "bonusSource",
          isShow: false,
        },
      ],
      specialArea: 0,
      thetime: "",
      tableData: [],
      total: 0,
      money: 0.0,
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        startDate: "",
        endDate: "",
      },
    };
  },
  watch: {
    $route(n, o) {
      this.specialArea = n.query.id;
    },
  },
  computed: {
    toSum() {
      var sum = 0;
      this.totals.forEach((ele) => {
        sum += (ele.realIncomeTotal * 100) / 100;
      });
      sum = sum.toFixed(2);
      return sum;
    },
    user() {
      return user;
    },
    ...mapGetters(["userInfo"]),
  },
  created() {},
 
  mounted() {
   
     (this.specialArea = this.$route.query.id);
    this.research();
    this.getMenuLists();
  },
  methods: {
    isLocalSymbol,
    getMenuLists() {
      let lists = JSON.parse(localStorage.getItem("menuList"));
      lists.forEach((item) => {
        if (this.marketList[0].name == item.menuKey) {
          this.marketList[0].isShow = true;
        }
      });
    },
    reset() {
      this.tableData = [];
      this.thetime = "";
      (this.queryParams = {
        pageNum: 1,
        pageSize: 15,
        startDate: "",
        endDate: "",
      }),
        this.research();
    },
    dateHref(item) {
      if (this.marketList[0].isShow == true) {
        this.$router.push({
          path: "/marketDynamics",
          query: { id: 3, index: 1 },
        });
      }
    },
    goSource() {
      this.$router.push({
        path: "/bonusSource",
        query: { id: 3 },
      });
    },
    changeArea(item) {
      this.specialArea = item.id;
      //   this.research()
      this.$router.push({
        path: item.path,
        query: { id: item.id },
      });
    },
    research() {
      if (this.thetime) {
        this.queryParams.startDate = this.thetime[0];
        this.queryParams.endDate = this.thetime[1];
      }
      queryBonusCoupon(
        this.queryParams,
        `?pageNum=${this.queryParams.pageNum}&pageSize=${this.queryParams.pageSize}`
      ).then((res) => {
        res.rows.forEach((ele) => {
          if (ele.makerRealSubtotal == undefined) {
            ele.makerRealSubtotal = "0.00";
          }
        });
        this.tableData = res.rows;
        this.total = res.total;
      });
      queryBonusTotal({}).then((res) => {
        this.totals = res.rows;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.style1 {
  cursor: pointer;
  text-decoration: underline;
  color: #d61820;
}
.tabletop {
  position: sticky;
  top: 100px; //这个值根据实际情况而定
  z-index: 10;
  background-color: #fff;
}
:deep(.el-table) {
  overflow: visible;
}
:deep(.el-table__header-wrapper) {
  position: sticky;
  top: 150px; //这个值根据实际情况而定
  z-index: 10;
}
::v-deep .el-range-editor.el-input__inner {
  width: 100%;
  height: 32px;
  padding: 0 10px;
}
::v-deep .select .el-input--suffix .el-input__inner {
  height: 32px;
}
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
.contain {
  background: rgba(249, 249, 249, 1);
  height: 100vh;
  padding: 47px 62px 47px 0;
  //display: flex;
  .goodArea {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 100%;
    height: 80px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    .goodArea_i {
      margin: 0 35px;
      text-align: center;
      cursor: pointer;
    }
    .actArea {
      color: #b42b2a;
    }
  }
  .topbox {
    // height: 109px;
    width: 100% !important;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 0 0;
    padding: 15px 0 0 0;
    .searchbox {
      display: flex;
      align-items: center;
      margin-left: 40px;
      .btn1 {
        border: 1px solid #cccccc;
        border-radius: 5px 5px 5px 5px;
        color: #666;
        height: 34px;
        width: 68px;
        padding: 0;
      }
      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
        height: 34px;
        width: 68px;
        padding: 0;
      }
    }
  }
  .tablebox {
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
    margin-top: 20px;
    .tabletop {
      text-align: right;
      padding: 20px;
      .tabletoptitle1 {
        font-size: 18px;
      }
      .tabletoptitle2 {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}
</style>