/*
 * @Descripttion:
 * @version:
 * @Author: kBank
 * @Date: 2022-10-12 14:28:05
 */
import request from '@/util/request'
// banner
export function getActivity(data) {
    return request({
        url: '/activity/api/base/activity-list',
        method: 'get',
        data
    })
}
// 月度新增业绩对比
export function achieveContrast(params) {
    return request({
        url: '/member/api/achieve/new-add-achieve-contrast',
        method: 'get',
        params
    })
}
// 首购 {{$t('w_0169')}}业绩
export function achieveDay(params) {
    return request({
        url: '/report/api/achieve/new-add-achieve-day',
        method: 'get',
        params
    })
}

export function getActivityList(data) {
    return request({
        url: '/activity/api/pick/list',
        method: 'get',
        data
    })
}
export function pickLog(data) {
    return request({
        url: '/activity/api/pick/pick-log',
        method: 'get',
        data
    })
}

//活跃会员对比
export function getActiveMember(params) {
    return request({
        url: '/member/api/active-member-census/activeMember',
        method: 'get',
        params
    })
}

//活跃会员查询条件下拉选
export function getDayType(params) {
    return request({
        url: '/system/pub/enums/get-day-type',
        method: 'get',
        params
    })
}

//新增业绩统计
export function getPlaceNewAddAchieve(params) {
    return request({
        url: '/member/api/achieve/place-new-add-achieve-stat',
        method: 'get',
        params
    })
}

// 获取直推业绩左侧
export function parentAchieveLeft(data) {
    return request({
        url: '/member/api/achieve/parent-achieve-left',
        method: 'post',
        data
    })
}

// 获取直推业绩右侧
export function parentAchieveRight(data) {
    return request({
        url: '/member/api/achieve/parent-achieve-right',
        method: 'post',
        data
    })
}

// 获取等级
export function systemGrandeRange(params) {
    return request({
        url: '/system/manage/grade/gradeRanglist',
        method: 'get',
        params
    })
}


export function getYjList(params) {
    return request({
        url: '/system/pub/enums/achievement-type-member',
        method: 'get',
        params
    })
}
