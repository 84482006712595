<!--
 * @Descripttion:
 * @version:
 * @Author: 王三华
 * @Date: 2023-06-21 17:59:22
-->
<template>
  <div class="contain">
    <el-dialog :title="paytitle"
               :visible.sync="toShow"
               width="30%"
               center
               :close-on-click-modal="false"
               :before-close="handleClose">
      <div class="diaBox">
        <!-- 充值 -->
        <template v-if="ifcz">
          <div class="tit4">{{ $t('w_0212') }}</div>
          <el-input class="czinputbox"
                    v-model="czJe"></el-input>
        </template>
        <div class="tit1">
            {{ userInfo.currencyIcon }}{{ objTree.amount | numberToCurrency }}
          </div>
<template v-if="!isTree">
  <div class="tit1">
    {{ userInfo.currencyIcon }}{{ orderData.orderAmount }}
  </div>
  <div class="tit2">
    {{ $t('w_0213') }}
    <div class="tit3">{{ countDown }}</div>
    {{ $t('w_0214') }}
  </div>
</template>
        <div class="tit4" v-if="!isTree">{{ $t('w_0215') }}</div>
        <div class="quan">
          <el-collapse v-model="activeNames">
            <el-collapse-item name="1"
                              v-if="!ifcz&isShare&userInfo.memberCode!='CN68880628'">
<template slot="title">
    <div class="pf">
        <div class="pf_l">
            <img src="@/assets/images/yhkzf.jpg" alt="" />
            <div>{{ $t('w_0216') }}</div>
        </div>
        <el-radio v-model="whatPay" @click.prevent="selPayRadio(0, $event)" :label="0">{{ " " }}
        </el-radio>
    </div>
</template>
              <div class="quan_i"
                   v-show="account.consumeBalance == 0||account.consumeDeduct > 0">
                <div class="tTit">{{ $t('w_0217') }}</div>
                <div class="lan">
                  <div>
                    {{ userInfo.currencyIcon
                    }}{{ account.consumeBalance | numberToCurrency }}
                  </div>
                  <div>
                    -{{ userInfo.currencyIcon
                    }}{{ account.consumeDeduct | numberToCurrency }}
                  </div>
                </div>
              </div>

              <div class="quan_i"
                   v-show="payDetail.payAccount3 > 0">
                <div class="tTit">{{ $t('w_0219') }}</div>
                <div class="lan">
                  <div>
                    {{ userInfo.currencyIcon
                    }}{{ payDetail.account3 | numberToCurrency }}
                  </div>
                  <div>
                    -{{ userInfo.currencyIcon
                    }}{{ payDetail.payAccount3 | numberToCurrency }}
                  </div>
                </div>
              </div>
              <div class="quan_i"
                   v-show="account.bonusBalance==0||account.bonusDeduct > 0">
                <div class="tTit">{{ $t('w_0220') }}</div>
                <div class="lan">
                  <div>
                    {{ userInfo.currencyIcon
                    }}{{ account.bonusBalance | numberToCurrency }}
                  </div>
                  <div>
                    -{{ userInfo.currencyIcon
                    }}{{ account.bonusDeduct | numberToCurrency }}
                  </div>
                </div>
              </div>
              <div class="quan_i"
                   v-show="account.cashBalance==0||account.cashDeduct > 0">
                <div class="tTit">{{ $t('w_0218') }}</div>
                <div class="lan">
                  <div>
                    {{ userInfo.currencyIcon
                    }}{{ account.cashBalance | numberToCurrency }}
                  </div>
                  <div>
                    -{{ userInfo.currencyIcon
                    }}{{ account.cashDeduct | numberToCurrency }}
                  </div>
                </div>
              </div>
              <div class="quan_i"
                   v-show="payDetail.payAccount5 > 0">
                <div class="tTit">{{ $t('w_0221') }}</div>
                <div class="lan">
                  <div>
                    {{ userInfo.currencyIcon
                    }}{{ payDetail.account5 | numberToCurrency }}
                  </div>
                  <div>
                    -{{ userInfo.currencyIcon
                    }}{{ payDetail.payAccount5 | numberToCurrency }}
                  </div>
                </div>
              </div>
              <div class="quan_i"
                   v-show="payDetail.payAccount6 > 0">
                <div class="tTit">{{ $t('w_0222') }}</div>
                <div class="lan">
                  <div>
                    {{ userInfo.currencyIcon
                    }}{{ payDetail.account6 | numberToCurrency }}
                  </div>
                  <div>
                    -{{ userInfo.currencyIcon
                    }}{{ payDetail.payAccount6 | numberToCurrency }}
                  </div>
                </div>
              </div>
              <div class="quan_i"
                   v-show="payDetail.payAccount7 > 0">
                <div class="tTit">{{ $t('w_0223') }}</div>
                <div class="lan">
                  <div>
                    {{ userInfo.currencyIcon
                    }}{{ payDetail.account7 | numberToCurrency }}
                  </div>
                  <div>
                    -{{ userInfo.currencyIcon
                    }}{{ payDetail.payAccount7 | numberToCurrency }}
                  </div>
                </div>
              </div>
              <div class="quan_i"
                   v-show="payDetail.payAccount8 > 0">
                <div class="tTit">复购券账户</div>
                <div class="lan">
                  <div>
                    {{ userInfo.currencyIcon
                    }}{{ payDetail.account8 | numberToCurrency }}
                  </div>
                  <div>
                    -{{ userInfo.currencyIcon
                    }}{{ payDetail.payAccount8 | numberToCurrency }}
                  </div>
                </div>
              </div>
              <!-- </div> -->
              <div class="quan_i"
                   v-show="payDetail.payAccount9 > 0">
                <div class="tTit">全球积分</div>
                <div class="lan">
                  <div>
                    {{ userInfo.currencyIcon
                    }}{{ payDetail.account9 | numberToCurrency }}
                  </div>
                  <div>
                    -{{ userInfo.currencyIcon
                    }}{{ payDetail.payAccount9 | numberToCurrency }}
                  </div>
                </div>
              </div>
              <div class="quan_i"
                   v-show="payDetail.payAccount10 > 0">
                <div class="tTit">车奖积分</div>
                <div class="lan">
                  <div>
                    {{ userInfo.currencyIcon
                    }}{{ payDetail.account10 | numberToCurrency }}
                  </div>
                  <div>
                    -{{ userInfo.currencyIcon
                    }}{{ payDetail.payAccount10 | numberToCurrency }}
                  </div>
                </div>
              </div>
              <!-- </div> -->
            </el-collapse-item>
            <div class="quan_r"
                 v-if="ifcz">
              <li class="flex_ac"
                  v-show="this.payList.pay9"
                  @click.prevent="selPayRadio(1)">
                <div class="flex_ac_i">
                  <img src="@/assets/images/pay_i2.png"
                       alt="" />
                  <span>微信支付</span>
                </div>
                <el-radio v-model="whatPay"
                          :label="1"><span></span></el-radio>
              </li>
              <li class="flex_ac"
                  v-show="this.payList.pay8"
                  @click.prevent="selPayRadio(2)">
                <div class="flex_ac_i">
                  <img src="@/assets/images/pay_i3.png"
                       alt="" />
                  <span>{{ $t('ENU_PAY_TY_1') }}</span>
                </div>
                <el-radio v-model="whatPay"
                          :label="2"><span></span></el-radio>
              </li>
              <li class="flex_ac"
                  v-show="this.payList.pay10"
                  @click.prevent="selPayRadio(3)">
                <div class="flex_ac_i">
                  <img src="@/assets/images/pay_i2.png"
                       alt="" />
                  <span>{{ $t('w_0227') }}</span>
                </div>
                <el-radio v-model="whatPay"
                          :label="3"><span></span></el-radio>
              </li>
<!--              <li class="flex_ac"-->
<!--                  v-show="this.payList.pay11"-->
<!--                  @click.prevent="selPayRadio(4)">-->
<!--                <div class="flex_ac_i">-->
<!--                  <img src="@/assets/images/pay_i3.png"-->
<!--                       alt="" />-->
<!--                  <span>{{ $t('w_0228') }}</span>-->
<!--                </div>-->
<!--                <el-radio v-model="whatPay"-->
<!--                          :label="4"><span></span></el-radio>-->
<!--              </li>-->
              <li class="flex_ac"
                  v-show="this.payList.pay12"
                  @click.prevent="selPayRadio(12)">
                <div class="flex_ac_i">
                  <img src="@/assets/images/pay_i2.png"
                       alt="" />
                  <span>{{ $t('w_0229') }}</span>
                </div>
                <el-radio v-model="whatPay"
                          :label="12"><span></span></el-radio>
              </li>
              <!-- 汇付微信 -->
              <li class="flex_ac"
                  v-show="this.payList.pay13"
                  @click.prevent="selPayRadio(13)">
                <div class="flex_ac_i">
                  <img src="@/assets/images/pay_i2.png"
                       alt="" />
                  <span>{{ $t('w_0230') }}</span>
                </div>
                <el-radio v-model="whatPay"
                          :label="13"><span></span></el-radio>
              </li>
              <!-- {{ $t('w_0231') }} -->
              <!-- <li class="flex_ac"
                    v-show="this.payList.pay15"
                    @click="beforeScanPay('hfYhk')">
                  <div class="flex_ac_i">
                    <img src="@/assets/images/jdBank.jpg"
                         alt="" />
                    <span>{{ $t('w_0231') }}支付</span>
                  </div>
                  <el-radio v-model="whatPay"
                            :label="15">{{ }}</el-radio>
                </li> -->
              <el-collapse accordion
                           v-show="this.payList.pay15">
                <el-collapse-item>
<template slot="title">
    <div class="flex_ac_i">
        <img src="@/assets/images/jdBank.jpg" alt="" />
        <span>{{ $t('w_0231') }} ({{ $t('w_1031') }})</span>
    </div>
</template>
                  <div class="pad">
                    <div v-for="(item, index) in hfList"
                         :key="index"
                         class="pay_flax flex_bet"
                         @click.prevent="selPayRadio('hf' + index)">
                      <div class="flax_i">
                        {{ item.bankName }} ({{ item.bankNo }})
                      </div>
                      <el-radio v-model="whatPay"
                                :label="'hf' + index">{{
                      }}</el-radio>
                    </div>
                    <div class="pay_flax flax_i"
                         @click="bindBank('hf')">
                      <img class="img1"
                           src="@/assets/images/addto.png"
                           alt="" />
                      <div>{{ $t('w_0179') }}</div>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
              <li class="flex_ac"
                  v-show="this.payList.pay0"
                  @click.prevent="selPayRadio(5)">
                <div class="flex_ac_i">
                  <img src="@/assets/images/pay_i2.png"
                       alt="" />
                  <span>{{ $t('w_0232') }}</span>
                </div>
                <el-radio v-model="whatPay"
                          :label="5"><span></span></el-radio>
              </li>
              <li class="flex_ac"
                  v-show="this.payList.pay1"
                  @click.prevent="selPayRadio(6)">
                <div class="flex_ac_i">
                  <img src="@/assets/images/pay_i3.png"
                       alt="" />
                  <span>{{ $t('w_0233') }}</span>
                </div>
                <el-radio v-model="whatPay"
                          :label="6"><span></span></el-radio>
              </li>
              <el-collapse accordion
                           v-show="this.payList.pay4">
                <el-collapse-item>
<template slot="title">
    <div class="flex_ac_i">
        <img src="@/assets/images/jdBank.jpg" alt="" />
        <span>{{ $t('w_0234') }} ({{ $t('w_1031') }})</span>
    </div>
</template>
                  <div class="pad">
                    <div v-for="(item, index) in jdList"
                         :key="index"
                         class="pay_flax flex_bet"
                         @click.prevent="selPayRadio('jd' + index)">
                      <div class="flax_i">
                        {{ item.bankName }} ({{ item.bankNo }})
                      </div>
                      <el-radio v-model="whatPay"
                                :label="'jd' + index">{{
                      }}</el-radio>
                    </div>
                    <div class="pay_flax flax_i"
                         @click="bindBank('jd')">
                      <img class="img1"
                           src="@/assets/images/addto.png"
                           alt="" />
                      <div>{{ $t('w_0179') }}</div>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
              <!-- <li class="flex_ac flex_bc"
                    v-show="this.payList.pay4">
                  <div class="flex_ac_i">
                    <img src="@/assets/images/jdBank.jpg"
                         alt="" />
                    <span>{{ $t('w_0234') }}</span>
                  </div>
                  <div class="pad">
                    <div v-for="(item, index) in jdList"
                         :key="index"
                         class="pay_flax flex_bet"
                         @click.prevent="selPayRadio('jd' + index)">
                      <div class="flax_i">
                        {{ item.bankName }} ({{ item.bankNo }})
                      </div>
                      <el-radio v-model="whatPay"
                                :label="'jd' + index">{{
                      }}</el-radio>
                    </div>
                    <div class="pay_flax flax_i"
                         @click="bindBank(false)">
                      <img class="img1"
                           src="@/assets/images/addto.png"
                           alt="" />
                      <div>{{ $t('w_0179') }}</div>
                    </div>
                  </div>
                </li> -->
              <li class="flex_ac"
                  v-show="this.payList.pay5"
                  @click.prevent="selPayRadio(7)">
                <div class="flex_ac_i">
                  <img src="@/assets/images/pay_i2.png"
                       alt="" />
                  <span>{{ $t('w_0235') }}</span>
                </div>
                <el-radio v-model="whatPay"
                          :label="7"><span></span></el-radio>
              </li>
              <li class="flex_ac"
                  v-show="this.payList.pay6"
                  @click.prevent="selPayRadio(8)">
                <div class="flex_ac_i">
                  <img src="@/assets/images/pay_i3.png"
                       alt="" />
                  <span>{{ $t('w_0236') }}</span>
                </div>
                <el-radio v-model="whatPay"
                          :label="8"><span></span></el-radio>
              </li>
              <li class="flex_ac flex_bc"
                  v-show="this.payList.pay7">
                <div class="flex_ac_i">
                  <img src="@/assets/images/qb.png"
                       alt="" />
                  <span>{{ $t('w_0237') }} ({{ $t('w_1031') }})</span>
                </div>
                <div class="pad">
                  <div v-for="(item, index) in tlList"
                       :key="index"
                       class="pay_flax flex_bet"
                       @click.prevent="selPayRadio('tl' + index)">
                    <div class="flax_i">
                      {{ item.bankName }} ({{ item.bankNo }})
                    </div>
                    <el-radio v-model="whatPay"
                              :label="'tl' + index">{{
                    }}</el-radio>
                  </div>
                  <div class="pay_flax flax_i"
                       @click="bindBank('tl')">
                    <img class="img1"
                         src="@/assets/images/addto.png"
                         alt="" />
                    <div>{{ $t('w_0179') }}</div>
                  </div>
                </div>
              </li>
            </div>
          </el-collapse>
        </div>
<template v-if="!ifcz">
  <div v-show="specialArea == 1||specialArea == 24">
    <div class="tit4">{{ $t('w_0238') }}</div>
    <div class="quan">
      <div class="kuang_i">
        <div>{{ $t('w_0239') }}</div>
        <div>{{ orderData.memberCode }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0240') }}</div>
        <div>{{ orderData.pkGradeVal }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('MY_CK_8') }}</div>
        <div>{{ orderData.memberName }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('MY_CK_9') }}</div>
        <div>{{ orderData.phone }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('N_I_164') }}</div>
        <div>{{ orderData.parent }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('N_I_165') }}</div>
        <div>{{ orderData.parentName }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('N_I_167') }}</div>
        <div>{{ orderData.placeParent }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('N_I_166') }}</div>
        <div>{{ orderData.placeParentName }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0241') }}</div>
        <div>{{ orderData.placeDeptVal }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0061') }}</div>
        <div>{{ orderData.deliveryWayVal }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0063') }}</div>
        <div>{{ orderData.transTypeVal }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('MY_ORD_10') }}</div>
        <div>{{ orderData.recName }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0242') }}</div>
        <div>{{ orderData.recPhone }}</div>
      </div>
      <div class="kuang_i">
        <div style="white-space: nowrap">{{ $t('w_0068') }}</div>
        <div>
          {{ orderData.recProvince }}{{ orderData.recCity }}{{ orderData.recAddress }}
        </div>
      </div>
    </div>
  </div>
  <div v-show="specialArea == 2||specialArea == 25">
    <div class="tit4">{{ $t('w_0243') }}</div>
    <div class="quan">
      <div class="kuang_i">
        <div>{{ $t('w_0095') }}</div>
        <div>{{ orderData.memberCode }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0098') }}</div>
        <div>{{ orderData.memberName }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0099') }}</div>
        <div>{{ orderData.pkGradeVal }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0061') }}</div>
        <div>{{ orderData.deliveryWayVal }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0063') }}</div>
        <div>{{ orderData.transTypeVal }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('MY_ORD_10') }}</div>
        <div>{{ orderData.recName }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0242') }}</div>
        <div>{{ orderData.recPhone }}</div>
      </div>
      <div class="kuang_i">
        <div style="white-space: nowrap">{{ $t('w_0068') }}</div>
        <div>
          {{ orderData.recProvince }}{{ orderData.recCity }}{{ orderData.recAddress }}
        </div>
      </div>
    </div>
  </div>
  <div v-show="specialArea == 3||specialArea == 26">
    <div class="tit4">{{ $t('w_0244') }}</div>
    <div class="quan">
      <div class="kuang_i">
        <div>{{ $t('w_0245') }}</div>
        <div>{{ orderData.memberCode }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0246') }}</div>
        <div>{{ orderData.memberName }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0061') }}</div>
        <div>{{ orderData.deliveryWayVal }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0063') }}</div>
        <div>{{ orderData.transTypeVal }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('MY_ORD_10') }}</div>
        <div>{{ orderData.recName }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0242') }}</div>
        <div>{{ orderData.recPhone }}</div>
      </div>
      <div class="kuang_i">
        <div style="white-space: nowrap">{{ $t('w_0068') }}</div>
        <div>
          {{ orderData.recProvince }}{{ orderData.recCity }}{{ orderData.recAddress }}
        </div>
      </div>
    </div>
  </div>
  <div v-show="specialArea == 7">
    <div class="tit4">{{ $t('w_0247') }}</div>
    <!-- <div class="quan">
                      <div class="kuang_i">
                        <div>{{ $t('w_0240') }}</div>
                        <div>{{ orderData.pkGradeVal }}</div>
                      </div>
                    </div> -->
    <div class="quan">
      <div class="kuang_i">
        <div>{{ $t('w_0239') }}</div>
        <div>{{ orderData.memberCode }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0240') }}</div>
        <div>{{ orderData.pkGradeVal }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('MY_CK_8') }}</div>
        <div>{{ orderData.memberName }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('MY_CK_9') }}</div>
        <div>{{ orderData.phone }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('N_I_164') }}</div>
        <div>{{ orderData.parent }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('N_I_165') }}</div>
        <div>{{ orderData.parentName }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('N_I_167') }}</div>
        <div>{{ orderData.placeParent }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('N_I_166') }}</div>
        <div>{{ orderData.placeParentName }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0241') }}</div>
        <div>{{ orderData.placeDeptVal }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0061') }}</div>
        <div>{{ orderData.deliveryWayVal }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0063') }}</div>
        <div>{{ orderData.transTypeVal }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('MY_ORD_10') }}</div>
        <div>{{ orderData.recName }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0242') }}</div>
        <div>{{ orderData.recPhone }}</div>
      </div>
      <div class="kuang_i">
        <div style="white-space: nowrap">{{ $t('w_0068') }}</div>
        <div>
          {{ orderData.recProvince }}{{ orderData.recCity }}{{ orderData.recAddress }}
        </div>
      </div>
    </div>
  </div>
  <div v-show="specialArea == 13">
    <div class="tit4">{{$t('w_0059')}}</div>
    <div class="quan">
      <div class="kuang_i">
        <div>{{ $t('N_I_124') }}</div>
        <div>{{ orderData.memberCode }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('CK_KS_14') }}</div>
        <div>{{ orderData.memberName }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0061') }}</div>
        <div>{{ orderData.deliveryWayVal }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0063') }}</div>
        <div>{{ orderData.transTypeVal }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('MY_ORD_10') }}</div>
        <div>{{ orderData.recName }}</div>
      </div>
      <div class="kuang_i">
        <div>{{ $t('w_0242') }}</div>
        <div>{{ orderData.recPhone }}</div>
      </div>
      <div class="kuang_i">
        <div style="white-space: nowrap">{{ $t('w_0068') }}</div>
        <div>
          {{ orderData.recProvince }}{{ orderData.recCity }}{{ orderData.recAddress }}
        </div>
      </div>
    </div>
  </div>
</template>
        <div class="btn"
             @click="quickPay(ifcz)">{{ $t('w_0248') }}</div>
      </div>
    </el-dialog>
    <PayAasswordTree :isPw="isPw"
    ref="PayAasswordTree"
                 @closePwPay="closePwPay"></PayAasswordTree>
    <BindBank :isAdd="isAdd"
              :jdTl="jdTl"
              @closeBind="closeBind"></BindBank>
    <el-dialog :title="$t('w_0226')"
               :visible.sync="wxPopup"
               width="30%"
               :before-close="closewxPopup"
               center>
      <div class="pay_code">
        <div ref="qrCodeUrlWx"
             class="qrcode"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as api from '@/api/pay.js'
import * as act from '@/api/bonus.js'
import { generate } from '@/api/register.js'
import PayAasswordTree from '@/components/PayAasswordTree.vue'
import BindBank from '@/components/BindBank.vue'
import { setTokenMi } from '@/util/auth'
import QRCode from 'qrcodejs2'
var payStatus
export default {
  components: {
    PayAasswordTree,
    BindBank,
  },
  data() {
    return {
      zfhtml: '',
      // 微信支付弹窗
      wxPopup: false,
      // 支付弹框
      toShow: this.isPay,
      // 支付密码弹框
      isPw: false,
      // 绑定银行卡弹框
      isAdd: false,
      // 京东or通联
      jdTl: '',
      isBank: '',
      orderCode: '',
      businessType: '',
      specialArea: '',
      whatPay: 0,
      activeNames: ['1'],
      orderData: {},
      payDetail: {},
      // 钱包支付数据传递
      pwData: {},
      countDown: '',
      clockTime: '',
      jdList: [],
      tlList: [],
      hfList: [],
      paytitle: '',
      czJe: '',
      payList: {},
      sucPay: 0,
      luckydrawData: {},
      isShare: true,
      pkCountry: '',
      isTree: false,
      objTree: {},
      account: {},
    }
  },
  props: {
    isPay: {
      type: Boolean,
      default: false,
    },
    paramsPost: {},
    ifbaodan: {
      type: Boolean,
      default: true,
    },
    ifcz: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  watch: {
    isPay(val) {
      this.toShow = val
    },
    paramsPost: {
      handler(val) {
        this.pkCountry = parseInt(localStorage.getItem('pkCountry'))
        if (val.orderType == 4) {
          console.log('🌈抽奖aaaaa', val)
          this.orderCode = val.orderCode
          this.luckydrawData = val.luckydrawData
          // 抽奖支付处理（查询订单支付信息以及显示等）
          this.businessType = 4 //抽奖
          val.specialArea = this.specialArea = 4
          this.getLuckdrawDetail()
          this.pwData = val
        } else {
          this.orderCode = val.orderCode
          this.specialArea = val.specialArea
          this.businessType = 1 //直销
          // 获取订单信息
          this.getOrderInfo()

          if (
            this.userInfo.registerFans != 0 &&
            this.userInfo.registerShare != 0
          ) {
            // 获取消费信息
            this.getSpendInfo()
          } else {
            this.isShare = false
          }

          this.pwData = val
          clearInterval(this.clockTime)
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.ifcz) {
      this.paytitle = '充值'
    } else {
      this.paytitle = '待支付金额'
    }
    // // 获取订单信息
    // this.getOrderInfo()
    // // 获取消费信息
    // this.getSpendInfo()
    // 获取银行卡
    this.getBankList()
    // 获取支付配置
    this.getPayConfig()
  },
  methods: {
    getAccount() {
      api
        .deductionAccount({
          pkBaseId: this.objTree.pkBaseId,
          pkTreeRule: this.objTree.pkTreeRule,
          shopQuantity: this.objTree.quantity,
        })
        .then((res) => {
          this.account = res.data
        })
    },
    // 种树营口金额
    howMoney(obj) {
      this.objTree = obj
      console.log(
        '%c [ this.objTree ]-1095',
        'font-size:13px; background:#1324b4; color:#5768f8;',
        this.objTree
      )
      this.isTree = true
      this.getAccount()
    },
    //查询充值抽奖次数
    getLuckdrawDetail() {
      // 抽奖支付处理（查询订单支付信息以及显示等）
      act
        .getLuckdrawDetail({
          pkBaseId: this.luckydrawData.pkBaseId,
          payNum: this.luckydrawData.payNum,
        })
        .then((res) => {
          if (res.code == 200) {
            this.orderData.orderAmount = res.data.toBePaidMoney
            this.payDetail = res.data
            console.log('🌈6666666', this.payDetail)
            // const data = res.data;
            // this.price = data.price;
            // if (this.getUser.pkCountry == 1) {
            //   this.payDetail = {
            //     xjb: data.xjb,
            //     ykxjb: data.ykxjb,
            //   };
            // } else {
            //   this.payDetail = {
            //     xfb: data.xfb,
            //     ykxfb: data.ykxfb,
            //     jjb: data.jjb,
            //     ykjjb: data.ykjjb,
            //   };
            // }
            this.downTime()
          }
        })
    },
    closewxPopup() {
      this.wxPopup = false
      this.$refs.qrCodeUrlWx.innerHTML = ''
      // this.isPay = false
      clearInterval(payStatus)
    },
    getPayConfig() {
      api.payConfig().then((res) => {
        this.payList = res.data
      })
    },
    selPayRadio(val, e) {
      if (e) {
        e.stopPropagation()
      }
      this.whatPay = val
    },
    bindBank(val) {
      this.isAdd = true
      this.jdTl = val
    },
    getBankList() {
      api.jdBankList().then((res) => {
        this.jdList = res.data
      })
      // api.tlBankList().then((res) => {
      //   this.tlList = res.data
      // })
      api.hfBankList().then((res) => {
        this.hfList = res.data
      })
    },
    quickPay(cz) {
      if ((this.isTree = true)) {
        this.isPw = true
        this.$refs.PayAasswordTree.howMoney(this.objTree)
      }
      // 非银行卡
      if (typeof this.whatPay == 'number') {
        // 钱包支付
        if (this.whatPay == 0) {
          // 充值
          if (!cz) {
            //支付
            // 幂等性
            generate().then((res) => {
              setTokenMi(res.msg)
            })
            this.isPw = true
          } else {
            if (!this.czJe || this.czJe < 0) {
              this.$message({
                message: '请输入正确的充值金额',
                type: 'warning',
              })
            } else {
              //充值
              api.preCharge({ rechargeAmount: this.czJe }).then((res) => {
                this.paramsPost = res.data
                this.isPw = true
              })
            }
          }
          // 宝付微信
        } else if (this.whatPay == 12) {
          if (!cz) {
            this.scanPayBf()
          } else {
            api.preCharge({ rechargeAmount: this.czJe }).then((res) => {
              this.orderCode = res.orderCode
              this.businessType = 3
              this.scanPayBf()
            })
          }
          // 汇付微信
        } else if (this.whatPay == 13) {
          if (!cz) {
            this.scanPayHf()
          } else {
            api.preCharge({ rechargeAmount: this.czJe }).then((res) => {
              this.orderCode = res.orderCode
              this.businessType = 3
              this.scanPayHf()
            })
          }
        }
      } else {
        // 京东银行卡支付
        if (this.whatPay.slice(0, 2) == 'jd') {
          let indexed = this.whatPay.slice(2, 4)
          // 充值
          if (!cz) {
            let params = {
              businessType: 1, //订单类型
              businessCode: this.orderCode,
              payChannel: 4, //京东
              payType: 3, //银行卡
              bindCode: this.jdList[indexed].bindCode,
            }
            api.unifiedorder(params).then((response) => {
              this.isBank = 'jd'
              this.pwData = Object.assign({}, this.pwData, {
                bindCode: this.jdList[indexed].bindCode,
                orderCode: this.orderCode,
              })
              this.isPw = true
            })
            this.checkPayStatus(params)
          } else {
            api.preCharge({ rechargeAmount: this.czJe }).then((res) => {
              let params = {
                businessType: 3, //订单类型充值
                businessCode: res.orderCode,
                payChannel: 4, //京东
                payType: 3, //银行卡
                bindCode: this.jdList[indexed].bindCode,
              }
              api.unifiedorder(params).then((response) => {
                this.isBank = 'jd'
                this.pwData = Object.assign({}, this.pwData, {
                  bindCode: this.jdList[indexed].bindCode,
                  orderCode: res.orderCode,
                })
                this.isPw = true
              })
            })
          }
          // {{ $t('w_0231') }}支付
        } else if (this.whatPay.slice(0, 2) == 'hf') {
          let indexed = this.whatPay.slice(2, 4)
          // 充值
          if (!cz) {
            let params = {
              businessType: 1, //订单类型
              businessCode: this.orderCode,
              payChannel: 6, //汇付
              payType: 3, //银行卡
              bindCode: this.hfList[indexed].bindCode,
            }
            api.unifiedorder(params).then((response) => {
              this.isBank = 'hf'
              this.pwData = Object.assign({}, this.pwData, {
                bindCode: this.hfList[indexed].bindCode,
                orderCode: this.orderCode,
              })
              this.isPw = true
            })
            this.checkPayStatus(params)
          } else {
            api.preCharge({ rechargeAmount: this.czJe }).then((res) => {
              let params = {
                businessType: 3, //订单类型充值
                businessCode: res.orderCode,
                payChannel: 6, //汇付
                payType: 3, //银行卡
                bindCode: this.hfList[indexed].bindCode,
              }
              api.unifiedorder(params).then((response) => {
                this.isBank = 'hf'
                this.pwData = Object.assign({}, this.pwData, {
                  bindCode: this.hfList[indexed].bindCode,
                  orderCode: res.orderCode,
                })
                this.isPw = true
              })
            })
          }
        }
      }
    },
    // 宝付微信
    // 支付渠道 payChannel（0=杉德，1=支付宝，2=微信，3=通联，4=京东，5=宝付，6=汇付）
    scanPayBf() {
      let params = {
        businessType: this.businessType, //订单类型充值
        businessCode: this.orderCode,
        payChannel: 5, //宝付
        payType: 2,
      }
      api.unifiedorder(params).then((res) => {
        this.wxPopup = true
        this.$nextTick(() => {
          new QRCode(
            this.$refs.qrCodeUrlWx,
            {
              width: 300,
              height: 300,
              text: res.data,
              colorDark: '#000000',
              colorLight: '#ffffff',
              correctLevel: QRCode.CorrectLevel.Q,
              textAlign: 'center',
            },
            1000
          )
        })
      })
      this.checkPayStatus(params)
    },
    // 汇付微信
    scanPayHf() {
      let params = {
        businessType: this.businessType, //订单类型充值
        businessCode: this.orderCode,
        payChannel: 6, //汇付
        payType: 2, //银行卡
      }
      api.unifiedorder(params).then((res) => {
        this.wxPopup = true
        this.$nextTick(() => {
          new QRCode(
            this.$refs.qrCodeUrlWx,
            {
              width: 300,
              height: 300,
              text: res.data,
              colorDark: '#000000',
              colorLight: '#ffffff',
              correctLevel: QRCode.CorrectLevel.Q,
              textAlign: 'center',
            },
            1000
          )
        })
      })
      this.checkPayStatus(params)
    },
    // 查询订单状态
    checkPayStatus(data) {
      // let payStatus
      let that = this
      // 定时器
      payStatus = setInterval(() => {
        if (that.sucPay == 1) {
          // 清除定时器
          clearInterval(payStatus)
          this.$alert('', '支付成功', {
            confirmButtonText: this.$t('w_0035'),
            callback: (action) => {
              this.$emit('closePay', 1)
            },
          })
        } else {
          api.payStatus(data).then((res) => {
            that.sucPay = res.data
          })
        }
      }, 2000)
    },
    handleClose() {
      this.$emit('closePay', 0)
    },
    closePwPay(index) {
      if (index == 1) {
        this.isPw = false
        this.$emit('closePay', 1)
      } else {
        this.isPw = false
      }
    },
    closeBind() {
      this.isAdd = false
      this.getBankList()
    },
    getOrderInfo() {
      api
        .orderInfo({
          specialArea: this.specialArea,
          orderCode: this.orderCode,
          pkCountry: this.pkCountry,
        })
        .then((res) => {
          this.orderData = res.data
          this.downTime()
        })
    },
    downTime() {
      let that = this
      this.clockTime = setInterval(() => {
        if (that.orderData.payTime && that.orderData.payTime > 0) {
          that.countDown = this.getTime(this.orderData.payTime--)
        } else {
          that.countDown = '00:00:00'
        }
      }, 1000)
    },
    getSpendInfo() {
      api
        .spendDetails({
          specialArea: this.specialArea,
          orderCode: this.orderCode,
          pkCountry: this.pkCountry,
        })
        .then((res) => {
          this.payDetail = res.data
        })
    },
    getTime(time) {
      // 转换为式分秒
      let h = parseInt((time / 60 / 60) % 24)
      h = h < 10 ? '0' + h : h
      let m = parseInt((time / 60) % 60)
      m = m < 10 ? '0' + m : m
      let s = parseInt(time % 60)
      s = s < 10 ? '0' + s : s
      // 作为返回值返回
      return h + ':' + m + ':' + s
    },
  },
  beforeDestroy() {
    clearInterval(payStatus)
  },
}
</script>

<style lang="scss" scoped>
.contain {
  ::v-deep .el-collapse-item__header {
    // border-bottom: 1px solid rgba(24,144,255,0.3020);
    // border-radius: 8px;
    border: 0;
  }
  ::v-deep .el-collapse {
    // border: 1px solid rgba(24,144,255,0.3020);
    border: 0; // border-radius: 8px;
  }
  ::v-deep .el-collapse-item__wrap {
    border: 0;
  }
}

::v-deep .czinputbox .el-input__inner {
  height: 65px !important;
  margin-top: 10px;
  font-size: 18px;
}

.diaBox {
  // height: 70vh;
  overflow-y: auto;
  padding: 0 20px;
  max-height: 700px;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.tit1 {
  font-size: 24px;
  font-weight: 600;
  color: #e02b26;
  text-align: center;
  margin-bottom: 20px;
}

.tit2 {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tit3 {
  font-size: 14px;
  font-weight: 400;
  color: #e02b26;
  margin: 0 5px;
}

.tit4 {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  margin-top: 30px;
}

.quan {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 2px 20px 0px #efefef;
  padding: 15px;
  margin-top: 20px;
}

.pf {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  .pf_l {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #333333;
  }
  img {
    width: 24px;
    height: 21px;
    margin-right: 10px;
  }
}

.quan_i {
  padding: 0 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.quan_r {
  padding: 0 5px;
}

.kuang_i {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}

.lan {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  font-size: 18px;
  color: #666;
}

.tTit {
  font-size: 18px;
  color: #333;
}

.btn {
  position: sticky;
  bottom: 0px;
  width: 209px;
  height: 48px;
  line-height: 48px;
  background: #d61820;
  border-radius: 40px 40px 40px 40px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  margin: 25px auto;
  cursor: pointer;
}

.flex_ac {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 0;
}

.flex_ac_i {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  font-weight: 400;
  img {
    width: 16px;
    height: 16px;
    margin-right: 20px;
  }
}

.flex_bc {
  flex-direction: column;
  align-items: flex-start;
}

.pad {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pay_flax {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  cursor: pointer;
}

.flax_i {
  flex: 1;
  margin-left: 36px;
}

.flex_bet {
  justify-content: space-between;
}

.img1 {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.pay_code {
  text-align: center;
  .qrcodeWx {
    margin-top: 20px;
  }
}

.qrcode {
  display: inline-block;
  img {
    width: 132px;
    height: 132px;
    background-color: #fff; //设置白色背景色
    padding: 6px; // 利用padding的特性，挤出白边
    box-sizing: border-box;
  }
}
</style>
