<template>
  <div class="contain">
    <div class="rightbox" style="width: 100%">
      <div></div>
      <div class="top-lists">
        <div
          class="nav-view"
          @click="navTap(index)"
          v-show="item.menuKey != ''"
          :class="navIndex == index ? 'styles' : ''"
          v-for="(item, index) in navList"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>

      <div class="content-s">
        <!-- 业绩分布!-->
        <div v-if="navIndex == 0 && navList[0].menuKey != '' && ifDetail == 0">
          <div class="content-div1">
            <div class="yjfb_flex">
              <div style="margin-left: 20px">
                <el-date-picker
                  v-model="fgkhYear"
                  type="year"
                  value-format="yyyy"
                  :picker-options="pickerOptions"
                  :placeholder="$t('CK_KS_38')"
                >
                </el-date-picker>
              </div>
              <div style="margin-left: 20px">
                <el-select
                  class="yjfb_select"
                  v-model="fgkhDate"
                  :placeholder="$t('CK_KS_38')"
                >
                  <el-option
                    v-for="item in selMounthList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div style="margin-left: 20px">
                <el-select
                  class="yjfb_select"
                  v-model="yjType"
                  :placeholder="$t('CK_KS_38')"
                >
                  <el-option
                    v-for="item in yjList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- 暂时隐藏 -->
              <!-- <div style="cursor: pointer;" @click="exportDetail">[导出明细]</div> -->
            </div>
            <!-- 业绩分布 -->
            <consanguinityPv
              :achieveData="achieveData"
              ref="consanguinityPv"
            ></consanguinityPv>
          </div>
          <div class="flex-s">
            <div class="content-div2">
              <div class="title">
                <div>{{ $t("N_I_83") }}</div>
              </div>
              <div style="position: absolute">
                <placement />
              </div>
            </div>
          </div>
        </div>
        <!-- 月度业绩详情 -->
        <div v-if="ifDetail != 0">
          <monthPvDetail
            :year="fgkhYear"
            :placeDept="ifDetail"
            :monthType="fgkhDate"
            :achievementType="yjType"
            :monthScoreType="monthScoreType"
          />
        </div>

        <!-- 月度新增业绩 -->
        <div class="width-auto-s" v-show="navIndex == 1">
          <div class="flex-s">
            <!-- 首购新增业绩 1个月!-->
            <div class="content-div3">
              <div class="title">
                <div>{{ $t("S_C_71") }}</div>
                <div>{{ $t("w_0146") }}</div>
              </div>
              <div style="position: absolute">
                <fistAddPv ref="fistAddPv" />
              </div>
            </div>
             <!-- 首购业绩分布!-->
             <div class="content-div3">
              <div class="title">
                <div>{{ $t("w_1041") }}</div>
              </div>
              <div style="position: absolute">
                <firstPv ref="firstPv"></firstPv>
              </div>
            </div>


          </div>
            <!-- 复购新增业绩!-->
              <!-- 复购业绩分布!-->
          <!-- <div class="flex-s">

            <div class="content-div3">
              <div class="title">
                <div>{{ $t("N_I_109") }}</div>
                <div>{{ $t("w_0146") }}</div>
              </div>
              <div style="position: absolute">
                <repAddPv ref="repAddPv" />
              </div>
            </div>

            <div class="content-div3">
              <div class="title">
                <div>{{ $t("N_I_114") }}</div>
              </div>
              <div style="position: absolute">
                <secondPv ref="secondPv"></secondPv>
              </div>
            </div>
          </div> -->
          <!-- <div class="flex-s"> -->
            <!-- 推荐业绩分布!-->
            <!-- <div class="content-div3">
              <div class="title">{{ $t("APP_ADD_20") }}</div>
              <div style="position: absolute">
                <placementPerformance ref="placementPerformance" />
              </div>
            </div> -->
            <!-- 推荐小区分布!-->
            <!-- <div class="content-div3">
              <div class="title">{{ $t("N_I_111") }}</div>
              <div style="position: absolute">
                <recommendedCommunity ref="recommendedCommunity" />
              </div>
            </div> -->
             <!-- 真实业绩 -->
             <!-- <div class="content-div3">
              <div class="title">
                <div>{{ $t("N_I_102") }}</div>
                <div>
                  <el-select
                    v-model="whatMounth1"
                    :placeholder="$t('CK_KS_38')"
                  >
                    <el-option
                      v-for="item in selMounthList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div style="position: absolute">
                <real-performances ref="realPerformances" :time="whatMounth1" />
              </div>
            </div> -->
          <!-- </div> -->

          <div class="flex-s">
            <!-- 真实业绩分布 -->
            <!-- <div class="content-div3">
              <div class="title">{{ $t("N_I_115") }}</div>
              <div>
                <realPerformance ref="realPerformance" />
              </div>
            </div> -->
            <!-- 月度新增业绩对比!-->
            <div class="content-div3">
              <div class="title">
                <div>{{ $t("w_0145") }}</div>
                <div>
                  <el-select v-model="whatMounth" :placeholder="$t('CK_KS_38')">
                    <el-option
                      v-for="item in selMounthList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div style="position: absolute">
                <mounthPvCompare ref="mounthPvCompare" :time="whatMounth" />
              </div>

            </div>
            <!-- 活跃会员对比!-->
            <div class="content-div3">
              <div class="title">
                <div>{{ $t("N_I_105") }}</div>
                <div>
                  <el-select v-model="dateType" :placeholder="$t('CK_KS_38')">
                    <el-option
                      v-for="item in dateTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div>
                <activeMembers
                  ref="activeMembers"
                  :time="dateType"
                ></activeMembers>
              </div>
            </div>
          </div>

          <!-- 推荐平均用时!-->
          <!-- <div class="kuangk">
            <div
              class="title"
              style="width: 100%; padding-top: 20px; justify-content: left"
            >
              {{ $t("ENU_MENU_515") }}
              <span
                style="
                  margin-left: 10px;
                  color: #666;
                  font-size: 12px;
                  margin-top: 5px;
                "
                >{{ $t("N_I_116") }}</span
              >
            </div>

            <div style="" class="width-auto">
              <recommendTime ref="recommendTime" />
            </div>
          </div> -->
        </div>

        <div
          class="width-s height-auto"
          style="width: auto; margin-top: 20px"
          v-if="navIndex == 2"
        >
          <!-- 直推业绩!-->
          <div class="title" style="width: auto; padding: 20px 20px 0px 20px">
            <div>{{ $t("ENU_MENU_516") }}</div>
            <div class="right-flex-s">
              <!--              <el-date-picker v-model="thetime"-->
              <!--                              :picker-options="pickerBeginOption"-->
              <!--                              :disabled-date="disabledDate"-->
              <!--                              type="daterange"-->
              <!--                              value-format="yyyy-MM-dd"-->
              <!--                              range-separator="至"-->
              <!--                              start-placeholder="开始日期"-->
              <!--                              end-placeholder="结束日期">-->
              <!--              </el-date-picker>-->
              <el-date-picker
                class="thepicker"
                v-model="themonth"
                type="month"
                value-format="yyyy-MM"
                :placeholder="$t('CK_KS_38')"
              >
              </el-date-picker>
              <!-- <el-date-picker
                v-model="thetime"
                type="daterange"
                value-format="yyyy-MM-dd"
                :range-separator="$t('w_0139')"
                :start-placeholder="$t('CK_KS_4')"
                :end-placeholder="$t('CK_KS_5')"
                :picker-options="pickerOptions"
              >
              </el-date-picker> -->
            </div>
          </div>
          <div>
            <div style="display: flex">
              <template>
                <recommend-performance
                  :time="thetime"
                  ref="recommendPerformance"
                />
              </template>
              <template>
                <grade-performance :time="thetime" ref="gradePerformance" />
              </template>
            </div>
          </div>
        </div>
        <div
          class="width-s height-auto1"
          style="width: auto; margin-top: 20px"
          v-if="navIndex == 3"
        >
          <!-- 推荐列表!-->
          <div
            class="title"
            style="
              padding-top: 20px;
              display: block;
              border-bottom: none;
              background: #fff;
              border-radius: 10px 10px 0 0;
            "
          >
            {{ $t("ENU_MENU_517") }}
          </div>
          <div>
            <div class="tablebox">
              <el-table
                style="width: 100%"
                :data="tableData"
                :header-cell-style="{ background: '#EEEEEE' }"
                :row-class-name="tableRowClassName"
              >
                <el-table-column
                  align="center"
                  prop="memberCode"
                  :label="$t('N_I_124')"
                  width="240"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="memberName"
                  width="300"
                  :label="$t('CK_KS_14')"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="pkRegisterGradeStr"
                  width="300"
                  :label="$t('PER_DA_5')"
                ></el-table-column>
                <el-table-column
                  width="300"
                  align="center"
                  prop="creationTime"
                  :label="$t('w_0141')"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="consumeAchieve"
                  sortable="custom"
                  :label="$t('N_I_103')"
                  :formatter="stateFormat"
                  width="300"
                ></el-table-column>
                <el-table-column label="" width="auto"></el-table-column>
              </el-table>
              <pagination
                :total="total"
                :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize"
                @pagination="research"
              />
            </div>
          </div>
        </div>

        <!--        <div style="height: 40px"></div>-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarUserInfo from "@/components/sidebarUserInfo.vue";
import recommendTime from "@/components/recommendTime.vue";
import placement from "@/components/placement.vue";
import realPerformance from "@/components/realPerformance.vue";
import placementPerformance from "@/components/placementPerformance.vue";
import realPerformances from "@/components/realPerformances.vue";
import mounthPvCompare from "@/components/mounthPvCompare.vue";
import fistAddPv from "@/components/fistAddPv.vue";
import repAddPv from "@/components/repAddPv.vue";
import topBanner from "@/components/topBanner.vue";
import pagination from "@/components/pagination.vue";
import navBar from "@/components/navBar.vue";
import recommendedCommunity from "@/components/marketDynamics/recommendedCommunity.vue";
import firstPv from "@/components/marketDynamics/firstPv.vue";
import secondPv from "@/components/marketDynamics/secondPv.vue";
import activeMembers from "@/components/marketDynamics/activeMembers.vue";
import recommendPerformance from "@/components/recommendPerformance.vue";
import gradePerformance from "@/components/gradePerformance.vue";
import consanguinityPv from "@/components/marketDynamics/consanguinityPv.vue";
import monthPvDetail from "@/components/marketDynamics/monthPvDetail.vue";
import {
  getDayType,
  getPlaceNewAddAchieve,
  getYjList,
} from "@/api/activity.js";
import { getWalletBalance, parentList } from "@/api/wallet";
import * as api from "@/api/goods";
import noticePopup from "@/components/noticePopup.vue";
import { mapGetters } from "vuex";
export default {
  name: "marketDynamics",
  components: {
    noticePopup,
    realPerformance,
    placementPerformance,
    realPerformances,
    sidebarUserInfo,
    placement,
    recommendTime,
    mounthPvCompare,
    fistAddPv,
    repAddPv,
    topBanner,
    pagination,
    navBar,
    recommendedCommunity,
    activeMembers,
    consanguinityPv,
    recommendPerformance,
    gradePerformance,
    firstPv,
    secondPv,
    monthPvDetail,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  props: {
    ifDetail: {
      type: Number,
      default: 0,
    },
    monthScoreType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      thetime: [],
      selectData: "",
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectData = minDate.getTime();
          if (maxDate) {
            // 选择了最大日期后，清除已选日期，解除限制。
            this.selectData = "";
          }
        },
        disabledDate: (time) => {
          // 是否禁用日期选择
          if (this.selectData) {
            let date = new Date(this.selectData);
            // true：不可以选择该日期；false：可以选择该日期。
            // 大于或者小于本月的日期被禁用
            return (
              date.getMonth() > new Date(time).getMonth() ||
              date.getMonth() < new Date(time).getMonth()
            );
          } else {
            return false;
          }
        },
      },
      pickerBeginOption: {
        disabledDate: (time) => {
          return time.getTime() > Date.now() - 1 * 86400000;
        },
      },
      index: 50,
      navIndex: 0,
      disabledDate: "",
      navList: [
        {
          name: this.$t("ENU_MENU_510"),
          menuKey: "",
          value: "ConsanguinityCheck",
        },
        // { name: this.$t("N_I_83"), menuKey: "", value: "placementChart" },
        // { name: this.$t("N_I_84"), menuKey: "", value: "monthlyChart" },
        { name: this.$t("MN_F_49"), menuKey: "", value: "monthlyPerformance" },
        // { name: this.$t("N_I_86"), menuKey: "", value: "recommendTime" },
        { name: this.$t("N_I_87"), menuKey: "", value: "recommendPerformance" },
        { name: this.$t("ENU_MENU_517"), menuKey: "", value: "recommendList" },
      ],
      marketList: [
        {
          text: this.$t("MN_F_49"),
          id: 0,
          path: "/marketDynamics",
        },
        {
          text: this.$t("ENU_MENU_520"),
          id: 7,
          path: "/activeZone",
        },
        {
          text: this.$t("ENU_MENU_530"),
          id: 1,
          path: "/incomeDetail",
        },

        {
          text: this.$t("ENU_MENU_540"),
          id: 3,
          path: "/bonusSource",
        },
        // {
        //   text: "云代奖金",
        //   id: 2,
        //   path: "/generation",
        // },
        {
          text: this.$t("ENU_MENU_560"),
          id: 4,
          path: "/appraisal",
        },
        {
          text: this.$t("ENU_MENU_570"),
          id: 5,
          path: "/registration",
        },
        {
          text: this.$t("ENU_MENU_580"),
          id: 6,
          path: "/investment",
        },
      ],
      specialArea: 0,

      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 50,
      },
      whatMounth: "",
      whatMounth1: "",
      dateType: 1,
      selMounthList: [],
      dateTypeList: [],
      mounthList: [
        {
          value: 1,
          label: 1 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 2,
          label: 2 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 3,
          label: 3 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 4,
          label: 4 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 5,
          label: 5 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 6,
          label: 6 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 7,
          label: 7 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 8,
          label: 8 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 9,
          label: 9 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 10,
          label: 10 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 11,
          label: 11 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 12,
          label: 12 + this.$t("ENU_SETTLE_P_3"),
        },
      ],
      fgkhDate: "",
      fgkhYear: "",
      yjList: [],
      yjType: 2,
      achieveData: {},
      themonth: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getFullYear() < new Date().getFullYear() - 1 || time.getFullYear() > new Date().getFullYear();
        }
      }
    };
  },
  watch: {
    $route(n, o) {
      this.specialArea = n.query.id;
    },
    whatMounth(n) {
      this.$refs.mounthPvCompare.upDateCover(n);
    },
    whatMounth1(n) {
      this.$refs.realPerformances.upDateCover(n);
    },
    dateType(n) {
      this.$refs.activeMembers.upDateCover2(n);
    },
    fgkhYear(n) {
      this.fgkhYear = n;
      this.getFgDataList();
    },
    fgkhDate(n) {
      this.fgkhDate = n;
      this.getFgDataList();
      // this.$refs.consanguinityPv.getFgDataList(n);
    },
    yjType(n) {
      this.yjType = n;
      this.getFgDataList();
      // this.$refs.consanguinityPv.getFgDataList(n);
    },
    thetime(value) {
      this.$nextTick(() => {
        this.$refs.recommendPerformance.upDateCover2(value);
      });
      this.$refs.gradePerformance.upDateCover2(value);
    },
    themonth(value) {
      this.$nextTick(() => {
        this.$refs.recommendPerformance.upDateCover2(value);
      });
      this.$refs.gradePerformance.upDateCover2(value);
    },
  },
  created() {},
  mounted() {
    this.specialArea = this.$route.query.id;
    this.getMounth();
    this.getMounth1();
    this.getMenuLists();

    this.getYjList();
    // this.getFgDataList()
    this.getNowMonth();
    // this.getDays()
  },
  updated() {
    // this.scrollToBottom()
  },
  methods: {
    getYjList() {
      getYjList().then((res) => {
        this.yjList = res.data;
      });
    },
    getFgDataList() {
      getPlaceNewAddAchieve({
        monthType: this.fgkhDate,
        achievementType: this.yjType,
        year: this.fgkhYear,
      }).then((res) => {
        this.achieveData = res.data;
      });
    },
    getDays() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      // let dates =this.getMonthDay(2022,7,0)
      var day = date.getDate();
      var nowDate;
      if (day == 1) {
        if (month < 10) {
          month = "0" + month;
        }
        if (day < 10) {
          day = "0" + day;
        }
        nowDate = year + "-" + month + "-" + day;
        this.thetime[0] = nowDate;
        this.thetime[1] = nowDate;
      } else {
        if (month < 10) {
          month = "0" + month;
        }
        if (day < 10) {
          day = "0" + day;
        }
        nowDate = year + "-" + month + "-" + day;
        this.thetime[0] = nowDate;
        this.thetime[1] = nowDate;
      }
      console.error(nowDate);
    },
    //导出明细
    exportDetail() {
      this.download(
        "/member/api/achieve/export",
        {},
        `业绩分布${new Date().getTime()}.xlsx`
      );
    },
    getNowMonth() {
      let date = new Date();
      let year = date.getFullYear(); // 得到当前年份
      let month = date.getDate() === 1 ? date.getMonth() : date.getMonth() + 1; // 得到当前月份（0-11月份，+1是当前月份）
      date.setDate(1); // 将当前时间的日期设置成第一天
      month = month >= 10 ? month : "0" + month; // 补零
      this.themonth = year + "-" + month;
      // return year + "-" + month
    },
    getStartTime() {
      let date = new Date();
      // console.error(date.getDate()==1?date.getMonth():date.getMonth() + 1)
      let year = date.getFullYear(); // 得到当前年份
      let month = date.getDate() === 1 ? date.getMonth() : date.getMonth() + 1; // 得到当前月份（0-11月份，+1是当前月份）
      date.setDate(1); // 将当前时间的日期设置成第一天
      month = month >= 10 ? month : "0" + month; // 补零
      let day = date.getDate(); // 得到当前天数，实际是本月第一天，因为前面setDate(1) 设置过了
      day = day >= 10 ? day : "0" + day; // 补零
      return year + "-" + month + "-" + day;
    },
    getEndTime() {
      var date = new Date();
      var year = date.getFullYear(); // 年
      let month = date.getDate() === 1 ? date.getMonth() : date.getMonth() + 1; // 得到当前月份（0-11月份，+1是当前月份）
      var day = date.getDate() > 1 ? date.getDate() - 1 : date.getDate(); // 日
      let day1 = date.getDate();
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds(); //秒
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      if (day1 == 1) {
        var date2 = new Date();
        var year2 = date2.getFullYear();
        var month2 = date2.getMonth();
        let dates = this.getMonthDay(year2, month2, 0);
        day = dates;
      }
      return year + "-" + month + "-" + day + " ";
    },
    getMonthDay(year, month) {
      let days = new Date(year, month + 1, 0).getDate();
      return days;
    },
    navTap(index) {
      console.log("dianji", index);
      this.navIndex = index;
      if (index == 1) {
        this.getDayType();
        this.$refs.fistAddPv.upDateCover();
        // this.$refs.repAddPv.upDateCover();
        // this.$refs.placementPerformance.getData();
        // this.$refs.recommendedCommunity.getData();
        this.$refs.firstPv.getData();
        // this.$refs.secondPv.getData();
        // this.$refs.realPerformance.getData()
        this.$refs.mounthPvCompare.upDateCover();
        this.$refs.activeMembers.upDateCover2();
        this.$refs.realPerformances.upDateCover();
        this.$refs.recommendTime.getInit();
      }
      if (index == 3) {
        this.research();
        // this.thetime[0] = '2023-06-01'
        // this.thetime[1] = '2023-06-29'
        this.thetime[0] = this.getStartTime();
        this.thetime[1] = this.getEndTime();
      }
      if (index == 0) {
        this.$router.push({
          path: "/marketDynamics",
          query: { id: 0, ifDetail: index },
        });
        this.$emit("updateValue", 0);
      }
    },
    getDayType() {
      getDayType().then((res) => {
        this.dateTypeList = res.data;
      });
    },
    getMenuLists() {
      let list = JSON.parse(localStorage.getItem("menuList"));
      list.forEach((item) => {
        this.navList.forEach((items) => {
          if (items.value == item.menuKey) {
            items.menuKey = item.menuKey1;
          }
        });
      });
    },
    // 获取当前月
    getMounth() {
      this.whatMounth = new Date().getMonth() + 1;
      let selMounth = [];
      this.mounthList.forEach((item) => {
        if (item.value <= this.whatMounth) {
          selMounth.push(item);
        }
      });
      this.selMounthList = selMounth;
    },
    // 获取当前月
    getMounth1() {
      this.whatMounth1 = new Date().getMonth() + 1;
      this.fgkhDate = new Date().getMonth() + 1;
      this.fgkhYear = new Date().getFullYear().toString();
      let selMounth = [];
      this.mounthList.forEach((item) => {
        // if (item.value <= this.whatMounth) {
        //   selMounth.push(item);
        // }
        selMounth.push(item);
      });
      this.selMounthList = selMounth;
    },
    changeArea(item) {
      this.specialArea = item.id;
      //   this.research()
      this.$router.push({
        path: item.path,
        query: { id: item.id },
      });
    },
    scrollToBottom: function () {
      this.$nextTick(() => {
        //Vue 在更新 DOM 时是异步执行的，为了在数据变化之后等待 Vue 完成更新 DOM，可以在数据变化之后立即使用 Vue.nextTick(callback)。这样回调函数将在 DOM 更新完成后被调用。
        var container = this.$el.querySelector(".tablebox"); //这里的class是我盛放数据流设置了overflow:auto 也就是让其拥有滚动条的盒子，自己根据情况去选定元素
        container.scrollTop = 1000;
      });
    },
    research() {
      let that = this;
      parentList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.height-auto {
  height: 1000px !important;
}
.height-auto1 {
  height: 1183px !important;
}
.yjfb_flex {
  display: flex;
  align-items: center;
  // justify-content: space-between;
}
.yjfb_select {
  width: 124px;
  // height: 54px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 8px 8px;
}
.width-auto {
  width: 1400px;
  background: #fff;
  // box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
  border-radius: 0px 0px 8px 8px;
}
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
  }
}
.content-s {
  // height: 1452px;
  overflow-x: hidden;
}
.styles {
  border: none !important;
  color: #fff !important;
  background: #d61820;
}
.top-lists {
  display: flex;
  padding: 20px 0 0 0;
  .nav-view {
    cursor: pointer;
    padding: 0 26px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid #999999;
    font-size: 14px;
    text-align: center;
    line-height: 44px;
    margin-right: 20px;
    color: #999;
  }
}
.tablebox {
  //height: 820px;
  //overflow-y: auto;
}
.heights {
  height: 800px !important;
}
.width-s {
  width: 100%;
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
  height: 502px;
  border-radius: 10px 10px 10px 10px;
}
.title {
  height: 48px;
  font-size: 18px;
  color: #333;
  padding: 0 20px;
  border-bottom: 5px solid rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  position: relative;
}
.right-flex-s {
  position: absolute;
  right: 20px;
  top: 15px;
}
.width {
  //width: 55% !important;
}
.content-div4 {
  width: 45% !important;
  height: 686px !important;
}
.content-div5 {
  width: 53.5% !important;
  height: 686px !important;
}
.flex-s {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  .content-div2 {
    width: 49.3%;
    //width: 677px;
    height: 700px;
    background: #ffffff;
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
    padding: 30px 0px;
  }
  .content-div3 {
    width: 49%;
    //width: 741px;
    height: 500px;
    background: #ffffff;
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
    padding: 30px 0;
  }
}
.content-div1 {
  height: 406px;
  background: #ffffff;
  box-shadow: 0px 3px 20px 0px rgba(135, 135, 135, 0.3);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  padding: 20px;
  margin-top: 20px;
  //width: 100%;
}
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
.contain {
  background: rgba(249, 249, 249, 1);
  // height: 100vh;
  padding: 0 !important;
  display: flex;
  .goodArea {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 100%;
    height: 80px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    .goodArea_i {
      margin: 0 35px;
      text-align: center;
      cursor: pointer;
    }
    .actArea {
      color: #b42b2a;
    }
  }
  .topbox {
    // height: 109px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 40px 0;
    display: flex;
    align-items: center;
    padding: 30px 16px 10px 16px;
    .searchbox {
      display: flex;
      align-items: center;
      .btn1 {
        background: #cccccc;
        border-radius: 5px 5px 5px 5px;
        color: #ffffff;
      }
      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
      }
    }
  }
  .tablebox {
    //box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
    // padding-bottom: 30px;
    width: 100%;
  }
}
::v-deep .pagination-container {
  border-radius: 10px;
}
.kuangk {
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
  border-radius: 8px 8px 8px 8px;
}

::v-deep .el-month-table td.current:not(.disabled) .cell {
  color: #000000 !important;
  font-weight: 500 !important;
}
</style>
