<template>
  <div>
    <el-dialog class="zhuanpan1" :visible.sync="newVip">
      <div class="wrap_close_center">
        <img
          style="width: 40px; height: 40px; cursor: pointer"
          :src="closeImg1"
          @click="clickClose"
        />
      </div>
      <div class="gameBox">
        <!-- <div class="bg1"></div> -->

        <!-- <div class="bg2" v-show="lampShow"></div> -->

        <div class="start" @click="move">
          <div class="lovesmart">
            <img src="../../assets/images/lovesmart.png" alt="" />
          </div>
          <div class='starttitle'>
            <div class="thestarttext">{{ $t('w_0193') }}</div>
            <p>{{ $t('w_0194') }}：{{ number_of_draws }}</p>
          </div>
        </div>

        <ul>
          <li
            v-for="(item, i) in giftList"
            :key="i"
            :class="['item' + (i + 1), { active: index == i }]"
          >
            <div class="img1">
              <img :src="item.giftCover" alt="" />
            </div>

            <p style="color:#fff">{{ item.giftName }}</p>
          </li>
        </ul>
      </div></el-dialog
    >
    <!-- 抽奖获得产品 -->
    <el-dialog
      class="chanpin"
      :visible.sync="prizeShow"
      style="z-index: 9999"
      @touchmove.prevent
    >
      <div class="wrap">
        <div class="turntable">
          <div class="bg-closes">
            <div>
              <img
                class="close-icons"
                style="width: 25px; height: 25px"
                :src="closeImg"
                @click="prizeClose"
              />
            </div>
            <div class="prize">
              <div class="top_text" style="margin-top: 90px">{{ $t('w_0195') }}</div>
              <div class="top_text overflow1">
                {{ productData.giftName || "" }}
              </div>
                <div class="banner-bottom">
                    <vue-seamless-scroll
                            :class-option="optionSingleHeightTime"
                            :data="productData.acGiftConfigList"
                            v-if="productData.acGiftConfigList.length>2"
                            class="seamless-warp"
                    >
                        <div class="item-s" v-for="(item, index) in productData.acGiftConfigList" :key="index">
                            <div class="product">{{ $t('ENU_N_B_T_3') }}：{{item.productName}}     x{{item.quantity}}</div>
                            <div class="price">{{ $t('w_0196') }}：{{ item.retailPrice | numFilter }}{{ $t('w_0197') }}</div>
                        </div>
                    </vue-seamless-scroll>
                    <div v-else>
                        <div class="item-s" v-for="(item, index) in productData.acGiftConfigList" :key="index">
                            <div class="product">{{ $t('ENU_N_B_T_3') }}：{{item.productName}}     x{{item.quantity}}</div>
                            <div class="price">{{ $t('w_0196') }}：{{ item.retailPrice | numFilter }}{{ $t('w_0197') }}</div>
                        </div>
                    </div>
                </div>

                <img
                fit="contain"
                class="margin-tops"
                style="height: 240px"
                :src="productData.giftCover"
              />
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 充值抽奖次数 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="rechargeShow"
      width="40%"
      class="pay_password"
    >
      <div class="pay_item" @click.stop>
        <div class="pay_pt1">{{ $t('w_0199') }}</div>
        <div style="font-size: 24px; margin: 20px 0">
          <div>{{ $t('w_0198') }}：{{ totalSurplusNum }}</div>
          <div>{{ $t('w_0194') }}：0</div>
        </div>
        <el-input
          type="number"
          :placeholder="$t('w_0200')"
          v-model="keyValue"
          clearable
        >
        </el-input>
        <div class="czbtnbox">
          <el-button class="btn" @click.stop @click="closeBtn">{{ $t('MY_WAL_4') }}</el-button>
        </div>
      </div>
    </el-dialog>
    <Pay :isPay="isPay" @closePay="closePay" :paramsPost="paramsPost"></Pay>
  </div>
</template>
  
  <script>
import * as act from "@/api/bonus.js";
import Pay from "@/components/Pay.vue";
export default {
  components: {
    Pay,
  },
  computed: {
    optionSingleHeightTime() {
      return {
        step: 0.6, // 数值越大速度滚动越快
        limitMoveNum: this.productData.acGiftConfigList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 5, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  data() {
    return {
      isPay: false,
      giftList: [
        // { id: 1, number: 15, prize_name: "欢乐豆1" },
        // { id: 2, number: 15, prize_name: "欢乐豆2" },
        // { id: 3, number: 15, prize_name: "欢乐豆3" },
        // { id: 4, number: 15, prize_name: "欢乐豆4" },
        // { id: 5, number: 15, prize_name: "欢乐豆5" },
        // { id: 6, number: 15, prize_name: "欢乐豆6" },
        // { id: 7, number: 15, prize_name: "欢乐豆7" },
        // { id: 8, number: 15, prize_name: "欢乐豆8" },
      ], //奖品列表

      index: 0, // 当前转动到哪个位置，第一次起点位置0,对应页面item1位置

      count: 8, // 总共有多少个位置

      times: 0, // 转动跑格子次数,

      cycle: 30, // 转动基本次数：即至少需要转动多少次再进入抽奖环节

      speed: 100, // 初始转动速度

      // lampShow: true, //开始抽奖，灯光闪烁

      timer: 0, // 转动定时器

      lamp: 0, // 灯光定时器

      prize: 0, // 中奖位置，接口返回

      number_of_draws: 0, //限制每天抽奖次数，接口返回
      productData: {
        acGiftConfigList: [{ productName: "" }],
      }, //中奖信息

      prize_data: {
        //中奖信息

        id: Number, //奖品ID

        name: "", //奖品名称

        number: Number, //奖品数量

        image: "", //奖品图片

        type: Number, // 奖品类型
      },
      newVip: false,
      pkId: "",
      prizeShow: false, //奖品
      closeImg: require("../../assets/images/close1.png"),
      closeImg1: require("../../assets/images/close.png"),
      rechargeShow: false,
      keyValue: "", //充值抽奖次数
      totalSurplusNum: "", //可购买次数
      paramsPost: {},
    };
  },
    filters: {
        numFilter (value) {
            // 截取当前数据到小数点后两位
            let realVal = parseFloat(value).toFixed(2)
            return realVal
        }
    },
  methods: {
    closePay(val) {
      if (val) {
        this.isPay = false;
      } else {
        this.$confirm(this.$t('w_0090')+"？")
          .then((_) => {
            this.isPay = false;
            // this.$router.replace({
            //   path: "/order/myOrder",
            // });
          })
          .catch((_) => {});
      }
    },
    // 充值抽奖次数
    closeBtn() {
      if (
        this.keyValue &&
        Number(this.keyValue) > 0 &&
        Number(this.keyValue) <= this.totalSurplusNum
      ) {
        this.rechargeShow = false;
        act
          .drawOnlinePayDraw({
            payNum: Number(this.keyValue),
            pkBaseId: this.pkId,
          })
          .then((res) => {
            this.paramsPost = {
              orderType: "4",
              type: "0",
              orderCode: res.data.actCode,
              speciaArea:'4',
              address: "0", //订单ID 不需要随便传个占位
              luckydrawData: {
                pkBaseId: this.pkId,
                payNum: Number(this.keyValue),
              },
            };
            this.isPay = true;
          })
          .catch((error) => {
            this.rechargeShow = true;
          });
      } else {
        this.$message({
          type: "warning",
          message: this.$t('w_0201'),
        });
      }
    },
    clickClose() {
      this.newVip = false;
    },
    //关闭获得商品弹窗
    prizeClose() {
      this.prizeShow = false;
    },
    //查询转盘抽奖次数
    getDrawNum(val) {
      act.getLotteryDrawNum({ pkBaseId: val }).then((res) => {
        if (res.code == 200) {
          this.number_of_draws = res.data.availableSurplusNum;
          this.totalSurplusNum = res.data.totalSurplusNum;
        }
      });
    },
    getGiftList(val) {
      this.pkId = val;
      act
        .getDrawPrizeList({
          pkBaseId: val,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.rows.length > 0) {
              this.newVip = true;
              this.giftList = res.rows;
              this.getDrawNum(val);
            } else {
              this.newVip = false;
            }
          }
        });
    },
    move() {
      if (this.number_of_draws == 0) {
        this.newVip = false;
        this.rechargeShow = true;
        // this.$message({
        //   message: "今日抽奖次数已用完,明天再来吧",
        //   type: "warning",
        // });
      } else if (this.times != 0) {
        this.$message({
          message: this.$t('w_0202'),
          type: "warning",
        });
      } else {
        act.getLotteryDraw({ pkBaseId: this.pkId }).then((res) => {
          if (res.code == 200) {
            console.log("🌈?", res);
            this.giftList.forEach((ele, index) => {
              if (ele.pkId == res.data.pkId) {
                this.prize = index; //中奖位置赋值，跑马灯最终停留位置，这里实际位置需要-1
              }
            });
            //中奖奖品信息
            this.productData = res.data;

            this.getDrawNum(this.pkId);
            this.speed = 120; //每次抽奖速度初始化为200
            this.startRoll(); //执行抽奖
          }
        });

        // this.number_of_draws--; //抽奖开始，次数-1
        // this.speed = 160; //每次抽奖速度初始化为200
        // this.prize_data = 111; //已经拿到中奖信息，页面展示，等抽奖结束后，将弹窗弹出
        // this.prize = 7 - 1;
        // this.startRoll(); //执行抽奖
        // this.lamp = setInterval(() => {
        //   //灯光闪烁开启
        //   this.lampShow = !this.lampShow
        // }, 500)
      }
    },

    // 开始转动

    startRoll() {
      this.times += 1; // 转动次数

      this.oneRoll(); // 转动过程调用的每一次转动方法，这里是第一次调用初始化

      this.usePrize();
    },

    // 每一次转动

    oneRoll() {
      let index = this.index; // 当前转动到哪个位置

      const count = 8; // 总共有多少个位置

      index += 1;

      if (index > count - 1) {
        index = 0;
      }

      this.index = index;
    },

    usePrize() {
      // 如果当前转动次数达到要求 && 目前转到的位置是中奖位置

      if (this.times > this.cycle && this.prize === this.index) {
        clearTimeout(this.timer); // 清除转动定时器

        clearTimeout(this.lamp); // 清除灯光定时器

        // this.lampShow = false; // 白色灯隐藏
        this.times = 0; // 转动跑格子次数初始化为0，可以开始下次抽奖

        //本转盘中奖率100%,不存在未中奖的情况
        // if (this.prize_data.type == 0) {
        //   console.log("未中奖，谢谢参与"); //未中奖提示弹出，
        // } else {
        //   console.log("中奖啦"); //中奖弹出提示
        // }
        this.prizeShow = true;
      } else {
        if (this.times < this.cycle - 10) {
          // this.speed -= 3; // 加快转动速度
        } else {
          this.speed += 10; // 抽奖即将结束，放慢转动速度
        }

        this.timer = setTimeout(this.startRoll, this.speed); //开始转动
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
.margin-tops {
  margin-top: 100px;
}
.seamless-warp {
  //margin-top: 30px;
  padding-top: 20px !important;
  padding: 10px 20px;
  height: 150px;
}
.product {
  color: #fff;
  font-size: 16px;
  margin-left: 15px;
}
.price {
  margin-right: 15px;
  color: #c8161d;
  font-size: 16px;
}
.item-s{
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  width: 470px;
}
.banner-bottom{
  width: 470px;
  height: 75px;
  position: absolute;
  left: 90px;
  bottom: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 10px;
}
.overflow1 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 10px !important;
  width: 450px;
}
.close-icons {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}
.bg-closes {
  width: 650px;
  height: 850px;
  background: url("~@/assets/images/bg-cologs.jpg") no-repeat;
  margin-top: 120px;
  position: relative;
}
::v-deep .chanpin .el-dialog__header {
  display: none;
}
::v-deep .chanpin .el-dialog__body {
  padding: 0;
}
::v-deep .zhuanpan1 .el-dialog {
  margin: 0 !important;
  padding-top: 40px;
}
.pay_password {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;

  // background: none;

  .van-number-keyboard {
    position: relative;
  }

  .pay_item {
    background: #fff;
    // width: 100%;
    height: auto;
    border-radius: 10px 10px 0 0;
    padding: 42px;
    text-align: center;
    font-size: 28px;
    color: #202020;
    .czbtnbox {
      display: flex;
      justify-content: center;
      margin: 30px auto 0 auto;
    }
  }
}
.center-s {
  z-index: 100010;
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  right: 0;
}
.wrap_close_center {
  z-index: 100010;
  display: flex;
  justify-content: flex-end;
  width: 68%;
  padding-top: 70px;
  // height: 120px;
}
.wrap {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  .turntable {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 100%;
    // background-color: rgba(0, 0, 0, 0.5);
  }

  .wrap_close {
    z-index: 100010;
    display: flex;
    justify-content: flex-end;
    width: 400px;
    height: 30px;
    padding: 0 20px;
  }

  .wrap_close_center {
    z-index: 100010;
    display: flex;
    justify-content: center;
    width: 325px;
    height: 120px;
  }

  .turntable_top {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    // width: 325px;

    .top_img {
      position: absolute;
      top: -20px;
      bottom: 0;
    }

    .top_text {
      font-size: 42.5px;
      letter-spacing: 0px;
      line-height: 45px;
      background-image: -webkit-linear-gradient(top, #0be9fe, #45b5fe);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
    }
  }

  .footer_text {
    font-size: 24px;
    background-image: -webkit-linear-gradient(top, #0be9fe, #45b5fe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 65px;
    text-align: center;
  }

  .prize {
    display: flex;
    flex-direction: column;
    align-items: center;
    //width: 750px;
    height: 650px;
    background-size: 100% auto;
    background-repeat: no-repeat;

    .top_text {
      //height: 100px;
      font-size: 40px;
      background: #c8161d;
      font-weight: bold;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-top: 80px;
      text-align: center;
      padding: 0 50px;
    }
  }

  .turntableNum {
    width: 420px;
    height: 600px;
    background-image: linear-gradient(#c1edff, #02f2fe);
    margin-top: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .item {
      width: 385px;
      height: 529px;
      background-color: #ffffff;
      padding-top: 70px;

      .top {
        display: flex;
        flex-direction: column;
        font-size: 36px;
        color: #3ea5e1;
        text-align: center;
        line-height: 30px;
        .bottomcishu {
          margin-top: 40px;
        }
      }

      .btn_box {
        display: flex;
        justify-content: center;
        margin: 250px auto 0 auto;
      }
    }
  }
}
// ::v-deep .el-dialog {
//   margin: 0 !important;
// }
// ::v-deep .el-dialog__headerbtn {
//   width: 100%;
// }
// ::v-deep .el-dialog__headerbtn .el-dialog__close {
//   border: 1px solid #8f9298;
//   border-radius: 50%;
//   font-size: 30px;
// }
::v-deep .zhuanpan1 .el-dialog__header {
  display: none;
}
::v-deep .zhuanpan1 .el-dialog__body {
  padding: 0;
}
::v-deep .zhuanpan1 {
  width: 100% !important;
}
.zhuanpan1 ::v-deep .el-dialog,
.el-pager li {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7) !important;
  margin-top: 0 !important;
}
.gameBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 700px;
  height: 670px;
  margin: auto;
  // margin: 150px auto 0;
  border-radius: 8px;
  background: url("~@/assets/images/giftDrwalbg.gif") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .bg1 {
    position: absolute;
    left: 0px;
    top: 4px;
    width: 100%;
    height: 317px;
    // background: url(../../common/images/home/game3.png) no-repeat center;
    // background-size: 317px 317px;
    // border: 1px solid blue;
    background: rgb(168, 164, 164);
  }

  .bg2 {
    position: absolute;
    left: 0;
    top: 4px;
    width: 100%;
    height: 317px;
    // background: url(../../common/images/home/game2.png) no-repeat center;
    // background-size: 317px 317px;
    background: rgb(231, 230, 227);
  }

  .start {
    position: relative;
    // padding-top:20px;
    width: 140px;
    height: 140px;
    top: 0px;
    left: 18px;
    cursor: pointer;
    // background: linear-gradient(0deg, #ff6a00, #fed8bc);
    display: flex;
    // align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #ffffff;
    border-radius: 35px;
    .lovesmart {
      display: flex;
      justify-content: flex-end;
      img {
        width: 30px;
        height: 30px;
      }
    }
    .starttitle{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .thestarttext {
      // margin-top: 10px;
      color: #fced99;
      font-size: 38px;
      font-weight: 600;
    }

    p {
      margin-bottom: 5px;
      font-size: 12px;
      font-weight: 400;
      color: #fced99;

      //   color: rgba(255, 255, 255, 1);
    }
  }

  ul {
    list-style: none;
    li {
      position: absolute;

      width: 160px;

      height: 153px;
      background-color: rgba(0, 0, 0, 0.04);
      // background: linear-gradient(0deg, #fffdd6, #fffefb);

      border: 4px solid #ff6a00;

      border-radius: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .img1 {
        // margin: 15px auto 3px;

        img {
          width: 80px;
          height: 80px;
        }
      }

      p {
        text-align: center;

        font-size: 13px;

        font-weight: 500;

        color: rgba(153, 153, 153, 1);
      }
    }

    .item1 {
      left: 82px;

      top: 84px;
    }

    .item2 {
      left: 262px;
      top: 84px;
    }

    .item3 {
      left: 442px;

      top: 84px;
    }

    .item8 {
      left: 82px;

      top: 260px;
    }

    .item4 {
      left: 442px;

      top: 260px;
    }

    .item7 {
      left: 82px;

      top: 434px;
    }

    .item6 {
      left: 262px;

      top: 434px;
    }

    .item5 {
      left: 442px;

      top: 434px;
    }

    // .item9 {
    //   left: 0px;

    //   top: 215px;
    // }

    // .item10 {
    //   left: 0px;

    //   top: 120px;
    // }

    .active {
      background:  linear-gradient(-20deg, rgb(249, 84, 19), rgba(253, 252, 252, 0.6));
      // background: #ff9409;
    }
  }
}
</style>