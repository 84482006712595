<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2022-08-08 10:42:29
-->
<template>
  <!-- <van-overlay :show="newVip" @click="newVip = false"> -->
  <el-dialog class="zhuanpan1"
             :visible.sync="newVip">
    <div class="kuang">
      <i class="el-icon-close cha"
         @click="close"></i>

      <!-- :arrow="cGiftList.length>1?'always':'never'" -->
      <el-carousel @change="carouselHand"
                   :autoplay='false'
                   indicator-position="outside"
                   :height='bannerHeight + "px"'
                   class="switerAll"
                   arrow='never'
                   ref='swiper'
                   style="width:548px;margin:0 auto">
        <div class="left_arr"
             @click="arrowClick('prev')">
          <img src="@/assets/images/arrowl.png"
               alt="">
        </div>
        <div class="right_arr"
             @click="arrowClick('next')">
          <img src="@/assets/images/arrowr.png"
               alt="">
        </div>
        <el-carousel-item v-for="(item,index) in cGiftList"
                          :key="index">
          <div class="kuang_t">{{item.plan}}</div>
          <!-- <h3>{{ item }}</h3> -->
          <div class="n_kuang">
            <div class="n_kuang_t">
              <div v-if="cGiftList.length>1"
                   class="tit">{{item.actName}}</div>
              <div v-for="(itm,indx) in item.pgpList"
                   :key="indx"
                   class="bao">
                <img class="img_i"
                     :src="itm.cover"
                     alt="">
                <div class="bao_r">
                  <div class="bao_rl">
                    <div>{{itm.productName}}</div>
                    <div class="num">X{{itm.quantity}}</div>
                  </div>
                  <div class="price">￥{{itm.price}}</div>
                </div>
              </div>
            </div>

          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="btns"
           @click="changeGift">{{ $t('w_0211') }}</div>
    </div>
  </el-dialog>
  <!-- </van-overlay> -->
</template>

<script>
import * as act from '@/api/bonus.js'
export default {
  name: 'Title',
  props: {
    // isAgree: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      newVip: false,
      toChange: false,
      cGiftList: [],
      isChange: '',
      bannerIndex: 0,
      bannerHeight: 0,
    }
  },
  mounted() {
    // this.getGiftList()
    this.screenWidth = window.innerWidth
    this.bannerHeight = (330 / 1500) * this.screenWidth
  },
  methods: {
    arrowClick(val) {
      if (val == 'next') {
        this.$refs.swiper.next()
      } else {
        this.$refs.swiper.prev()
      }
    },
    carouselHand(index) {
      this.bannerIndex = index
    },
    toIsAgree() {
      this.$emit('toIsAgree')
    },
    getGiftList(val) {
      act
        .getGiftPack({
          pkBaseId: val,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data != undefined) {
              this.newVip = true
              this.cGiftList = res.data
            } else {
              this.newVip = false
            }
          }
        })
    },
    close() {
      this.newVip = false
    },
    changeGift() {
      let rows = this.cGiftList[this.bannerIndex]
      this.$router.push({
        path: '/newGiftPay',
        query: {
          data: JSON.stringify(rows)
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>

.img1 {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 10px;
  right: 30px;
}
.img2 {
  width: 284px;
  height: 70px;
  margin: 150px 0 30px 0;
}
.img3 {
  width: 452px;
  height: 447px;
  margin-bottom: 60px;
}
.btn {
  color: #df0701;
  background: linear-gradient(to bottom, #f9ca99, #ffba39);
  font-size: 24px;
  padding: 12px 30px;
  border-radius: 50px;
  font-weight: Bold;
  margin-bottom: 30px;
}
.btm {
  color: #fac57f;
  font-size: 24px;
  margin-bottom: 30px;
}
::v-deep .zhuanpan1 .el-dialog__header {
  display: none;
}
::v-deep .zhuanpan1 .el-dialog__body {
  padding: 0;
}
.zhuanpan1 ::v-deep .el-dialog,
.el-pager li {
  background: rgba(0, 0, 0, 0.7) !important;
  margin-top: 0 !important;
}
.kuang {
  width: 80%;
  margin: 0 auto;
  // background: #fff;
  background: url('~@/assets/images/nGift.png') no-repeat;
  background-size: 100% 100%;
  height: 601px;
  // text-align: center;
  margin-top: 150px;
  // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  position: relative;

  .cha {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 25px;
    color: #fff;
  }
}
.kuang_t {
  text-align: center;
  font-weight: 600;
  color: #fff;
  font-size: 34px;
  margin: 20px 0;
  // padding-top: 40px;
}
.n_kuang {
  border-radius: 8px;
  border: 2px dashed #ccc;
  height: 330px;
  width: 388px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .n_kuang_t {
    flex: 1;
    overflow: auto;
    overflow-x: hidden;
    // ::-webkit-scrollbar {
    //   width: 0 !important;
    // }
    // ::-webkit-scrollbar {
    //   width: 0 !important;
    //   height: 0;
    // }
  }
  .tit {
    font-size: 24px;
    // margin: 12px 0;
    margin-top: 12px;
    font-weight: 600;
    color: #fff;
  }
}
.btns {
  background: #ffb95c;
  border-radius: 10px;
  height: 60px;
  width: 388px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 60px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}
.bao {
  margin-top: 12px;
  display: flex;
  background: rgba(207, 213, 250, 0.4);
  border-radius: 8px;
  margin-bottom: 20px;
  align-items: center;
  padding: 12px;
  font-size: 12px;
  font-weight: 600;
  color: #333333;
  .bao_r {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    .bao_rl {
      display: flex;
      flex-direction: column;
      .num {
        font-size: 12px;
        margin-top: 10px;
        font-weight: 400;
        color: #333333;
      }
    }
    .price {
      font-size: 16px;

      font-weight: 600;
      color: #f64e49;
    }
  }
}
.img_i {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}
* {
  ::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }
}
.zhuanpan1 {
  ::v-deep .el-dialog {
    background: transparent !important;
    box-shadow: none;
  }
}
.switerAll {
  .left_arr {
    position: absolute;
    top: 55%;
    left: 20px;
    z-index: 10;
    img {
      width: 44px;
      height: 44px;
    }
  }
  .right_arr {
    position: absolute;
    top: 55%;
    right: 20px;
    z-index: 10;
    img {
      width: 44px;
      height: 44px;
    }
  }
}
</style>
<style>
.kuang .el-icon-arrow-left {
  font-size: 18px;
  font-weight: 600;
}
.kuang .el-icon-arrow-right {
  font-size: 18px;
  font-weight: 600;
}
.kuang .el-carousel__arrow {
  top: 60%;
}
.kuang .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  opacity: none;
}
.kuang .el-carousel__indicator.is-active button {
  width: 50px;
  border-radius: 10px;
}
.kuang .el-carousel__indicators--outside .el-carousel__indicator:hover button {
  opacity: 1;
}
</style>