<template>
  <div>
    <div class="flex-s">
      <div
        id="main2"
        style="width: 100%; margin-top: -20px"
        :style="myChartStyle"
      ></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { azAchievement, getPerformance } from "@/api/index";
import { formatSeconds } from "@/util/common";
export default {
  name: "recommendTime",
  data() {
    return {
      texts: "",
      pieData: [
      
        {
          value: 0,
          age: 0,
          name: this.$t('S_C_18'),
          itemStyle: { color: "#EE5858" },
        },
        {
          value: 0,
          age: 0,
          name: this.$t('S_C_19'),
          itemStyle: { color: "#FBB046" },
        },
      ],
      myChartStyle: {
        float: "center",
        width: "600px",
        height: "450px",
        paddingTop: "0",
        // margin: "20px",
      }, //图表样式
    };
  },
  mounted() {
    // this.getData();
  },
  methods: {
    getData() {
      azAchievement().then((res) => {
        this.pieData[1].value = res.data.rightAchieve;
        this.pieData[0].value = res.data.leftAchieve;
        this.pieData[1].age = res.data.rightPeopleNumber;
        this.pieData[0].age = res.data.leftPeopleNumber;
        setTimeout(() => {
          this.ageChart(res.data);
        }, 50);
      });
    },
    ageChart(datas) {
      var myChart = echarts.init(document.getElementById("main2"));
      let that = this
      var option = {
        legend: {
          // 图例
          // right: "0%",
          left: "42%",
          bottom: "0",
          color: "#999",
          // orient: "vertical",
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              // formatter:'{b}:   {c}人\n 业绩:  {d}万',
              formatter: function (data) {
                return (
                  data.data.name +
                  ":" +
                  data.data.age +
                  "人" +
                  "\n " +
                  "业绩：" +
                  data.data.value +
                  that.$t('S_C_58') 
                );
              },
              lineHeight: 25, // 行高
              color: "{f}",
              labelLine: {
                show: false,
              },
            },
            radius: "70%", //饼图半径
            data: that.pieData,
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-select .el-input .el-select__caret {
  color: #333;
}
.lines {
  width: 2px;
  height: 470px;
  opacity: 1;
  background: #979797;
  position: absolute;
  bottom: 50px;
  left: 50%;
  margin-left: -110px;
}
.right-lines {
  position: absolute;
  right: 80px;
  top: 140px;
  color: #999;
  font-size: 12px;
}
.item-s {
  padding-bottom: 10px;
  display: flex;
  line-height: 24px;
}
.bg-color {
  width: 40px;
  height: 22px;
  border-radius: 2px;
  margin-left: 5px;
}
.flex-s {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 700px;
}
.styles {
  position: absolute;
  right: -30px;
  z-index: 111;
  top: 60px;
  /*background: rgba(0,0,0,0.05);*/
  /*border-radius: 4px 4px 4px 4px;*/
}
::v-deep .el-select .el-input__inner {
  border: none;
  background: rgba(0, 0, 0, 0.05);
}
</style>