<template>
  <div class="contain">
    <div>
      <!-- 公告弹窗 -->
      <notice-popup
        :userInfo="userInfo"
        :publishLocationIndex="index"
      ></notice-popup>
    </div>
    <sidebarUserInfo></sidebarUserInfo>
    <div class="rightbox" style="width: 100%">
      <topBanner></topBanner>
      <div class="menuList">
        <div
          class="menu_i"
          @click="changeArea(item.id, index)"
          :class="specialArea == item.id ? 'actMenu' : ''"
          v-for="(item, index) in marketList"
          v-show="item.isShow"
          :key="index"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="xian"></div>
      <!--      <navBar :myId="this.specialArea"></navBar>-->
      <template>
        <!-- 业绩分布!-->
        <marketDynamicss
          @updateValue="updateParentValue"
          :ifDetail="ifDetail"
          :monthScoreType='monthScoreType'
          v-if="this.specialArea == 0"
        />
      </template>
      <template>
        <!-- 活动专区!-->
        <activeZone v-if="this.specialArea == 7" />
      </template>
      <template>
        <!-- 奖金明细!-->
        <incomeDetail v-if="this.specialArea == 1" />
      </template>
      <template>
        <!-- 奖金来源!-->
        <bonusSource v-if="this.specialArea == 3" />
      </template>
      <template>
        <!-- 考核明细!-->
        <appraisal v-if="this.specialArea == 4" />
      </template>
      <template>
        <!-- 注册列表!-->
        <registration v-if="this.specialArea == 5" />
      </template>
      <template>
        <!-- 招商列表!-->
        <investment v-if="this.specialArea == 6" />
      </template>
    </div>
  </div>
</template>

<script>
import sidebarUserInfo from "@/components/sidebarUserInfo.vue";
import recommendTime from "@/components/recommendTime.vue";
import placement from "@/components/placement.vue";
import realPerformance from "@/components/realPerformance.vue";
import placementPerformance from "@/components/placementPerformance.vue";
import realPerformances from "@/components/realPerformances.vue";
import mounthPvCompare from "@/components/mounthPvCompare.vue";
import fistAddPv from "@/components/fistAddPv.vue";
import repAddPv from "@/components/repAddPv.vue";
import topBanner from "@/components/topBanner.vue";
import pagination from "@/components/pagination.vue";
import navBar from "@/components/navBar.vue";
import recommendedCommunity from "@/components/marketDynamics/recommendedCommunity.vue";
import activeMembers from "@/components/marketDynamics/activeMembers.vue";
import recommendPerformance from "@/components/recommendPerformance.vue";
import gradePerformance from "@/components/gradePerformance.vue";
import consanguinityPv from "@/components/marketDynamics/consanguinityPv.vue";
import marketDynamicss from "@/components/marketDynamics/marketDynamicss.vue";
import activeZone from "@/components/marketDynamics/activeZone.vue";
import incomeDetail from "@/components/marketDynamics/incomeDetail.vue";
import bonusSource from "@/components/marketDynamics/bonusSource.vue";
import appraisal from "@/components/marketDynamics/appraisal.vue";
import registration from "@/components/marketDynamics/registration.vue";
import investment from "@/components/marketDynamics/investment.vue";
import { getDayType } from "@/api/activity.js";
import { getWalletBalance, parentList } from "@/api/wallet";
import * as api from "@/api/goods";
import noticePopup from "@/components/noticePopup.vue";
import { mapGetters } from "vuex";
import Registration from "@/views/marketDynamics/appraisal.vue";
export default {
  name: "marketDynamics",
  components: {
    investment,
    registration,
    Registration,
    appraisal,
    bonusSource,
    incomeDetail,
    activeZone,
    marketDynamicss,
    noticePopup,
    realPerformance,
    placementPerformance,
    realPerformances,
    sidebarUserInfo,
    placement,
    recommendTime,
    mounthPvCompare,
    fistAddPv,
    repAddPv,
    topBanner,
    pagination,
    navBar,
    recommendedCommunity,
    activeMembers,
    consanguinityPv,
    recommendPerformance,
    gradePerformance,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      index: 50,
      navIndex: 0,
      navList: [
        { name: this.$t("MN_F_49"), menuKey: "", value: "ConsanguinityCheck" },
        { name: this.$t("N_I_83"), menuKey: "", value: "placementChart" },
        { name: this.$t("N_I_84"), menuKey: "", value: "monthlyChart" },
        { name: this.$t("N_I_85"), menuKey: "", value: "monthlyPerformance" },
        { name: this.$t("N_I_86"), menuKey: "", value: "recommendTime" },
        { name: this.$t("N_I_87"), menuKey: "", value: "recommendPerformance" },
        { name: this.$t("ENU_MENU_517"), menuKey: "", value: "recommendList" },
      ],
      marketList: [
        {
          text: this.$t("MN_F_49"),
          id: 0,
          path: "/marketDynamics",
          name: "marketDynamics",
          isShow: false,
        },
        {
          text: this.$t("ENU_MENU_520"),
          id: 7,
          path: "/marketDynamics",
          name: "activeZone",
          isShow: false,
        },
        {
          text: this.$t("ENU_MENU_530"),
          id: 1,
          path: "/marketDynamics",
          name: "incomeDetail",
          isShow: false,
        },
        {
          text: this.$t("ENU_MENU_540"),
          id: 3,
          path: "/marketDynamics",
          name: "bonusSource",
          isShow: false,
        },
        // {
        //   text: "云代奖金",
        //   id: 3,
        //   path: "/generation",
        // },
        {
          text: this.$t("ENU_MENU_560"),
          id: 4,
          path: "/marketDynamics",
          name: "appraisal",
          isShow: false,
        },
        {
          text: this.$t("ENU_MENU_570"),
          id: 5,
          path: "/marketDynamics",
          name: "registration",
          isShow: false,
        },
        {
          text: this.$t("ENU_MENU_580"),
          id: 6,
          path: "/marketDynamics",
          name: "investment",
          isShow: false,
        },
      ],
      specialArea: 0,
      thetime: [],
      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 8,
      },
      whatMounth: "",
      whatMounth1: "",
      dateType: 1,
      selMounthList: [],
      dateTypeList: [],
      mounthList: [
        {
          value: 1,
          label: 1 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 2,
          label: 2 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 3,
          label: 3 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 4,
          label: 4 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 5,
          label: 5 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 6,
          label: 6 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 7,
          label: 7 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 8,
          label: 8 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 9,
          label: 9 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 10,
          label: 10 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 11,
          label: 11 + this.$t("ENU_SETTLE_P_3"),
        },
        {
          value: 12,
          label: 12 + this.$t("ENU_SETTLE_P_3"),
        },
      ],
      fgkhDate: "",
      ifDetail: "0",
      monthScoreType:""
    };
  },
  watch: {
    $route(n, o) {
      this.specialArea = n.query.id;
      this.ifDetail = n.query.ifDetail || "0";
      this.monthScoreType = n.query.monthScoreType||""
    },
    whatMounth(n) {
      this.$refs.mounthPvCompare.upDateCover(n);
    },
    whatMounth1(n) {
      this.$refs.realPerformances.upDateCover(n);
    },
    dateType(n) {
      this.$refs.activeMembers.upDateCover2(n);
    },
    fgkhDate(n) {
      this.$refs.consanguinityPv.getFgDataList(n);
    },
    thetime(value) {
      this.$refs.recommendPerformance.upDateCover2(value);
      this.$refs.gradePerformance.upDateCover2(value);
    },
  },
  created() {},
  mounted() {
    this.specialArea = this.$route.query.id;
    this.getMenuLists();

    // this.research();
    // this.getMounth();
    // this.getMounth1();
    // this.getDayType();
    // var date = new Date();
    // var year = date.getFullYear();
    // var month = date.getMonth() + 1;
    // var day = date.getDate();
    // if (month < 10) {
    //     month = "0" + month;
  },
  methods: {
    updateParentValue(newValue) {
      this.ifDetail = newValue;
      this.monthScoreType = ""
    },
    getStartTime() {
      let date = new Date();
      date.setDate(1); // 将当前时间的日期设置成第一天
      let year = date.getFullYear(); // 得到当前年份
      let month = date.getMonth() + 1; // 得到当前月份（0-11月份，+1是当前月份）
      month = month >= 10 ? month : "0" + month; // 补零
      let day = date.getDate(); // 得到当前天数，实际是本月第一天，因为前面setDate(1) 设置过了
      day = day >= 10 ? day : "0" + day; // 补零
      return year + "-" + month + "-" + day;
    },
    getEndTime() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      // 这里传入的是整数时间，返回的是下个月的第一天，因为月份是0-11
      let nextMonthFirthDay = new Date(year, month, 1); // 下个月的第一天
      console.log(nextMonthFirthDay);
      let oneDay = 1000 * 60 * 60 * 24; // 一天的时间毫秒数
      let endDay = new Date(nextMonthFirthDay - oneDay);
      let day = endDay.getDate(); // 本月最后一天
      month = month >= 10 ? month : "0" + month; // 补零
      day = day >= 10 ? day : "0" + day; // 补零
      console.log(day);
      // 这里传入的是字符串格式的时间，返回的是传入字符串的时间
      return year + "-" + month + "-" + day;
    },
    navTap(index) {
      this.navIndex = index;
      if (index == 5) {
        this.thetime[0] = this.getStartTime();
        this.thetime[1] = this.getEndTime();
      }
    },
    getDayType() {
      getDayType().then((res) => {
        this.dateTypeList = res.data;
      });
    },
    getMenuLists() {
      api.menuList().then((res) => {
        res.data.forEach((item) => {
          this.navList.forEach((items) => {
            if (items.value == item.menuKey) {
              items.menuKey = item.menuKey1;
            }
          });
          this.marketList.forEach((ctem) => {
            if (ctem.name == item.menuKey) {
              ctem.isShow = true;
            }
          });
        });
      });
      
    },
    // 获取当前月
    getMounth() {
      this.whatMounth = new Date().getMonth() + 1;
      let selMounth = [];
      this.mounthList.forEach((item) => {
        if (item.value <= this.whatMounth) {
          selMounth.push(item);
        }
      });
      this.selMounthList = selMounth;
    },
    // 获取当前月
    getMounth1() {
      this.whatMounth1 = new Date().getMonth() + 1;
      let selMounth = [];
      this.mounthList.forEach((item) => {
        if (item.value <= this.whatMounth) {
          selMounth.push(item);
        }
      });
      this.selMounthList = selMounth;
      console.error(selMounth);
      // this.$refs.realPerformances.upDateCover(6)
    },
    changeArea(id, index) {
      this.navIndex = index;
      this.specialArea = id;
      //   this.research()
      this.$router.push({
        path: "/marketDynamics",
        query: { id: id },
      });
    },
    research() {
      parentList(this.queryParams).then((res) => {
        this.tableData = res.rows;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.xian {
  height: 5px;
  background: rgba(0, 0, 0, 0.05);
  margin-top: -5px;
}
.menuList {
  display: flex;
  margin-top: 20px;
  padding: 0;
  box-sizing: border-box;
  //width: 100px;
  text-align: center;
  .menu_i {
    //width: 100px;
    text-align: center;
    margin-right: 70px;
    line-height: 50px;
    cursor: pointer;
  }
  .actMenu {
    border-bottom: 5px solid #b42b2a;
  }
}
.height-auto {
  height: 1000px !important;
}
.yjfb_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.yjfb_select {
  width: 124px;
  // height: 54px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 8px 8px;
}
.width-auto {
  width: 1400px;
}
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
  }
}
.content-s {
  //height: 1500px;
}
.styles {
  border: none !important;
  color: #fff !important;
  background: #d61820;
}
.top-lists {
  display: flex;
  padding: 0 0 0 0;
  .nav-view {
    cursor: pointer;
    padding: 0 26px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid #999999;
    font-size: 14px;
    text-align: center;
    line-height: 44px;
    margin-right: 20px;
    color: #999;
  }
}
.tablebox {
  //height: 820px;
  //overflow-y: auto;
}
.heights {
  height: 800px !important;
}
.width-s {
  width: 100%;
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
  height: 502px;
  border-radius: 10px 10px 10px 10px;
}
.title {
  height: 48px;
  font-size: 18px;
  color: #333;
  padding: 0 20px;
  border-bottom: 5px solid rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  position: relative;
}
.right-flex-s {
  position: absolute;
  right: 20px;
  top: 15px;
}
.width {
  //width: 55% !important;
}
.content-div4 {
  width: 45% !important;
  height: 686px !important;
}
.content-div5 {
  width: 53.5% !important;
  height: 686px !important;
}
.flex-s {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  .content-div2 {
    width: 49.3%;
    //width: 677px;
    height: 700px;
    background: #ffffff;
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
    padding: 30px 0px;
  }
  .content-div3 {
    width: 49%;
    //width: 741px;
    height: 500px;
    background: #ffffff;
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
    padding: 30px 0;
  }
}
.content-div1 {
  height: 406px;
  background: #ffffff;
  box-shadow: 0px 3px 20px 0px rgba(135, 135, 135, 0.3);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  padding: 20px;
  margin-top: 20px;
  //width: 100%;
}
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
.contain {
  background: rgba(249, 249, 249, 1);
  //height: 100vh;
  padding: 47px 62px 30px 0;
  display: flex;
  .goodArea {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 100%;
    height: 80px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    .goodArea_i {
      margin: 0 35px;
      text-align: center;
      cursor: pointer;
    }
    .actArea {
      color: #b42b2a;
    }
  }
  .topbox {
    // height: 109px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 40px 0;
    display: flex;
    align-items: center;
    padding: 30px 16px 10px 16px;
    .searchbox {
      display: flex;
      align-items: center;
      .btn1 {
        background: #cccccc;
        border-radius: 5px 5px 5px 5px;
        color: #ffffff;
      }
      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
      }
    }
  }
  .tablebox {
    //box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
    padding-bottom: 30px;
    width: 100%;
  }
}
::v-deep .pagination-container {
  border-radius: 10px;
}
</style>