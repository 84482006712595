<template>
  <div class="width-auto" style="position: relative">
    <!-- <div class="right-lines">
      <div class="item-s" v-for="item in list">
        <img class="bg-color" :src="item.value" />
        <div class="color-style">{{ item.name }}</div>
      </div>
    </div> -->
    <div
      class="data-list"
      @mouseenter="mouseOvers(1)"
      @mouseleave="mouseOut(1)"
    >
      <div class="user-data">
        <div class="mouseOvers" @mouseover="mouseOvers(1)" v-if="check1">
          <div class="name-s" style="display: flex; align-items: center">
            <div>{{ $t("N_I_124") }}：{{ userInfo.memberCode }}</div>
            <el-button size="mini" @click="copyCode(userInfo.memberCode)">{{
              $t("MY_CK_27")
            }}</el-button>
          </div>
          <div class="name-s">
            {{ $t("CK_KS_14") }}：{{ userInfo.memberName }}
          </div>
          <div class="name-s">
            {{ $t("PER_DA_9") }}：{{ userInfo.payTime.substring(0, 10) }}
          </div>
          <div class="name-s" style="margin-bottom: 10px">
            {{ $t("w_0306") }}：{{ userInfo.consumeAchieve | numFilter }}
          </div>
        </div>
        <div class="border-color">
          <img src="@/assets/images/avatar.png" alt="">
        </div>
        <div class="code" style="margin-top: -10px">
          {{ this.myChart.memberCode }}
        </div>
        <div class="code" v-if="this.myChart.memberName">
          ({{ this.myChart.memberName }})
        </div>
      </div>
    </div>
    <div
      class="data-list1"
      @mouseenter="mouseOvers(2)"
      @mouseleave="mouseOut(2)"
      v-if="this.myChart.leftMember"
    >
      <div class="user-data">
        <div class="mouseOvers" @mouseover="mouseOvers(2)" v-if="check2">
          <div class="name-s" style="display: flex; align-items: center">
            <div>{{ $t("N_I_124") }}：{{ userInfo1.memberCode }}</div>
            <el-button size="mini" @click="copyCode(userInfo1.memberCode)">{{
              $t("MY_CK_27")
            }}</el-button>
          </div>
          <div class="name-s">
            {{ $t("CK_KS_14") }}：{{ userInfo1.memberName }}
          </div>
          <div class="name-s">
            {{ $t("PER_DA_9") }}:{{ userInfo1.payTime.substring(0, 10) }}
          </div>
          <div class="name-s" style="margin-bottom: 10px">
            {{ $t("w_0306") }}:{{ userInfo1.consumeAchieve | numFilter }}
          </div>
        </div>
        <div class="border-color">
          <img src="@/assets/images/avatar.png" alt="">
          <!-- <img :src="this.myChart.leftMember.lastImageUrl || ''" /> -->
        </div>
        <div class="code" style="margin-top: -10px">
          {{ this.myChart.leftMember.lastMemberCode }}
        </div>
        <div class="code">{{ $t("N_I_101") }}</div>
        <div class="code" style="font-size: 16px">{{ $t("S_C_18") }}</div>
      </div>
    </div>
    <div
      class="data-list2"
      @mouseenter="mouseOvers(3)"
      @mouseleave="mouseOut(3)"
      v-if="this.myChart.rightMember"
    >
      <div class="user-data">
        <div class="mouseOvers" @mouseover="mouseOvers(3)" v-if="check3">
            <div class="name-s" style="display: flex; align-items: center">
            <div>{{ $t("N_I_124") }}：{{ userInfo2.memberCode }}</div>
            <el-button size="mini" @click="copyCode(userInfo2.memberCode)">{{
              $t("MY_CK_27")
            }}</el-button>
          </div>
          <div class="name-s">
            {{ $t("CK_KS_14") }}：{{ userInfo2.memberName }}
          </div>
          <div class="name-s">
            {{ $t("PER_DA_9") }}:{{ userInfo2.payTime.substring(0, 10) }}
          </div>
          <div class="name-s" style="margin-bottom: 10px">
            {{ $t("w_0306") }}:{{ userInfo2.consumeAchieve | numFilter }}
          </div>
        </div>
        <div class="border-color" v-if="this.myChart.rightMember">
          <img src="@/assets/images/avatar.png" alt="">
          <!-- <img :src="this.myChart.rightMember.lastImageUrl" /> -->
        </div>
        <div class="code" style="margin-top: -10px">
          {{ this.myChart.rightMember.lastMemberCode }}
        </div>
        <div class="code">{{ $t("N_I_101") }}</div>
        <div class="code" style="margin-left: 24px; font-size: 16px">
          {{ $t("S_C_19") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  getMemberRecommend,
  getPlaceMent,
  getTreeDetail,
  getGradeIcon,
} from "@/api/index";
import { formatSeconds } from "@/util/common";
export default {
  name: "recommendTime",
  data() {
    return {
      myChart: {},
      check1: false,
      index1: 0,
      check2: false,
      index2: 0,
      check3: false,
      index3: 0,
      userInfo: {},
      userInfo1: {},
      userInfo2: {},
      list: [
        {
          name: this.$t("ENU_AGENT_G_3"),
          color: "linear-gradient(180deg, #EA728D 0%, #F3CF4F 100%)",
        },
        {
          name: this.$t("w_0307"),
          color: "linear-gradient(180deg, #14E3A3 0%, #EBDF3C 84%)",
        },
        { name: "SVIP", color: "#EC6C00" },
        { name: "VIP", color: "#009B58" },
        { name: "创客", color: "#48B2FD" },
        { name: "优客", color: "#ED1D25" },
        { name: "嗨粉", color: "#B6007A" },
        { name: "海粉", color: "#0015CE" },
      ],
    };
  },
  mounted() {
    this.getInit();
    this.getGradeIcons();
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  methods: {
    //复制
    copyCode(content) {
      if (window.clipboardData) {
        window.clipboardData.setData("text", content);
      } else {
        (function (content) {
          //oncopy 事件在用户拷贝元素上的内容时触发。
          document.oncopy = function (e) {
            e.clipboardData.setData("text", content);
            e.preventDefault();
            document.oncopy = null;
          };
        })(content);
        document.execCommand("Copy");
      }
      this.$message({
        message: this.$t("MY_CK_29"),
        type: "success",
      });
    },
    mouseOvers(data) {
      if (data == 1) {
        this.check1 = true;
        if (this.index1 == 0) {
          getTreeDetail({ memberCode: this.myChart.memberCode }).then((res) => {
            this.userInfo = res.data;
          });
        }
        this.index1++;
      }
      if (data == 2) {
        this.check2 = true;
        if (this.index2 == 0) {
          getTreeDetail({
            memberCode: this.myChart.leftMember.lastMemberCode,
          }).then((res) => {
            this.userInfo1 = res.data;
          });
        }
        this.index2++;
      }
      if (data == 3) {
        this.check3 = true;
        if (this.index3 == 0) {
          getTreeDetail({
            memberCode: this.myChart.rightMember.lastMemberCode,
          }).then((res) => {
            this.userInfo2 = res.data;
          });
        }
        this.index3++;
      }
    },
    mouseOut(data) {
      if (data == 1) {
        this.check1 = false;
      }
      if (data == 2) {
        this.check2 = false;
      }
      if (data == 3) {
        this.check3 = false;
      }
    },
    getInit() {
      getPlaceMent().then((res) => {
        this.myChart = res.data;
      });
    },
    getGradeIcons() {
      getGradeIcon().then((res) => {
        this.list = res.data;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.color-style {
  color: #333;
  font-size: 14px;
}
.name-s {
  color: #333;
  font-size: 12px;
  margin-bottom: 18px;
}
.mouseOvers {
  padding: 10px 5px;
  width: 153px;
  height: 144px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #ed1d25;
  position: absolute;
  margin-left: 100px;
  z-index: 111;
}
.user-data {
  position: relative;
}
.code {
  color: #333;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 15px;
  text-align: center;
}
.data-list {
  margin: 50px 0 0 -70px;
  position: absolute;
  left: 50%;
}
.data-list2 {
  margin: 250px 0 0 85px;
  position: absolute;
  left: 50%;
}
.data-list1 {
  margin:250px 0 0 -220px;
  position: absolute;
  left: 50%;
}
.border-color {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  //background: #FFFFFF;
  //box-shadow: 0px 2px 20px 0px rgba(204,204,204,0.5);
  border-radius: 50%;
  img {
    // position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.width-auto {
  width: 686px !important;
  position: relative;
  // height: 1000px;
}
.right-lines {
  position: absolute;
  left: 30px;
  width: 100%;
  top: 10px;
  color: #999;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
}
.item-s {
  padding-bottom: 10px;
  display: flex;
  line-height: 30px;
  margin-right: 20px;
  align-items: center;
}
.bg-color {
  width: 56px;
  height: 56px;
  border-radius: 4px;
  margin-right: 10px;
}
</style>