<template>
  <div class="contain">
    <div class="rightbox" style="width: 100%">
      <div class="top-lists">
        <div
          class="nav-view"
          @click="clickHref(item.path)"
          :class="navIndex == index ? 'styles' : ''"
          v-for="(item, index) in navList"
          v-show="item.menuKey != ''"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <template>
        <appraisal v-if="navIndex == 0" />
        <dividend v-if="navIndex == 1" />
      </template>
    </div>
  </div>
</template>
    
<script>
import sidebarUserInfo from "@/components/sidebarUserInfo.vue";
import topBanner from "@/components/topBanner.vue";
import pagination from "@/components/pagination.vue";
import topFBar from "@/components/topFBar.vue";
import appraisal from "@/components/appraisal.vue";
import dividend from "@/components/dividend.vue";
import {
  getAssessList,
  getAssessStatusList,
  getAssAchieve,
} from "@/api/bonus.js";
import navBar from "@/components/navBar.vue";
import * as api from "@/api/goods";
export default {
  name: "Registration",
  components: {
    navBar,
    dividend,
    appraisal,
    sidebarUserInfo,
    topBanner,
    pagination,
    topFBar,
  },
  data() {
    return {
      moren: "dividend",
      navIndex: 0,
      navList: [
        {
          name: this.$t('ENU_MENU_561'),
          path: 0,
          menuKey: "",
        },
        // {
        //   name: this.$t('ENU_MENU_562'),
        //   path: 1,
        //   menuKey: "",
        // },
      ],
      marketList: [
        {
          text: this.$t('ENU_MENU_510'),
          id: 0,
          path: "/marketDynamics",
        },
        {
          text: this.$t('ENU_MENU_520'),
          id: 7,
          path: "/activeZone",
        },
        {
          text: this.$t('ENU_MENU_530'),
          id: 1,
          path: "/incomeDetail",
        },
        {
          text: this.$t('ENU_MENU_540'),
          id: 3,
          path: "/bonusSource",
        },
        // {
        //   text: "云代奖金",
        //   id: 2,
        //   path: "/generation",
        // },

        {
          text: this.$t('ENU_MENU_560'),
          id: 4,
          path: "/appraisal",
        },
        {
          text: this.$t('ENU_MENU_570'),
          id: 5,
          path: "/registration",
        },
        {
          text: this.$t('ENU_MENU_580'),
          id: 6,
          path: "/investment",
        },
      ],
      specialArea: 0,
      thetime: "",
      tableData: [],
      dataList: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 50,
        startDate: "",
        endDate: "",
        assessType: 0,
        assessStatus: "",
      },
      assessStatusList: [], //收益类型
      achieve: "",
      flag_s: false,
    };
  },
  watch: {
    $route(n, o) {
      this.specialArea = n.query.id;
    },
  },
  created() {},
  mounted() {
    // this.specialArea = this.$route.query.id;
    // this.research();
    // this.getAssessStatusList();
    // this.getAssAchieve();
    this.getMenuLists();
    console.log('🌈',this.navIndex)
  },
  methods: {
    getMenuLists() {
      let list = JSON.parse(localStorage.getItem("menuList"));
      list.forEach((item) => {
        if (item.menuKey == "repurchaseCheck") {
          this.navList[0].menuKey = "repurchaseCheck";
          // this.navIndex = 0;
        }
        if (item.menuKey == "dividendCheck") {
          this.navList[1].menuKey = "dividendCheck";
          // this.navIndex = 1;
        }
      });
    
    },
    clickHref(index) {
      this.navIndex = index;
    },
    orderHref() {
      this.$router.push({
        path: "/vipCenter",
        query: { id: 1 },
      });
    },
    getAssAchieve() {
      getAssAchieve({ assessType: 0,startDate:this.queryParams.startDate,  endDate:this.queryParams.endDate }).then((res) => {
        this.achieve = res.data;
      });
    },
    chongzhi() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 50,
        startDate: "",
        endDate: "",
        assessType: 0,
        assessStatus: "",
      };
      this.research();
    },
    changeArea(item) {
      this.specialArea = item.id;
      this.$router.push({
        path: item.path,
        query: { id: item.id },
      });
    },
    research() {
      if (this.thetime) {
        this.queryParams.startDate = this.thetime[0];
        this.queryParams.endDate = this.thetime[1];
      }
      getAssessList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    getAssessStatusList() {
      getAssessStatusList().then((res) => {
        this.assessStatusList = res.data;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.styles {
  border: none !important;
  color: #fff !important;
  background: #d61820;
}
.top-lists {
  display: flex;
  padding: 20px 0 0 0;
  .nav-view {
    //width: 60px;
    cursor: pointer;
    padding: 0 26px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid #999999;
    font-size: 14px;
    text-align: center;
    line-height: 44px;
    margin-right: 20px;
    color: #999;
  }
}
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
  }
}

::v-deep .el-range-editor.el-input__inner {
  width: 100%;
  height: 32px;
  padding: 0 10px;
}
::v-deep .select .el-input--suffix .el-input__inner {
  height: 32px;
}
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}

.waibox {
  position: relative;
  .passkh {
    width: 88px;
    height: 88px;
  }
  .poimg {
    position: absolute;
    top: 30px;
    bottom: 30px;
    left: 5px;
    right: 15px;
    color: #ffffff;
  }
}

.contain {
  background: rgba(249, 249, 249, 1);
  height: 100vh;
  padding: 0 !important;
  display: flex;
  .goodArea {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 100%;
    height: 80px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    .goodArea_i {
      margin: 0 35px;
      text-align: center;
      cursor: pointer;
    }
    .actArea {
      color: #b42b2a;
    }
  }
  .topbox {
    // height: 109px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 20px 0;
    padding: 20px 16px 0 0;
    .searchbox {
      display: flex;
      align-items: center;
      margin-left: 40px;
      margin-top: 2px;
      .btn1 {
        border: 1px solid #cccccc;
        border-radius: 5px 5px 5px 5px;
        color: #666;
        height: 34px;
        width: 68px;
        padding: 0;
      }
      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
        height: 34px;
        width: 68px;
        padding: 0;
      }
    }
  }
  .tablebox {
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
    .tabletop {
      text-align: right;
      padding: 20px;
      .tabletoptitle1 {
        font-size: 18px;
      }
      .tabletoptitle2 {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}
</style>