<template>
  <div class="contain">
    <div class="rightbox" style="width: 100%">
      <div class="topbox">
        <el-form ref="form" :model="queryParams" label-width="80px">
          <el-row>
            <el-col :span="5">
              <el-form-item :label="$t('N_I_124')" prop="memberCode">
                <el-input v-model="queryParams.memberCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item :label="$t('CK_KS_14')" prop="memberName">
                <el-input v-model="queryParams.memberName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item :label="$t('PER_DA_5')" prop="pkRegisterGrade">
                <el-select
                  v-model="queryParams.pkRegisterGrade"
                  :placeholder="$t('CK_KS_38')"
                >
                  <el-option
                    v-for="(item, index) in gradeList"
                    :key="index"
                    :label="item.gradeName"
                    :value="item.pkId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :label="$t('MY_ORD_3')" prop="teamCode">
                <el-date-picker
                  @change="changeTime"
                  v-model="thedata"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  :range-separator="$t('w_0139')"
                  :start-placeholder="$t('CK_KS_4')"
                  :end-placeholder="$t('CK_KS_5')"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <div class="searchbox">
                <el-button class="btn2" @click="research">{{
                  $t("N_I_183")
                }}</el-button>
                <el-button class="btn1" @click="chongzhi">{{
                  $t("CK_KS_6")
                }}</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tablebox" style="height: 1020px">
        <el-table
          height="1215px"
          :data="tableData"
          :header-cell-style="{ background: '#EEEEEE' }"
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            align="center"
            width="120"
            prop="memberCode"
            :label="$t('N_I_124')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="memberName"
            :label="$t('CK_KS_14')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="parentCode"
            :label="$t('N_I_164')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="parentName"
            :label="$t('N_I_165')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="placeParentCode"
            :label="$t('N_I_167')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="placeParentName"
            :label="$t('N_I_166')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="placeDeptVal"
            :label="$t('N_I_168')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="regMoney"
            :label="$t('N_I_169') + '(' + userInfo.currencyIcon + ')'"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.regMoney | toThousandthAndKeepDecimal }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="regPv"
            width="120"
            :label="$t('PER_DA_10') + '(PV)'"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.regPv | toThousandthAndKeepDecimal }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="registerGradeVal"
            :label="$t('PER_DA_5')"
            width="80"
          ></el-table-column>

          <el-table-column
            align="center"
            width="160"
            prop="creationTime"
            :label="$t('MN_T_6')"
          ></el-table-column>
          <el-table-column
            width="160"
            align="center"
            prop="payTime"
            :label="$t('MY_ORD_3')"
          ></el-table-column>
        </el-table>
        <pagination
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="research"
        />
      </div>
    </div>
  </div>
</template>

<script>
import sidebarUserInfo from "@/components/sidebarUserInfo.vue";
import topBanner from "@/components/topBanner.vue";
import pagination from "@/components/pagination.vue";
import { getRegList, getGradeList } from "@/api/bonus.js";
import navBar from "@/components/navBar.vue";
import { mapGetters } from "vuex";

export default {
  name: "Registration",
  components: {
    navBar,
    sidebarUserInfo,
    topBanner,
    pagination,
  },
  data() {
    return {
      marketList: [
        {
          text: this.$t("ENU_MENU_510"),
          id: 0,
          path: "/marketDynamics",
        },
        {
          text: this.$t("ENU_MENU_520"),
          id: 7,
          path: "/activeZone",
        },
        {
          text: this.$t("ENU_MENU_530"),
          id: 1,
          path: "/incomeDetail",
        },
        {
          text: this.$t("ENU_MENU_540"),
          id: 3,
          path: "/bonusSource",
        },
        // {
        //   text: "云代奖金",
        //   id: 2,
        //   path: "/generation",
        // },

        {
          text: this.$t("ENU_MENU_560"),
          id: 4,
          path: "/appraisal",
        },
        {
          text: this.$t("ENU_MENU_570"),
          id: 5,
          path: "/registration",
        },
        {
          text: this.$t("ENU_MENU_580"),
          id: 6,
          path: "/investment",
        },
      ],
      specialArea: 0,
      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 50,

        startCreationTime: "",
        endCreationTime: "",
      },
      gradeList: [], //收益类型
      thedata: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {
    $route(n, o) {
      this.specialArea = n.query.id;
    },
  },
  created() {},
  mounted() {
    this.specialArea = this.$route.query.id;
    this.research();
    this.getGradeList();
  },
  methods: {
    changeTime(val) {
      this.queryParams.startCreationTime = val[0];
      this.queryParams.endCreationTime = val[1];
    },
    getGradeList() {
      getGradeList().then((res) => {
        this.gradeList = res.data;
      });
    },
    chongzhi() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 50,
      };
      this.thedata = "";
      this.research();
    },
    changeArea(item) {
      this.specialArea = item.id;
      this.$router.push({
        path: item.path,
        query: { id: item.id },
      });
    },
    research() {
      getRegList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-table) {
  overflow: visible;
}
:deep(.el-table__header-wrapper) {
  position: sticky;
  top: 100px; //这个值根据实际情况而定
  z-index: 10;
}
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
.contain {
  background: rgba(249, 249, 249, 1);
  height: 100vh;
  padding: 0 !important;
  display: flex;
  .goodArea {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 100%;
    height: 80px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    .goodArea_i {
      margin: 0 35px;
      text-align: center;
      cursor: pointer;
    }
    .actArea {
      color: #b42b2a;
    }
  }
  .topbox {
    // height: 109px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 40px 0;
    padding: 30px 16px 10px 16px;
    .searchbox {
      display: flex;
      align-items: center;
      .btn1 {
        background: #cccccc;
        border-radius: 5px 5px 5px 5px;
        color: #ffffff;
      }
      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
      }
    }
  }
  .tablebox {
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
  }
}
</style>
