<template>
  <div>
    <div class="topbox">
      <el-form ref="form" :model="queryParams" label-width="80px">
        <el-row>
          <el-col :span="4">
            <el-form-item
              :label="$t('N_I_163')"
              prop="changeType"
              class="select"
            >
              <el-select
                v-model="queryParams.changeType"
                :placeholder="$t('CK_KS_38')"
              >
                <el-option
                  v-for="(item, index) in changeTypeList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="$t('CK_KS_12')"
              prop="assessStatus"
              class="select"
            >
              <el-select
                v-model="queryParams.assessStatus"
                :placeholder="$t('CK_KS_38')"
              >
                <el-option
                  v-for="(item, index) in assessStatusList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item :label="$t('N_I_160')" prop="teamCode">
              <el-date-picker
                v-model="thetime"
                type="daterange"
                value-format="yyyy-MM-dd"
                :range-separator="$t('w_0139')"
                :start-placeholder="$t('CK_KS_4')"
                :end-placeholder="$t('CK_KS_5')"
                @change="changeThetime"
                clearable
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <div class="searchbox">
              <el-button class="btn2" @click="research">{{
                $t("N_I_183")
              }}</el-button>
              <el-button class="btn1" @click="chongzhi">{{
                $t("CK_KS_6")
              }}</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="tablebox">
      <div class="tabletop">
        <span class="tabletoptitle1"> {{ $t("N_I_158") }}:</span>
        <span class="tabletoptitle2">{{ achieve }}</span>
      </div>
      <el-table
        height="1165px"
        :data="tableData"
        :header-cell-style="{ background: '#EEEEEE' }"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          align="center"
          prop="memberCode"
          width="180px"
          label=""
        >
          <template slot-scope="scope">
            <div v-if="scope.row.assessStatus == 0">
              <div class="waibox">
                <img class="passkh" src="@/assets/images/khpass.png" alt="" />
                <div class="poimg">{{ scope.row.settleMonth }}</div>
              </div>
              <div style="color: rgba(69, 194, 66, 1)">
                {{ $t("ENU_ASSESS_S_0") }}
              </div>
            </div>
            <div v-if="scope.row.assessStatus == 1">
              <div class="waibox">
                <img class="passkh" src="@/assets/images/nokh.png" alt="" />
                <div class="poimg">{{ scope.row.settleMonth }}</div>
              </div>
              <div style="color: rgba(255, 144, 8, 1)">
                {{ $t("ENU_ASSESS_S_1") }}
              </div>
            </div>
            <div v-if="scope.row.assessStatus == 2">
              <div class="waibox">
                <img class="passkh" src="@/assets/images/khnopass.png" alt="" />
                <div class="poimg">{{ scope.row.settleMonth }}</div>
              </div>
              <div style="color: rgba(254, 65, 65, 1)">
                {{ $t("ENU_ASSESS_S_2") }}
              </div>
            </div>
            <div v-if="scope.row.assessStatus == 3">
              {{ $t("ENU_ASSESS_S_3") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="110px"
          align="center"
          prop="tradeTime"
          :label="$t('N_I_160')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="changeTypeVal"
          :label="$t('N_I_163')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="assessStatusVal"
          :label="$t('CK_KS_12')"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.assessStatus == 0" style="color: #009b58">{{
              scope.row.assessStatusVal
            }}</span>
            <span v-if="scope.row.assessStatus == 1" style="color: #009b58">{{
              scope.row.assessStatusVal
            }}</span>
            <span v-if="scope.row.assessStatus == 2" style="color: #c8161d">{{
              scope.row.assessStatusVal
            }}</span>
            <span v-if="scope.row.assessStatus == 3">{{
              scope.row.assessStatusVal
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="tradeAchieveSign"
          :label="$t('N_I_162')"
        >
          <template slot-scope="scope">
            <span
              @click="orderHref(scope.row)"
              v-if="scope.row.changeType == 1"
              style="
                color: #c8161d;
                text-decoration: underline;
                cursor: pointer;
              "
              >{{ scope.row.tradeAchieveSign | numFilter }}</span
            >
            <span
              @click="orderHref(scope.row)"
              v-if="scope.row.changeType == 0"
              style="
                color: #009b58;
                text-decoration: underline;
                cursor: pointer;
              "
              >{{ scope.row.tradeAchieveSign | numFilter }}</span
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="research"
      />
    </div>
  </div>
</template>

<script>
import sidebarUserInfo from "@/components/sidebarUserInfo.vue";
import topBanner from "@/components/topBanner.vue";
import pagination from "@/components/pagination.vue";
import topFBar from "@/components/topFBar.vue";
import {
  getAssessList,
  getAssessStatusList,
  getAssAchieve,
  getchangeTypeList,
} from "@/api/bonus.js";
import navBar from "@/components/navBar.vue";
export default {
  name: "Registration",
  components: {
    navBar,
    sidebarUserInfo,
    topBanner,
    pagination,
    topFBar,
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  data() {
    return {
      changeTypeList: [],
      moren: "appraisal",
      topFbarList: [
        {
          name: this.$t("ENU_ASS_T_0"),
          path: "appraisal",
        },
        {
          name: this.$t("ENU_ASS_T_1"),
          path: "dividend",
        },
      ],
      marketList: [
        {
          text: this.$t("ENU_MENU_510"),
          id: 0,
          path: "/marketDynamics",
        },
        {
          text: this.$t("ENU_MENU_520"),
          id: 7,
          path: "/activeZone",
        },
        {
          text: this.$t("ENU_MENU_530"),
          id: 1,
          path: "/incomeDetail",
        },
        {
          text: this.$t("ENU_MENU_540"),
          id: 3,
          path: "/bonusSource",
        },
        // {
        //   text: "云代奖金",
        //   id: 2,
        //   path: "/generation",
        // },

        {
          text: this.$t("ENU_MENU_560"),
          id: 4,
          path: "/appraisal",
        },
        {
          text: this.$t("ENU_MENU_570"),
          id: 5,
          path: "/registration",
        },
        {
          text: this.$t("ENU_MENU_580"),
          id: 6,
          path: "/investment",
        },
      ],
      specialArea: 0,
      thetime: "",
      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 50,
        startDate: "",
        endDate: "",
        assessType: 0,
        assessStatus: "",
      },
      assessStatusList: [], //收益类型
      achieve: "",
      monthFirstday: "",
      monthLastday: "",
    };
  },
  watch: {
    $route(n, o) {
      this.specialArea = n.query.id;
    },
  },
  created() {},
  mounted() {
    this.specialArea = this.$route.query.id;
    this.research();
    this.getAssessStatusList();
    this.getchangeTypeList();
    this.getStartTime();
    this.getEndTime();
    this.thetime = [this.monthFirstday,this.monthLastday]
  },
  methods: {
    getStartTime() {
      let date = new Date();
      let year = date.getFullYear(); // 得到当前年份
      let month = date.getDate() === 1 ? date.getMonth() : date.getMonth() + 1; // 得到当前月份（0-11月份，+1是当前月份）
      date.setDate(1); // 将当前时间的日期设置成第一天
      month = month >= 10 ? month : "0" + month; // 补零
      let day = date.getDate(); // 得到当前天数，实际是本月第一天，因为前面setDate(1) 设置过了
      day = day >= 10 ? day : "0" + day; // 补零
      this.monthFirstday = year + "-" + month + "-" + day;
      this.queryParams.startDate = this.monthFirstday;
      console.log("🌈 this.monthFirstday", this.monthFirstday);
    },
    getEndTime() {
      let date = new Date();
      let year = date.getFullYear(); // 得到当前年份
      let month = date.getDate() === 1 ? date.getMonth() : date.getMonth() + 1; // 得到当前月份（0-11月份，+1是当前月份）
      month = month >= 10 ? month : "0" + month; // 补零
      let day = date.getDate(); // 得到当前天数，实际是本月第一天，因为前面setDate(1) 设置过了
      day = day >= 10 ? day : "0" + day; // 补零
      this.monthLastday = year + "-" + month + "-" + day + " ";
      this.queryParams.endDate = this.monthLastday;
      console.log("🌈this.monthLastday", this.monthLastday);
    },
    getchangeTypeList() {
      getchangeTypeList().then((res) => {
        this.changeTypeList = res.data;
      });
    },
    changeThetime(val) {
      this.queryParams.startDate = val ? val[0] : this.monthFirstday;
      this.queryParams.endDate = val ? val[1] : this.monthLastday;
    },
    orderHref(row) {
      this.$router.push({
        path: "/vipCenter",
        query: { id: 1, orderCode: row.orderCode },
      });
    },
    chongzhi() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 50,
        startDate: "",
        endDate: "",
        assessType: 0,
        assessStatus: "",
      };
      this.thetime = "";
      this.research();
    },
    changeArea(item) {
      this.specialArea = item.id;
      this.$router.push({
        path: item.path,
        query: { id: item.id },
      });
    },
    research() {
      getAssessList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      });
      getAssAchieve({
        assessType: 0,
        startDate: this.queryParams.startDate,
        endDate: this.queryParams.endDate,
      }).then((res) => {
        this.achieve = res.data;
      });
    },
    getAssessStatusList() {
      getAssessStatusList().then((res) => {
        this.assessStatusList = res.data;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
  }
}

::v-deep .el-range-editor.el-input__inner {
  width: 100%;
  height: 32px;
  padding: 0 10px;
}
::v-deep .select .el-input--suffix .el-input__inner {
  height: 32px;
}
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}

.waibox {
  position: relative;
  .passkh {
    width: 88px;
    height: 88px;
  }
  .poimg {
    position: absolute;
    top: 30px;
    bottom: 30px;
    left: 5px;
    right: 15px;
    color: #ffffff;
  }
}

.contain {
  background: rgba(249, 249, 249, 1);
  height: 100vh;
  padding: 47px 62px 7px 0;
  display: flex;
  .goodArea {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 100%;
    height: 80px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    .goodArea_i {
      margin: 0 35px;
      text-align: center;
      cursor: pointer;
    }
    .actArea {
      color: #b42b2a;
    }
  }
  .topbox {
    // height: 109px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 20px 0;
    padding: 20px 16px 0 0;
    .searchbox {
      display: flex;
      align-items: center;
      margin-left: 40px;
      margin-top: 2px;
      .btn1 {
        background: #cccccc;
        border-radius: 5px 5px 5px 5px;
        color: #ffffff;
      }
      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
      }
    }
  }
  .tablebox {
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
    .tabletop {
      text-align: right;
      padding: 20px;
      .tabletoptitle1 {
        font-size: 18px;
      }
      .tabletoptitle2 {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}
</style>