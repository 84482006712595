<template>
  <div class="contain">
    <div class="rightbox" style="width: 100%">
      <!-- <div class="mianbao" v-if="returnPath">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: returnPath }">{{$t('N_I_241')}}</el-breadcrumb-item>
                  <el-breadcrumb-item>{{$t('ENU_MENU_540')}}</el-breadcrumb-item>
              </el-breadcrumb>
          </div>  -->
      <div class="topbox">
        <el-form ref="form" :model="queryParams" label-width="80px">
          <el-row>
            <el-col :span="4">
              <el-form-item :label="$t('MN_T_1')" prop="memberCode">
                <el-input v-model="queryParams.memberCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item :label="$t('CK_KS_14')" prop="memberName">
                <el-input v-model="queryParams.memberName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item :label="$t('PER_DA_5')" prop="pkRegisterGrade">
                <el-select
                  v-model="queryParams.pkRegisterGrade"
                  :placeholder="$t('CK_KS_38')"
                >
                  <el-option
                    v-for="(item, index) in gradeList"
                    :key="index"
                    :label="item.gradeName"
                    :value="item.pkId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :label="$t('MY_ORD_15')" prop="creationTime">
                <el-date-picker
                  @change="changeTime"
                  v-model="thedata"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  :range-separator="$t('w_0139')"
                  :start-placeholder="$t('CK_KS_4')"
                  :end-placeholder="$t('CK_KS_5')"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <div class="searchbox">
                <el-button class="btn2" @click="research">{{
                  $t("N_I_183")
                }}</el-button>
                <el-button class="btn1" @click="resetAll()">{{
                  $t("CK_KS_6")
                }}</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tablebox">
        <el-table
          :data="tableData"
          height="600px"
          :header-cell-style="{ background: '#EEEEEE' }"
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            align="center"
            prop="memberCode"
            :label="$t('MN_T_1')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="memberName"
            :label="$t('CK_KS_14')"
          ></el-table-column>

          <el-table-column
            align="center"
            prop="orderAmount"
            :label="$t('MN_F_T_1090') + `(${isLocalSymbol()})`"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="orderAchieve"
            :label="$t('w_0306') + '(PV)'"
          ></el-table-column>

          <el-table-column
            align="center"
            prop="pkRegisterGradeVal"
            :label="$t('PER_DA_5')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="creationTime"
            :label="$t('MY_ORD_15')"
          ></el-table-column>
        </el-table>
        <pagination
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="research"
        />
      </div>
    </div>
  </div>
</template>
      
      <script>
import { mapGetters } from "vuex";

import pagination from "@/components/pagination.vue";
import { isLocalSymbol } from "../../util/numberToCurrency";
import * as api from "@/api/bonus.js";
// import navBar from "@/components/navBar.vue";
export default {
  name: "MonthPvDetail",
  components: {
    pagination,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  props: {
    placeDept: {
      type: Number,
      default: 1,
    },
    monthScoreType: {
      type: String,
      default: "",
    },
    monthType: {
      type: String,
      default: "",
    },
    achievementType: {
      type: String,
      default: "",
    },
    year: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      specialArea: 0,
      thetime: "",
      tableData: [],
      returnPath: "",
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 50,
        pkBonusItems: "",
        sourceMemberName: "",
        sourceMemberCode: "",
        startDate: "",
        endDate: "",
      },
      gradeList: [],
      thedata: [],
    };
  },
  watch: {
    $route(n, o) {
      this.specialArea = n.query.id;
    },
  },
  created() {
    this.getOthers();
  },
  mounted() {
    this.specialArea = this.$route.query.id;
    this.research();
  },
  methods: {
    isLocalSymbol,
    resetAll() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 50,
      };
      this.thedata = [];
      this.research();
    },
    changeTime(val) {
      this.queryParams.startDate = val[0];
      this.queryParams.endDate = val[1];
    },
    getOthers() {
      api.getGradeList().then((res) => {
        this.gradeList = res.data;
      });
    },
    research() {
      this.queryParams.placeDept = this.placeDept;
      this.queryParams.monthScoreType = this.monthScoreType;
      this.queryParams.monthType = this.monthType;
      this.queryParams.year = this.year;
      this.queryParams.achievementType = this.achievementType;
      api.getAchieveDetails(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>
      
      <style lang="scss" scoped>
::v-deep .el-range-editor.el-input__inner {
  width: 100%;
}
.mianbao {
  padding: 0 10px;
  margin: 20px 0 0 0;
}
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
.contain {
  background: rgba(249, 249, 249, 1);
  height: 100vh;
  padding: 47px 62px 47px 0;
  display: flex;
  .goodArea {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 100%;
    height: 80px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    .goodArea_i {
      margin: 0 35px;
      text-align: center;
      cursor: pointer;
    }
    .actArea {
      color: #b42b2a;
    }
  }
  .topbox {
    // height: 109px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 40px 0;
    padding: 30px 16px 10px 16px;
    .searchbox {
      display: flex;
      align-items: center;
      margin-left: 30px;
      .btn1 {
        background: #cccccc;
        border-radius: 5px 5px 5px 5px;
        color: #ffffff;
      }
      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
      }
    }
  }
  .tablebox {
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
  }
}
</style>