<template>
    <div style="position: relative;width: 800px">
        <div class="title1">
            <div>{{ $t('N_I_121') }}</div>
            <div style="color: #999;font-size: 14px;margin: 4px 0 0 14px">{{ $t('N_I_122') }}</div>
        </div>
        <div class="color-s">
            <div id="mounthData4" :style="myChartStyle">
            </div>
        </div>
    </div>
</template>

<script>
import * as api from '@/api/activity.js'
import {parentAchieveLeft, systemGrandeRange} from "@/api/activity.js";

export default {
    data() {
        return {
            arr: [],
            barList: {},
            gradeList: [],
            dataLists:{},
            myChartStyle: {
                width: "790px",
                height: "700px",
                margin: "50px 0 10px 0",
            }, //图表样式
        }
    },
    mounted() {
        this.systemGrandeRange()
        this.upDateCover2()
    },
    methods: {
        systemGrandeRange() {
            // 所有等级名称
            systemGrandeRange().then((res) => {
                this.gradeList = res.data
                let _barList = {id: [], name: [],color:[]}
                res.data.forEach((item) => {
                    _barList.id.push(item.pkId)
                    _barList.name.push(item.gradeName)
                    _barList.color.push(item.color)
                });
                this.barList = _barList
            })
        },
        initArrData(_data) {
            let _barList = {};
            let _barList1 = {};
            let arr = this.arr;
            this.systemGrandeRange();
            _data.forEach((item) => {
                if (item.umbrellaList.length>0) {
                   let lists= this.sortBy(item.umbrellaList, "gradeValue")
                    this.barList.id.forEach((_item,index) => {
                        lists.forEach((items) => {
                            if(_item == items.pkGrade){
                                _barList[_item] = this.barList.name[index];
                                _barList1[_item] = this.barList.color[index];
                            }
                        })
                    });
                }else if(item.umbrellaList.length==0){
                    this.barList.id.forEach((_item,index) => {
                        _barList[_item] = this.barList.name[index];
                        _barList1[_item] = this.barList.color[index];
                    });
                }
            });
            let _list = {id:[],name:[],color:[]};
            for(let key in _barList){
                _list.id.push(key);
                _list.name.push(_barList[key]);
            }
            for(let key in _barList1) {
                _list.color.push(_barList1[key])
            }

            this.barList = _list;
            let _arrList = {};
            this.barList.id.forEach((item) => {
                let _arrs = [];
                for (let i = 0; i < _data.length; i++) {
                    _arrs.push(0);
                }
                _arrList[item] = _arrs;
            });
            return this.initServiceData(_data, _arrList);
        },
        initServiceData(_data, _arrList) {
            //初始化data值
            _data.forEach((item, index) => {
                if (item.umbrellaList!=undefined) {
                    item.umbrellaList.forEach((items) => {
                        if(_arrList[items.pkGrade]!=undefined){
                            _arrList[items.pkGrade][index] = items.achieve;
                        }
                    })
                }
            });
            return _arrList;
        },
        getStartTime() {
            let date = new Date()
            let year = date.getFullYear() // 得到当前年份
            let month = date.getDate()===1?date.getMonth():date.getMonth() + 1 // 得到当前月份（0-11月份，+1是当前月份）
            date.setDate(1) // 将当前时间的日期设置成第一天
            month = month >= 10 ? month : '0' + month // 补零
            let day = date.getDate() // 得到当前天数，实际是本月第一天，因为前面setDate(1) 设置过了
            day = day >= 10 ? day : '0' + day // 补零
            // return year + '-' + month + '-' + day
             return year + '-' + month 
        },
        getEndTime() {
            var date = new Date();
            var year = date.getFullYear() // 年
            let month = date.getDate()===1?date.getMonth():date.getMonth() + 1 // 得到当前月份（0-11月份，+1是当前月份）
            var day = date.getDate() >1 ?date.getDate()-1:date.getDate(); // 日
            let day1 = date.getDate()
            var hour = date.getHours(); // 时
            var minutes = date.getMinutes(); // 分
            var seconds = date.getSeconds() //秒
            // 给一位数的数据前面加 “0”
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (day >= 0 && day <= 9) {
                day = "0" + day;
            }
            if (hour >= 0 && hour <= 9) {
                hour = "0" + hour;
            }
            if (minutes >= 0 && minutes <= 9) {
                minutes = "0" + minutes;
            }
            if (seconds >= 0 && seconds <= 9) {
                seconds = "0" + seconds;
            }
            if(day1==1){
                var date2 = new Date()
                var year2 = date2.getFullYear()
                var month2 = date2.getMonth()
                let dates =this.getMonthDay(year2,month2,0)
                day = dates
            }
            return year + "-" + month + "-" + day + " "
        },
        getMonthDay(year, month) {
            let days = new Date(year, month + 1, 0).getDate()
            return days
        },
        sortBy(array, field, sort = "asc"){
            const sortOpt = {
                asc: (a, b, sortField) => a[sortField] - b[sortField],
                desc: (a, b, sortField) => b[sortField] - a[sortField],
            };
            array.sort((a, b) => sortOpt[sort](a, b, field));
            return array;
        },
        upDateCover2(val) {
            api
                .parentAchieveLeft({
                    month:val? val:this.getStartTime(),
                    // startDate:val? val[0] :this.getStartTime(),
                    // endDate: val?val[1]:this.getEndTime(),
                   
                })
                .then((res) => {
                    let xAxisData = []
                    //初始化services数组
                    let _arrList = this.initArrData(res.data);
                    //初始化data值
                    res.data.forEach((item, index) => {
                        xAxisData.push(item.memberName)
                    })
                    let _series = [];
                    this.barList.name.forEach((item, index) => {
                        let _service = {
                            name: item,
                            showSymbol:false,
                            type: 'bar',
                            stack: 'one',
                            barWidth: 40, // 柱子宽度
                            color:this.barList.color[index],
                            data: _arrList[this.barList.id[index]]
                        };
                        _series.push(_service);
                    });
                    this.$nextTick(() => {//结合$nextTick使用
                        this.drawChart(xAxisData, _series);
                    })
                })
        },
        drawChart(arr1, series) {
            // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
            let myChart = this.$echarts.init(document.getElementById('mounthData4'))
            // 指定图表的配置项和数据
            let option = {
                // legend: {
                //     data: this.barList.name,
                //     left: '10%',
                //
                // },


                legend: {
                    data: this.barList.name,
                    bottom: "5%",
                    left: "center",
                    width: "auto",
                    itemHeight: 21,
                    itemWidth:39
                },
                tooltip: {},
                xAxis: {
                    data: arr1,
                    bottom:50,
                    axisLabel: {
                        formatter: function (params) {
                            var newParamsName = ''; // 拼接后的新字符串
                            var paramsNameNumber = params.length; // 实际标签数
                            var provideNumber = 3; // 每行显示的字数
                            var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 如需换回，算出要显示的行数

                            if (paramsNameNumber > provideNumber) {
                                /** 循环每一行,p表示行 */

                                for (var i = 0; i < rowNumber; i++) {
                                    var tempStr = ''; // 每次截取的字符串
                                    var start = i * provideNumber; // 截取位置开始
                                    var end = start + provideNumber; // 截取位置结束

                                    // 最后一行的需要单独处理

                                    if (i == rowNumber - 1) {
                                        tempStr = params.substring(start, paramsNameNumber);
                                    } else {
                                        tempStr = params.substring(start, end) + '\n';
                                    }
                                    newParamsName += tempStr;
                                }
                            } else {
                                newParamsName = params;
                            }

                            return newParamsName;
                        },
                        interval: 0,
                        margin:10,
                        textStyle:{
                            fontSize:14,
                        },
                    }
                },
                yAxis: [
                    {type: 'value'}
                ],
                grid: {
                    bottom:180
                },
                series: series
            }

            myChart.clear();
            myChart.setOption(option);
        },
    },
}
</script>

<style lang="scss" scoped>
.color-s {
  width: 790px;
  height: 746px;
  border: 4px solid #979797;
  margin: 80px;
  position: absolute;
  left: 0px;
  top: 10px;
}

.title1 {
  position: absolute;
  left: 80px;
  top: 40px;
  display: flex;
  color: #333333;
  font-size: 20px;

  div {
  }
}
</style>