<!--
 * @Descripttion: 
 * @version: 
 * @Author: 王三华
 * @Date: 2023-05-15 14:38:28
-->
<template>
  <div>
    <div id="firstPv"
         style="width: 700px; height: 400px"></div>
  </div>
</template>

<script>
import * as api from '@/api/activity.js'
export default {
  // mounted() {
  //   this.upDateCover()
  // },
  methods: {
    upDateCover() {
      api.achieveDay().then((res) => {
        let arr1 = res.data.map((item) => {
          return item.settleDate
        })
        let arr2 = res.data.map((item) => {
          return item.aNewPv
        })
        let arr3 = res.data.map((item) => {
          return item.bNewPv
        })
        this.drawChart(arr1, arr2, arr3)
      })
    },
    drawChart(arr1, arr2, arr3) {
      let myChart = this.$echarts.init(document.getElementById('firstPv'))
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: '',
          subtext: this.$t('w_0339'),
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          top: 'bottom',
          data: [this.$t('S_C_18'), this.$t('S_C_19')],
          itemWidth: 20, // 长方形宽度
          itemHeight: 10, // 长方形高度
        },
        xAxis: {
          data: arr1,
          axisLabel: {
            interval: 0,
            // rotate: 45,
            textStyle: {
              color: '#000',
              fontSize: '10',
              itemSize: '',
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            },
          },
        },
        series: [
          {
            name: this.$t('S_C_18'),
            type: 'line',
            data: arr2,
            symbol: 'none',
            itemStyle: {
              normal: {
                color: '#5A64FF',
              },
            },
            lineStyle: {
              normal: {
                width: 3,
              },
            },
          },
          {
            name: this.$t('S_C_19'),
            type: 'line',
            data: arr3,
            symbol: 'none',
            itemStyle: {
              normal: {
                color: '#01C4FF',
              },
            },
            lineStyle: {
              normal: {
                width: 3,
              },
            },
          },
        ],
      }
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    },
  },
}
</script>

<style>
</style>