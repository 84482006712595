<template>
    <div class="width-auto" style="position: relative">
        <div class="right-lines">
            <div class="item-s" v-for="item in list">
                <div>{{item.name}}</div>
                <div class="bg-color" :style="{backgroundColor:`${item.color}`}"></div>
            </div>
        </div>
        <div id="mycharts" style="width: 100%;margin-top: 10px"  :style="myChartStyle"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import {getMemberRecommend} from "@/api/index";
import {formatSeconds} from "@/util/common";
export default {
    name: "recommendTime",
    data(){
        return{
            myChart:{},
            list:[
                {"name":this.$t('N_I_117'),"color":"#01C291"},
                {"name":this.$t('N_I_118'),"color":"#FE4C4B"},
                {"name":this.$t('N_I_119'),"color":"#FE9A02"},
                {"name":this.$t('N_I_120'),"color":"#2982FF"},
            ],
            myChartStyle: {
                float: "center",
                width: "1200px",
                height: "450px",
                paddingTop: "20px",
                // margin: "20px",
            }, //图表样式
            sexData: [
            ],
        }
    },
    mounted() {
        // this.getInit();
    },
    methods:{
        getInit() {
            getMemberRecommend().then((res)=>{
                this.ageChart(res.data);
            })
        },
        ageChart(data) {
            let _that = this;
            let list1=[]
            list1.push({'value':data.companyTime ,'index':3}) //公司
            list1.push({'value':data.starTime,'index':2}) //明星
            list1.push({'value':data.teamTime ,'index':1})  // 团队
            list1.push({'value':data.thisTime,'index':0}) //自己
            // console.error(list1)
            let _data = this.initCharts(list1);
            // let _maxTime = _data[0].value;
            // let _unit = '秒';
            let _unitInfo = 60;
            // if(_maxTime > 60){
            //     _unit = '分钟';
            // }
            // if(_maxTime > 60 * 60){
            //     _unit = '小时';
            //     _unitInfo = 60*60;
            // }
            // if(_maxTime > 60 * 60 * 24){
            //     _unit = '天';
            //     _unitInfo = 60*60*24;
            // }
            list1.forEach(function(item){
               item["subValue"] = parseFloat(item.value) / _unitInfo;
            });
            // console.error("aaaa");
            // console.error(list1);
            var option = {
                tooltip: {
                    formatter: function(data){
                        data.value= formatSeconds( data.value)
                        return data.value
                    },
                },
                legend: {
                    // left: "right",
                    top: "70px",
                    right: "30px",
                    width: "auto",
                    orient: 'vertical',
                    itemHeight: 21,
                    itemWidth:39
                },
                color: "rgb(71,137,246)",
                grid: {
                    left: '0%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    minInterval: 1,
                    axisLine:{
                        show:true
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        formatter:function(data){
                           let _data =formatSeconds(data)
                            return _data;
                        },
                    }
                    // axisLabel:{
                    //     formatter: function(data){
                    //         var text=data;
                    //         console.error(text)
                    //         return text+'天'
                    //     },
                    //     itemWidth: "40"
                    // }
                },
                yAxis: {
                    type: 'category',
                    show:false,
                },
                series: {
                    type: 'bar',
                    color:'#FE9A02',
                    data: list1,
                    label: {
                        show: true,
                        position:'right',
                        textStyle:{
                            color:"#333",
                            fontSize:10
                        },
                        formatter: function(data){
                            data.value= formatSeconds( data.value)
                            return data.value
                        }
                    },
                    itemStyle: {
                        normal: {
                            //这里是颜色
                            color: function(params) {
                                var colorList = ['#2982FF','#FE9A02', '#FE4C4B', '#01C291'];
                                return colorList[params.dataIndex]
                            }
                        }
                    },
                    barWidth:'45'
                },
            };
            const myChart = echarts.init(document.getElementById("mycharts"));
            myChart.setOption(option);
            //随着屏幕大小调节图表
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        initCharts(data){
            return data;

        }
    }
}
</script>

<style scoped lang="scss">
.width-auto{
    //width: 1200px;
    margin-left:0px;
}
.right-lines{
    position: absolute;
    right: 10px;
    top:110px;
    color: #999;
    font-size: 12px;
}
.item-s{
    padding-bottom: 10px;
    display: flex;
    line-height: 26px;
}
.bg-color{
    width: 40px;
    height: 22px;
    border-radius: 2px;
    margin-left: 10px;
}
</style>