<template>
  <div>
    <div id="actmember" style="width: 700px; height: 400px"></div>
  </div>
</template>
  
  <script>
import * as api from "@/api/activity.js";
export default {
  props: {
    time: {
      type: Number,
      // 定义是否必须传
      required: false,
    },
  },
  mounted() {
    // this.upDateCover2();
  },
  methods: {
    upDateCover2(val) {
      api
        .getActiveMember({
          dateType: val || this.time,
        })
        .then((res) => {
          let arr1 = res.data.map((item) => {
            return item.creationTime;
          });
          let arr2 = res.data.map((item) => {
            return item.leftCoun;
          });
          let arr3 = res.data.map((item) => {
            return item.rightCount;
          });
          this.drawChart(arr1, arr2, arr3);
        });
    },
    drawChart(arr1, arr2, arr3) {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("actmember"));
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: "",
          subtext: "人数",
        },
        tooltip: {},
        legend: {
          top: "bottom",
          bottom: '5%',
          data: [this.$t('S_C_18'), this.$t('S_C_19')],
        },
        xAxis: {
          data: arr1,
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            // rotate: 45,
            textStyle: {
              color: "#000",
              fontSize: "10",
              itemSize: "",
            },
          },
        },
        yAxis: {
          minInterval:1,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        series: [
          {
            name: this.$t('S_C_18'),
            type: "bar",
            data: arr2,
            itemStyle: {
              normal: {
                barBorderRadius: [4, 4, 0, 0],
                color: "#34b934",
              },
            },
            barWidth: "18px",
          },
          {
            name: this.$t('S_C_19'),
            type: "bar",
            data: arr3,
            itemStyle: {
              normal: {
                barBorderRadius: [4, 4, 0, 0],
                color: "#FFAE36",
              },
            },
            barWidth: "18px",
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
  },
};
</script>
  
  <style>
</style>