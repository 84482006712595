<!--
 * @Descripttion: 
 * @version: 
 * @Author: 王三华
 * @Date: 2023-05-15 14:38:28
-->
<template>
  <div>
    <div id="mounthData"
         style="width: 700px; height: 400px">

    </div>
  </div>
</template>

<script>
import * as api from '@/api/activity.js'
export default {
    props:{
        time: {
            type: Number,
            // 定义是否必须传
            required: false,
        },
    },
  mounted() {
    // this.upDateCover()
    // this.drawChart()
  },
  methods: {
    upDateCover(val) {
      api
        .achieveContrast({
          month: val || this.time,
        })
        .then((res) => {
          let arr1 = res.data.map((item) => {
            return item.date
          })
          let arr2 = res.data.map((item) => {
            return item.aNewPv
          })
          let arr3 = res.data.map((item) => {
            return item.bNewPv
          })
          this.drawChart(arr1, arr2, arr3)
        })
    },
    drawChart(arr1, arr2, arr3) {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById('mounthData'))
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: '',
          subtext: this.$t('w_0339'),
        },
        tooltip: {},
        legend: {
          top: 'bottom',
          bottom: '5%',
          data: [this.$t('S_C_18'), this.$t('S_C_19')],
        },
        xAxis: {
          data: arr1,
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            },
          },
        },
        series: [
          {
            name: this.$t('S_C_18'),
            type: 'bar',
            data: arr2,
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0],
                color: '#FE4C4B',
              },
            },
          },
          {
            name: this.$t('S_C_19'),
            type: 'bar',
            data: arr3,
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0],
                color: '#FFAE36',
              },
            },
          },
        ],
      }
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    },
  },
}
</script>

<style>
</style>