<template>
    <div style="position: relative;width: 620px">
        <div class="title">{{ $t('N_I_123') }}</div>
        <div class="left-s">
            <div id="mounthData3" :style="myChartStyle">

            </div>
        </div>
    </div>
</template>

<script>
import * as api from '@/api/activity.js'
import {parentAchieveLeft, parentAchieveRight, systemGrandeRange} from "@/api/activity.js";
export default {
    data() {
        return {
            myChartStyle: {
                width: "620px",
                height: "450px",
                margin: "50px 0 10px 0",
            }, //图表样式
            gradeList:[],
            barList:[]
        }
    },
    mounted(){
        this.upDateCover2()
        // this.systemGrandeRange()
    },
    methods: {
        systemGrandeRange() {
            // 所有等级名称
            systemGrandeRange().then((res) => {
                this.gradeList = res.data
                let _barList = {id: [], name: [],color:[]}
                res.data.forEach((item) => {
                    _barList.id.push(item.pkId)
                    _barList.name.push(item.gradeName)
                    _barList.color.push(item.color)
                });
                this.barList = _barList
            })
        },
        sortBy(array, field, sort = "asc"){
            const sortOpt = {
                asc: (a, b, sortField) => a[sortField] - b[sortField],
                desc: (a, b, sortField) => b[sortField] - a[sortField],
            };
            array.sort((a, b) => sortOpt[sort](a, b, field));
            return array;
        },
        upDateCover2(val) {
            api
                .parentAchieveRight({
                    month:val? val:this.getStartTime(),
                    // startDate:val? val[0] :this.getStartTime(),
                    // endDate: val?val[1]:this.getEndTime(),
                })
                .then((res) => {
                    var arr= 0
                    res.data.list.forEach((item)=>{
                        item.name = item.pkGradeKeyVal
                        item.value = item.peopleNumber
                        item.color = item.color
                        arr+=item.peopleNumber

                    })
                    // 根据返回的value值重新进行排序
                    let lists =this.sortBy(res.data.list, "gradeValue")
                    lists.forEach((items)=>{
                        this.gradeList.push(items.color)
                    })
                    this.drawChart(res.data,res.data.list,arr)
                })
        },
        getStartTime() {
            let date = new Date()
            // console.error(date.getDate()==1?date.getMonth():date.getMonth() + 1)
            let year = date.getFullYear() // 得到当前年份
            let month = date.getDate()===1?date.getMonth():date.getMonth() + 1 // 得到当前月份（0-11月份，+1是当前月份）
            date.setDate(1) // 将当前时间的日期设置成第一天
            month = month >= 10 ? month : '0' + month // 补零
            let day = date.getDate() // 得到当前天数，实际是本月第一天，因为前面setDate(1) 设置过了
            day = day >= 10 ? day : '0' + day // 补零
            // return year + '-' + month + '-' + day
             return year + '-' + month
        },
        getEndTime() {
            var date = new Date();
            var year = date.getFullYear() // 年
            let month = date.getDate()===1?date.getMonth():date.getMonth() + 1 // 得到当前月份（0-11月份，+1是当前月份）
            var day = date.getDate() >1 ?date.getDate()-1:date.getDate(); // 日
            let day1 = date.getDate()
            var hour = date.getHours(); // 时
            var minutes = date.getMinutes(); // 分
            var seconds = date.getSeconds() //秒
            // 给一位数的数据前面加 “0”
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (day >= 0 && day <= 9) {
                day = "0" + day;
            }
            if (hour >= 0 && hour <= 9) {
                hour = "0" + hour;
            }
            if (minutes >= 0 && minutes <= 9) {
                minutes = "0" + minutes;
            }
            if (seconds >= 0 && seconds <= 9) {
                seconds = "0" + seconds;
            }
            if(day1==1){
                var date2 = new Date()
                var year2 = date2.getFullYear()
                var month2 = date2.getMonth()
                let dates =this.getMonthDay(year2,month2,0)
                day = dates
            }
            return year + "-" + month + "-" + day + " "
        },
        getMonthDay(year, month) {
            let days = new Date(year, month + 1, 0).getDate()
            return days
        },
        drawChart(arr1, arr2,arr3) {
            // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
            let myChart = this.$echarts.init(document.getElementById('mounthData3'))
            // 指定图表的配置项和数据
            let option = {
                legend: {
                    top: '10%',
                    width: '80%',
                    // height: '300px',
                    // itemGap: 15, //间隔
                    itemWidth: 40, // 宽
                    itemHeight: 20,// 高
                    formatter: (name) => {
                        // console.error(name)
                        return name
                    },
                },
                series: [
                    {
                        top:"35%",
                        type: 'pie',
                        radius: ['50%', '100%'],
                        data: arr2,
                        color:this.gradeList,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 15,
                                lineHeight: 40,
                                formatter: function(data){
                                    return (
                                        data.data.name  + ' ' +
                                        data.data.peopleNumber +
                                        "人" +
                                        "\n " +
                                        "业绩：" +
                                        data.data.achieve+
                                        "\n " +
                                        "占比：" +
                                        Math.round((parseInt(data.data.peopleNumber))  / arr3 * 10000) / 100 + "%"
                                    );
                                }
                            }
                        },
                        labelLine: {
                            show: true
                        },
                    },
                ],
            }
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option)
        },
    },
}
</script>

<style lang="scss" scoped>
.left-s{
    margin-left: 80px;
    margin-top: 0px;
}
.title{
    position: absolute;
    right: 420px;
    top: 56px;
    font-size: 20px;
}
</style>