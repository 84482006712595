<!--
 * @Descripttion: 
 * @version: 
 * @Author: 王三华
 * @Date: 2023-07-18 17:35:58
-->
<template>
    <div>
        <div class="topbox">
            <el-form ref="form" :model="queryParams" label-width="80px"> 
              <el-row>
                    <el-col :span="7">
                        <el-form-item :label="$t('w_0147')" >
                            <el-date-picker @change="getChange" v-model="thetime" type="daterange" value-format="yyyy-MM-dd" :range-separator="$t('w_0139')" :start-placeholder="$t('CK_KS_4')" :end-placeholder="$t('CK_KS_5')">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <div class="searchbox">
                            <el-button class="btn2" @click="getList">{{ $t('N_I_183') }}</el-button>
                            <el-button class="btn1" @click="resetAll()">{{ $t('CK_KS_6') }}</el-button>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="tablebox">
            <el-table :data="tableData" height="970px" :header-cell-style="{ background: '#EEEEEE' }" :row-class-name="tableRowClassName">
                <el-table-column align="center" prop="memberCode" :label="$t('N_I_124')"></el-table-column>
                <el-table-column align="center" prop="memberName" :label="$t('CK_KS_14')"></el-table-column>
                <el-table-column align="center" prop="phone" :label="$t('w_0052')"></el-table-column>
                <el-table-column align="center" prop="settleGradeStr" :label="$t('w_0148')"></el-table-column>
                <el-table-column align="center" prop="awardsStr" :label="$t('ENU_NOTICE_GRADE_2')"></el-table-column>
                <el-table-column align="center" prop="quantity" :label="$t('CK_KS_17')"></el-table-column>
                <el-table-column align="center" prop="price" :label="$t('w_0149')+'(¥)'"></el-table-column>
                <el-table-column align="center" prop="orderAmount" :label="$t('ENU_TOTAL_V_2')+'(¥)'"></el-table-column>
                <el-table-column align="center" prop="payTime" :label="$t('w_0147')"></el-table-column>
            </el-table>
            <pagination :total="total" :page.sync="pageNum" :limit.sync="pageSize" @pagination="getList" />
        </div>
    </div>
</template>

<script>
import * as api from '@/api/goods.js'

export default {
    data() {
        return {
            queryParams: {},
            tableData: [],
            total: 0,
            pageNum: 1,
            pageSize: 50,
            thetime:[]
        }
    },
    methods: {
      resetAll(){
        this.queryParams={}
        this.thetime = []
        this.pageNum = 1
        this.getList()
      },
        getList(){
          api.treeList(Object.assign({}, this.queryParams, {
            pageNum:this.pageNum,
            pageSize:this.pageSize,
          })).then(res=>{
            this.total = res.total
            this.tableData = res.rows
          })
        },
        getChange(value){
          if(value){
              this.queryParams.startPayDate = value[0]
              this.queryParams.endPayDate = value[1]
          }
      },
      tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
    }
}
</script>

<style lang="scss" scoped>
.topbox {
    // height: 109px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 20px 0;
    padding: 30px 16px 10px 16px;
    .searchbox {
        display: flex;
        align-items: center;
        margin-left: 30px;
        .btn1 {
            background: #cccccc;
            border-radius: 5px 5px 5px 5px;
            color: #ffffff;
        }
        .btn2 {
            border-radius: 5px 5px 5px 5px;
            background: rgba(214, 24, 32, 1);
            color: #ffffff;
        }
    }
}

.tablebox {
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
}
</style>