<template>
  <div class="contain">
    <div class="rightbox" style="width: 100%">
        <div class="mianbao" v-if="returnPath">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: returnPath }">{{$t('N_I_241')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('ENU_MENU_540')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="topbox">
        <el-form ref="form" :model="queryParams" label-width="80px">
          <el-row>
            <el-col :span="6">
              <el-form-item :label="$t('N_I_150')" prop="sourceMemberCode">
                <el-input v-model="queryParams.sourceMemberCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :label="$t('N_I_151')" prop="sourceMemberName">
                <el-input v-model="queryParams.sourceMemberName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :label="$t('N_I_154')" prop="pkBonusItems">
                <el-select v-model="queryParams.pkBonusItems" :placeholder="$t('CK_KS_38')">
                  <el-option
                    v-for="(item, index) in incomeType"
                    :key="index"
                    :label="item.bonusName"
                    :value="item.pkId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7" v-if="userInfo.pkCountry!=1">
              <el-form-item :label="$t('w_0138')" prop="teamCode" label-width="80px">
                <el-date-picker
                 @change="getChange"
                  v-model="thetime"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  :range-separator="$t('w_0139')"
                  :start-placeholder="$t('CK_KS_4')"
                  :end-placeholder="$t('CK_KS_5')"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <div class="searchbox" :style="userInfo.pkCountry==1?'margin-left:10px':''">
                  <el-button class="btn2" @click="research" >{{ $t('N_I_183') }}</el-button>
                  <el-button class="btn1" @click="resetAll()">{{ $t('CK_KS_6') }}</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tablebox">
        <el-table
          :data="tableData"
          height="1215px"
          :header-cell-style="{ background: '#EEEEEE' }"
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            align="center"
            prop="settleDate"
            :label="$t('N_I_130')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="sourceMemberCode"
            :label="$t('N_I_150')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="sourceMemberName"
            :label="$t('N_I_151')"
          ></el-table-column>
          <el-table-column
            align="center"
            :label="$t('MY_ORD_6')+`(${isLocalSymbol()})`"
          >
              <template  slot-scope="scope">
                  {{scope.row.orderAmount| numFilter}}
              </template>
          </el-table-column>
          <el-table-column
            align="center"
            :label="$t('MY_ORD_16')+'(PV)'"
          >
              <template  slot-scope="scope">
                  {{scope.row.orderAchieve| numFilter}}
              </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="bonusName"
            :label="$t('N_I_154')"
            width="200"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="round"
            :label="$t('N_I_155')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="second"
            :label="$t('N_I_156')"
          ></el-table-column>
          <el-table-column
            align="center"
            :label="$t('N_I_157')"
          >
              <template  slot-scope="scope">
                  {{scope.row.realIncome| numFilter}}
              </template>
          </el-table-column>
         
        </el-table>
        <pagination
          :total="total"
          :page.sync="pageNum"
          :limit.sync="pageSize"
          @pagination="research"
        />
      </div>
    </div>
  </div>
</template>
    
    <script>
    import { mapGetters } from "vuex";

    import sidebarUserInfo from "@/components/sidebarUserInfo.vue";
import topBanner from "@/components/topBanner.vue";
import pagination from "@/components/pagination.vue";
import { getBonusDetail,getBdBonusitemsList } from "@/api/bonus.js";
import navBar from "@/components/navBar.vue";
    import {isLocalSymbol} from "../../util/numberToCurrency";
export default {
  name: "IncomeDetail",
  components: {
      navBar,
    sidebarUserInfo,
    topBanner,
    pagination,
  },
  computed: {
      ...mapGetters(['userInfo']),
  },
  data() {
    return {
      marketList: [
        {
          text: this.$t('ENU_MENU_510'),
          id: 0,
          path: "/marketDynamics",
        },
        {
          text: this.$t('ENU_MENU_520'),
          id: 7,
          path: '/activeZone',
        },
        {
          text: this.$t('ENU_MENU_530'),
          id: 1,
          path: "/incomeDetail",
        },
        {
          text: this.$t('ENU_MENU_540'),
          id: 3,
          path: "/bonusSource",
        },
        // {
        //   text: "云代奖金",
        //   id: 2,
        //   path: "/generation",
        // },

        {
          text: this.$t('ENU_MENU_560'),
          id: 4,
          path: "/appraisal",
        },
        {
          text: this.$t('ENU_MENU_570'),
          id: 5,
          path: "/registration",
        },
        {
          text: this.$t('ENU_MENU_580'),
          id: 6,
          path: "/investment",
        },
      ],
      specialArea: 0,
      thetime: "",
      tableData: [],
      returnPath:'',
      total: 0,
        pageNum: 1,
        pageSize: 50,
      queryParams: {
        pkBonusItems:"",
        sourceMemberName:"",
        sourceMemberCode:"",
        startDate: "",
        endDate: "",
      },
      incomeType: [], //收益类型
    };
  },
  watch: {
    $route(n, o) {
      this.specialArea = n.query.id;
    },
  },
  created() {
    this.getBdBonusitemsList()
  },
  mounted() {
    this.specialArea = this.$route.query.id;
    let allData = decodeURIComponent(window.location.href).split('=')
      if(allData[2]!=undefined){
          this.returnPath = '/marketDynamics?id=1&index='+allData[1].split('&')[0]
      }
      setTimeout(()=>{
          this.research();
      },100)
  },
    filters: {
        numFilter (value) {
            // 截取当前数据到小数点后两位
            let realVal = parseFloat(value).toFixed(2)
            return realVal
        }
    },
  methods: {
      isLocalSymbol,
      getChange(value){
          if(value){
              this.queryParams.startDate = value[0]
              this.queryParams.endDate = value[0]
          }
      },
    resetAll() {
        this.pageNum = 1
        this.pageSize = 50
        this.queryParams={
            pkBonusItems:"",
            sourceMemberName:"",
            sourceMemberCode:"",
            startDate: "",
            endDate: "",
        }
        this.thetime =''
        this.research()
    },
    changeArea(item) {
      this.specialArea = item.id;
      this.$router.push({
        path: item.path,
        query: { id: item.id },
      })
    },
    getBdBonusitemsList(){
      getBdBonusitemsList().then(res=>{
        this.incomeType = res.rows
      })
    }, 
    research() {
      if (this.thetime) {
        this.queryParams.startDate = this.thetime[0];
        this.queryParams.endDate = this.thetime[1];
      }else{
          this.queryParams.startDate = '';
          this.queryParams.endDate = '';
      }
      getBonusDetail(this.queryParams,`?pageNum=${this.pageNum}&pageSize=${this.pageSize}`).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>
    
    <style lang="scss" scoped>
    ::v-deep .el-range-editor.el-input__inner{
      width: 100%;
    }
    .mianbao {
      padding: 0 10px;
      margin: 20px 0 0 0;
    }
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
.contain {
  background: rgba(249, 249, 249, 1);
  height: 100vh;
  padding: 0!important;
  display: flex;
  .goodArea {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 100%;
    height: 80px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    .goodArea_i {
      margin: 0 35px;
      text-align: center;
      cursor: pointer;
    }
    .actArea {
      color: #b42b2a;
    }
  }
  .topbox {
    // height: 109px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 40px 0;
    padding: 30px 16px 10px 16px;
    .searchbox {
      display: flex;
      align-items: center;
      margin-left: 30px;
      .btn1 {
        background: #cccccc;
        border-radius: 5px 5px 5px 5px;
        color: #ffffff;
      }
      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
      }
    }
  }
  .tablebox {
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
  }
}
</style>