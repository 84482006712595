<template>
    <div class="contains">
        <div class="topbox">
            <el-form  label-width="80px">
                <el-row>
                    <el-col :span="7" >
                        <el-form-item :label="$t('N_I_130')" style="margin-bottom: 15px">
                            <el-date-picker
                                    v-model="thetime"
                                    type="daterange"
                                    value-format="yyyy-MM-dd"
                                    :range-separator="$t('w_0139')"
                                    :start-placeholder="$t('CK_KS_4')"
                                    :end-placeholder="$t('CK_KS_5')"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <div class="searchbox">
                            <el-button class="btn2" @click="research">{{ $t('N_I_183') }}</el-button>
                            <el-button class="btn1" @click="reset">{{ $t('CK_KS_6') }}</el-button>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="height-auto-s">
            <div class="content-s">
                <div class="div-box" v-for="(item,index) in bonus" :key="index">
                    <div class="title">
                        {{item.name}}
                    </div>
                    <div class="flex-s">
                        <div class="view-s" v-for="(items,indexs) in item.bonusList" :key="indexs">
                            <div>{{items.name}}</div>
                            <div class="margin-tops">{{items.money | numFilter }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import sidebarUserInfo from "@/components/sidebarUserInfo.vue";
import topBanner from "@/components/topBanner.vue";
import pagination from "@/components/pagination.vue";
import {getQueryBonus, queryBonusFirst} from "@/api/bonus.js";
import {toThousandthAndKeepDecimal} from "@/util/numberToCurrency";
import user from "@/store/modules/user";
import {mapGetters} from "vuex";
import * as api from "@/api/goods";
import {queryBonusTotal, queryCurrentBonus} from "../../api/bonus";
import {isLocalSymbol} from "../../util/numberToCurrency";
export default {
    name: "IncomeDetail",
    components: {
        sidebarUserInfo,
        topBanner,
        pagination,
    },
    filters: {
        numFilter (value) {
            // 截取当前数据到小数点后两位
            let realVal = parseFloat(value).toFixed(2)
            return realVal
        }
    },
    data() {
        return {
            totals:[],
            bonus:[
                {
                    name:this.$t('ENU_MENU_531'),
                    bonusList:[
                        {
                            name:this.$t('N_I_131')+`(${isLocalSymbol()})`,
                            money:"0.00",
                        },
                        {
                            name:this.$t('N_I_132')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        {
                            name:this.$t('N_I_133')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        {
                            name:this.$t('N_I_135')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        {
                            name:this.$t('N_I_136')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        {
                            name:this.$t('N_I_137')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        {
                            name:this.$t('N_I_138')+`(${isLocalSymbol()})`,
                            money:"0",
                        }
                    ]
                },
                // {
                //     name:this.$t('ENU_MENU_532'),
                //     bonusList:[
                //         // {
                //         //     name:this.$t('N_I_131')+`(${isLocalSymbol()})`,
                //         //     money:"0",
                //         // },
                //         {
                //             name:this.$t('w_0154')+`(${isLocalSymbol()})`,
                //             money:"0",
                //         },
                //         // {
                //         //     name:this.$t('w_0155')+`(${isLocalSymbol()})`,
                //         //     money:"0",
                //         // },
                //         {
                //             name:this.$t('w_0155')+`(${isLocalSymbol()})`,
                //             money:"0",
                //         },
                //         {
                //             name:this.$t('N_I_141')+`(${isLocalSymbol()})`,
                //             money:"0",
                //         },
                //         {
                //             name:this.$t('N_I_137')+`(${isLocalSymbol()})`,
                //             money:"0",
                //         },
                //         {
                //             name:this.$t('N_I_138')+`(${isLocalSymbol()})`,
                //             money:"0",
                //         }
                //     ]
                // },
                {
                    name:this.$t('ENU_MENU_533'),
                    bonusList:[
                        {
                            name:this.$t('N_I_142')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        {
                            name:this.$t('N_I_138')+`(${isLocalSymbol()})`,
                            money:"0",
                        }
                    ]
                },
                {
                    name:this.$t('ENU_MENU_536'),
                    bonusList:[
                        {
                            name:this.$t('N_I_147')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        {
                            name:this.$t('N_I_138')+`(${isLocalSymbol()})`,
                            money:"0",
                        }
                    ]
                },
                {
                    name:this.$t('ENU_MENU_537'),
                    bonusList:[
                        {
                            name:this.$t('N_I_148')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        {
                            name:this.$t('N_I_149')+`(${isLocalSymbol()})`,
                            money:"0",
                        },  {
                            name:this.$t('N_I_137')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        {
                            name:this.$t('N_I_138')+`(${isLocalSymbol()})`,
                            money:"0",
                        }
                    ]
                },

            ],
            marketList: [
                {
                    text: this.$t('ENU_MENU_540'),
                    id: 3,
                    path: "/marketDynamics",
                    name: 'bonusSource',
                    isShow:false,
                },
            ],
            specialArea: 0,
            thetime: "",
            tableData: [],
            total: 0,
            money:0.00,
            queryParams: {
                startDate: "",
                endDate: "",
            },
        };
    },
    watch: {
        $route(n, o) {
            this.specialArea = n.query.id;
        },
    },
    computed: {
        user() {
            return user;
        },
        ...mapGetters(["userInfo"]),
    },
    created() {},
    mounted() {
        this.specialArea = this.$route.query.id;
        // this.getMenuLists()
        this.getBonusTotal()

    },
    methods: {
        isLocalSymbol,
        getBonusTotal() {
          if( parseInt(localStorage.getItem("pkCountry"))!=1){
            this.bonus = [
                {
                    name:this.$t('ENU_MENU_531'),
                    bonusList:[
                        {
                            name:this.$t('N_I_131')+`(${isLocalSymbol()})`,
                            money:"0.00",
                        },
                        {
                            name:this.$t('N_I_132')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        {
                            name:this.$t('N_I_133')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        {
                            name:this.$t('N_I_135')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        // {
                        //     name:this.$t('N_I_136')+`(${isLocalSymbol()})`,
                        //     money:"0",
                        // },
                        // {
                        //     name:this.$t('N_I_137')+`(${isLocalSymbol()})`,
                        //     money:"0",
                        // },
                        {
                            name:this.$t('N_I_138')+`(${isLocalSymbol()})`,
                            money:"0",
                        }
                    ]
                },
                {
                    name:this.$t('ENU_MENU_532'),
                    bonusList:[
                        // {
                        //     name:this.$t('N_I_131')+`(${isLocalSymbol()})`,
                        //     money:"0",
                        // },
                        {
                            name:this.$t('w_0154')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        // {
                        //     name:this.$t('w_0155')+`(${isLocalSymbol()})`,
                        //     money:"0",
                        // },
                        {
                            name:this.$t('w_0155')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        {
                            name:this.$t('N_I_141')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        // {
                        //     name:this.$t('N_I_137')+`(${isLocalSymbol()})`,
                        //     money:"0",
                        // },
                        {
                            name:this.$t('N_I_138')+`(${isLocalSymbol()})`,
                            money:"0",
                        }
                    ]
                },
                {
                    name:this.$t('ENU_MENU_533'),
                    bonusList:[
                        {
                            name:this.$t('N_I_142')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        {
                            name:this.$t('N_I_138')+`(${isLocalSymbol()})`,
                            money:"0",
                        }
                    ]
                },
                {
                    name:this.$t('ENU_MENU_536'),
                    bonusList:[
                        {
                            name:this.$t('N_I_147')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        {
                            name:this.$t('N_I_138')+`(${isLocalSymbol()})`,
                            money:"0",
                        }
                    ]
                },
                {
                    name:this.$t('ENU_MENU_537'),
                    bonusList:[
                        {
                            name:this.$t('N_I_148')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        {
                            name:this.$t('N_I_149')+`(${isLocalSymbol()})`,
                            money:"0",
                        },  {
                            name:this.$t('N_I_137')+`(${isLocalSymbol()})`,
                            money:"0",
                        },
                        {
                            name:this.$t('N_I_138')+`(${isLocalSymbol()})`,
                            money:"0",
                        }
                    ]
                },

            ]
          }
            queryCurrentBonus(this.queryParams).then((res)=>{
                this.bonus[0].bonusList[0].money = res.rows[0]? res.rows[0].directIncome : "0.00"
                this.bonus[0].bonusList[1].money =  res.rows[0]?res.rows[0].expandIncome : "0.00"
                this.bonus[0].bonusList[2].money =  res.rows[0]?res.rows[0].coachIncome : "0.00"
                this.bonus[0].bonusList[3].money =  res.rows[0]?res.rows[0].serviceIncome : "0.00"
                if( parseInt(localStorage.getItem("pkCountry"))==1){
                  this.bonus[0].bonusList[4].money =  res.rows[0]?res.rows[0].backPoints : "0.00"
                this.bonus[0].bonusList[5].money =  res.rows[0]?res.rows[0].serviceSpend : "0.00"
                }else{
                  this.bonus[0].bonusList[4].money =  res.rows[0]? res.rows[0].purRealSubtotal : "0.00"
                }
               

                this.bonus[1].bonusList[0].money =  res.rows[0]?res.rows[0].repurPushIncome : "0.00"
                this.bonus[1].bonusList[1].money =  res.rows[0]?res.rows[0].repurRangeIncome : "0.00"
                this.bonus[1].bonusList[2].money =  res.rows[0]?res.rows[0].repurExpandIncome : "0.00"
                if( parseInt(localStorage.getItem("pkCountry"))==1){
                  this.bonus[1].bonusList[3].money = res.rows[0]? res.rows[0].repServiceSpend : "0.00"
                }else{
                  this.bonus[1].bonusList[3].money =  res.rows[0]?res.rows[0].repurRealSubtotal :"0.00"
                }
               
                

                this.bonus[2].bonusList[0].money = res.rows[0]? res.rows[0].haiFunIncome : "0.00"
                this.bonus[2].bonusList[1].money =  res.rows[0]?res.rows[0].hiFunRealSubtotal : "0.00"

                // this.bonus[3].bonusList[0].money = res.rows[0]? res.rows[0].haiFunIncome : "0.00"
                // this.bonus[3].bonusList[1].money = res.rows[0]? res.rows[0].haiFunServiceSpend : "0.00"
                // this.bonus[3].bonusList[2].money =  res.rows[0]?res.rows[0].haiFunRealSubtotal : "0.00"

                this.bonus[3].bonusList[0].money = res.rows[0]? res.rows[0].repurCoupon : "0.00"
                this.bonus[3].bonusList[1].money =  res.rows[0]?res.rows[0].repurCouponSubtotal : "0.00"

                this.bonus[4].bonusList[0].money = res.rows[0]? res.rows[0].makerDirectIncome : "0.00"
                this.bonus[4].bonusList[1].money =  res.rows[0]?res.rows[0].makerShareIncome : "0.00"
                this.bonus[4].bonusList[2].money =  res.rows[0]?res.rows[0].makerServiceSpend: "0.00"
                this.bonus[4].bonusList[3].money =  res.rows[0]?res.rows[0].makerRealSubtotal: "0.00"

                let pkCountry = parseInt(localStorage.getItem("pkCountry"));
                console.log('%c [ pkCountry ]-262', 'font-size:13px; background:#ca63b5; color:#ffa7f9;', pkCountry)
            if(pkCountry!=1){
                this.bonus.splice(4,1)
                this.bonus.splice(3,1)
                this.bonus.splice(2,1)

            }
            })
           
        },
        getMenuLists(){
            let lists = JSON.parse(localStorage.getItem('menuList'))
            lists.forEach((item)=>{
                if(this.marketList[0].name==item.menuKey){
                    this.marketList[0].isShow = true
                }
            })
        },
        toThousandthAndKeepDecimal,
        dateHref(item){
            if(this.marketList[0].isShow==true){
                this.$router.push({
                    path:"/marketDynamics",
                    query: { id: 3 ,index:1},
                });
            }
        },
        goSource(){
            this.$router.push({
                path:"/marketDynamics",
                query: { id: 3, },
            });
        },
        changeArea(item) {
            this.specialArea = item.id;
            //   this.research()
            this.$router.push({
                path: item.path,
                query: { id: item.id },
            });
        },
        reset() {
            this.tableData = []
            this.thetime = ''
            this.queryParams = {
                startDate: "",
                endDate: "",
            },
                this.research()
        },
        research() {
            if (this.thetime) {
                this.queryParams.startDate = this.thetime[0];
                this.queryParams.endDate = this.thetime[1];
            }
         this.getBonusTotal()
        },
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex % 2 == 1) {
                return "warning-row";
            } else if (rowIndex % 2 == 0) {
                return "success-row";
            }
            return "";
        },
    },
};
</script>

<style lang="scss" scoped>
.height-auto-s{
  height: 1333px;
  margin-top: 20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px 0px rgba(135,135,135,0.3);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  width: 100%;
}
.flex-s{
  display: flex;
  flex-wrap: wrap;
}
.margin-tops{
  margin-top: 20px;
}
.view-s:nth-child(even){
  margin-left: 15px;
}
.view-s{
  width: 200px;
  height: 63px;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px 0px rgba(204,204,204,0.5);
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  padding: 10px;
  margin-top: 10px;
}
.title{
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #333;
  font-size: 28px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.div-box{
  width: 458px;
  height:480px;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px 0px rgba(204,204,204,0.5);
  border-radius: 10px 10px 10px 10px;
  margin-left: 20px;
  //display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.content-s{
  //height: 1333px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  //background: #FFFFFF;
  //box-shadow: 0px 2px 20px 0px rgba(135,135,135,0.3);
  //border-radius: 10px 10px 10px 10px;
  opacity: 1;
  width: 100%;
}
.style1{
  cursor: pointer;text-decoration:underline;color: #d61820
}
.tabletop{
  position: sticky;
  top: 100px; //这个值根据实际情况而定
  z-index: 10;
  background-color: #fff;
}
:deep(.el-table)  {
  overflow: visible;
}
:deep(.el-table__header-wrapper) {
  position: sticky;
  top: 150px; //这个值根据实际情况而定
  z-index: 10;
}
::v-deep .el-range-editor.el-input__inner{
  width: 100%;
  height: 32px;
  padding: 0 10px;
}
::v-deep .select .el-input--suffix .el-input__inner{
  height: 32px;
}
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
.contain {
  background: rgba(249, 249, 249, 1);
  height: 100vh;
  padding: 47px 62px 47px 0;
  //display: flex;
  .goodArea {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 100%;
    height: 80px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    .goodArea_i {
      margin: 0 35px;
      text-align: center;
      cursor: pointer;
    }
    .actArea {
      color: #b42b2a;
    }
  }
  .topbox {
    // height: 109px;
    width: 100%!important;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 0 0;
    padding: 15px 0 0 0;
    .searchbox {
      display: flex;
      align-items: center;
      margin-left: 40px;
      .btn1 {
        border: 1px solid #CCCCCC;
        border-radius: 5px 5px 5px 5px;
        color: #666;
        height: 34px;
        width: 68px;
        padding: 0;
      }
      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
        height: 34px;
        width: 68px;
        padding: 0;
      }
    }
  }
  .tablebox {
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
    margin-top: 20px;
    .tabletop {
      text-align: right;
      padding: 20px;
      .tabletoptitle1 {
        font-size: 18px;
      }
      .tabletoptitle2 {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}
</style>