<template>
  <div class="yjfb">
    <div class="qiu1" style="cursor: pointer;" @click="goDetail(1,1)">
      <div class="num">{{ achieveData.leftCoun || 0 }}</div>
      <div class="btom">{{ $t('N_I_88') }}</div>
    </div>
    <div class="qiu2" style="cursor: pointer;" @click="goDetail(1,2)">
      <div class="num">{{ achieveData.leftAchieve || 0 }}</div>
      <div class="btom">{{ $t('w_0135') }}</div>
    </div>
    <div class="qiu4" style="cursor: pointer;" @click="goDetail(1,3)">
      <div class="num">{{ achieveData.leftDirectAchieve || 0 }}</div>
      <div class="btom">{{$t('S_C_18')}}{{$t('w_0136')}}</div>
    </div>
    <div class="qiu3" style="cursor: pointer;" @click="goDetail(3,4)">
      <div class="num">{{ achieveData.directAchieve || 0 }}</div>
      <div class="btom">{{ $t('w_0136') }}</div>
    </div>
    <div class="qiu4" style="cursor: pointer;" @click="goDetail(2,3)">
      <div class="num">{{ achieveData.rightDirectAchieve || 0 }}</div>
      <div class="btom">{{$t('S_C_19')}}{{$t('w_0136')}}</div>
    </div>
    <div class="qiu2" style="cursor: pointer;" @click="goDetail(2,2)">
      <div class="num">{{ achieveData.rightAchieve || 0 }}</div>
      <div class="btom">{{ $t('w_0137') }}</div>
    </div>
    <div class="qiu1" style="cursor: pointer;" @click="goDetail(2,1)">
      <div class="num">{{ achieveData.rightCount || 0 }}</div>
      <div class="btom">{{ $t('N_I_92') }}</div>
    </div>
  </div>
</template>

<script>
import { getPlaceNewAddAchieve } from "@/api/activity.js";
export default {
  data() {
    return {
      achieveData: {},
    };
  },
  props: {
    // time: {
    //   type: Number,
    //   // 定义是否必须传
    //   required: false,
    // },
    achieveData:{
      type:Object,
    }
  },
  mounted() {
    // this.getFgDataList()
  },
  methods: {
    goDetail(index,type){
      this.$router.push({
          path:"/marketDynamics",
          query: { id: 0,ifDetail:index,monthScoreType:type},
      });
    },
    // getFgDataList(val) {
    //   getPlaceNewAddAchieve({ monthType: val || this.time }).then((res) => {
    //     this.achieveData = res.data;
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.yjfb {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  // background: #ffffff;
  // padding: 20px;
  .num {
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
  }
  .btom {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
  }
  .qiu1 {
    width: 161px;
    height: 161px;
    border-radius: 50%;
    margin: 0 8px;
    margin-top: 120px;
    background: linear-gradient(150deg, #fe8b8b 0%, #f34444 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qiu2 {
    width: 179px;
    height: 179px;
    border-radius: 50%;
    margin: 0 8px;
    margin-top: 60px;
    background: linear-gradient(153deg, #ff9b61 0%, #ff772a 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qiu3 {
    border-radius: 50%;
    width: 253px;
    height: 253px;
    margin: 0 8px;
    background: linear-gradient(208deg, #667AFF 0%, #6B7CEA 100%);    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qiu4{
    width: 179px;
    height: 179px;
    border-radius: 50%;
    margin: 0 10px;
    margin-top: 8px;
    background: linear-gradient(153deg, #65FF61 0%, #48FF2A 100%);    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>